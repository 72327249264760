
import React from 'react';
// import CanvasJSReact from './canvasjs.react';
 import CanvasJSReact from "../../assets/canvasjs/canvasjs.react";
 var CanvasJSChart = CanvasJSReact.CanvasJSChart;
 // var CanvasJSReact = CanvasJSReact.CanvasJSChart;

//  https://www.usinflationcalculator.com/inflation/current-inflation-rates/

const Chart = ()=>{
    const data = [
      {
        "Sr. No": "111",
         "Year": "2022",
         "Jan": "0.075",
         "Feb": "0.079",
         "Mar": "0.085",
         "Apr": "0.083",
         "May": "0.086",
         "Jun": "0.091",
         "Jul": "0.085",
         "Aug": "0.083",
         "Sept": "0.082",
         "Oct": "0.077",
         "Nov": "0.071",
         "Dec": "0.065",
         "AVE": "0.08"
      },
      {
        "Sr. No": "110",
         "Year": "2021",
         "Jan": "0.014",
         "Feb": "0.017",
         "Mar": "0.026",
         "Apr": "0.042",
         "May": "0.05",
         "Jun": "0.054",
         "Jul": "0.054",
         "Aug": "0.053",
         "Sept": "0.054",
         "Oct": "0.062",
         "Nov": "0.068",
         "Dec": "0.07",
         "AVE": "0.047"
      },
      {
        "Sr. No": "109",
         "Year": "2020",
         "Jan": "0.025",
         "Feb": "0.023",
         "Mar": "0.015",
         "Apr": "0.003",
         "May": "0.001",
         "Jun": "0.006",
         "Jul": "0.01",
         "Aug": "0.013",
         "Sept": "0.014",
         "Oct": "0.012",
         "Nov": "0.012",
         "Dec": "0.014",
         "AVE": "0.012"
      },
        {
          "Sr. No": "1",
           "Year": "2019",
           "Jan": "0.0155",
           "Feb": "0.0152",
           "Mar": "0.0186",
           "Apr": "0.0200",
           "May": "0.0179",
           "Jun": "0.0165",
           "Jul": "0.0181",
           "Aug": "0.0175",
           "Sept": "0.0171",
           "Oct": "0.0176",
           "Nov": "0.0205",
           "Dec": "0.0229",
           "AVE": "0.0181"
        },
        {
          "Sr. No": "2",
           "Year": "2018",
           "Jan": "0.0207",
           "Feb": "0.0221",
           "Mar": "0.0236",
           "Apr": "0.0246",
           "May": "0.0280",
           "Jun": "0.0287",
           "Jul": "0.0295",
           "Aug": "0.0270",
           "Sept": "0.0228",
           "Oct": "0.0252",
           "Nov": "0.0218",
           "Dec": "0.0191",
           "AVE": "0.0244"
        },
        {
          "Sr. No": "3",
           "Year": "2017",
           "Jan": "0.0250",
           "Feb": "0.0274",
           "Mar": "0.0238",
           "Apr": "0.0220",
           "May": "0.0187",
           "Jun": "0.0163",
           "Jul": "0.0173",
           "Aug": "0.0194",
           "Sept": "0.0223",
           "Oct": "0.0204",
           "Nov": "0.0220",
           "Dec": "0.0211",
           "AVE": "0.0213"
        },
        {
          "Sr. No": "4",
           "Year": "2016",
           "Jan": "0.0137",
           "Feb": "0.0102",
           "Mar": "0.0085",
           "Apr": "0.0113",
           "May": "0.0102",
           "Jun": "0.0101",
           "Jul": "0.0084",
           "Aug": "0.0106",
           "Sept": "0.0146",
           "Oct": "0.0164",
           "Nov": "0.0169",
           "Dec": "0.0207",
           "AVE": "0.0126"
        },
        {
          "Sr. No": "5",
           "Year": "2015",
           "Jan": "-0.0009",
           "Feb": "-0.0003",
           "Mar": "-0.0007",
           "Apr": "-0.0020",
           "May": "-0.0004",
           "Jun": "0.0012",
           "Jul": "0.0017",
           "Aug": "0.0020",
           "Sept": "-0.0004",
           "Oct": "0.0017",
           "Nov": "0.0050",
           "Dec": "0.0073",
           "AVE": "0.0012"
        },
        {
          "Sr. No": "6",
           "Year": "2014",
           "Jan": "0.0158",
           "Feb": "0.0113",
           "Mar": "0.0151",
           "Apr": "0.0195",
           "May": "0.0213",
           "Jun": "0.0207",
           "Jul": "0.0199",
           "Aug": "0.0170",
           "Sept": "0.0166",
           "Oct": "0.0166",
           "Nov": "0.0132",
           "Dec": "0.0076",
           "AVE": "0.0162"
        },
        {
          "Sr. No": "7",
           "Year": "2013",
           "Jan": "0.0159",
           "Feb": "0.0198",
           "Mar": "0.0147",
           "Apr": "0.0106",
           "May": "0.0136",
           "Jun": "0.0175",
           "Jul": "0.0196",
           "Aug": "0.0152",
           "Sept": "0.0118",
           "Oct": "0.0096",
           "Nov": "0.0124",
           "Dec": "0.0150",
           "AVE": "0.0147"
        },
        {
          "Sr. No": "8",
           "Year": "2012",
           "Jan": "0.0293",
           "Feb": "0.0287",
           "Mar": "0.0265",
           "Apr": "0.0230",
           "May": "0.0170",
           "Jun": "0.0166",
           "Jul": "0.0141",
           "Aug": "0.0169",
           "Sept": "0.0199",
           "Oct": "0.0216",
           "Nov": "0.0176",
           "Dec": "0.0174",
           "AVE": "0.0207"
        },
        {
          "Sr. No": "9",
           "Year": "2011",
           "Jan": "0.0163",
           "Feb": "0.0211",
           "Mar": "0.0268",
           "Apr": "0.0316",
           "May": "0.0357",
           "Jun": "0.0356",
           "Jul": "0.0363",
           "Aug": "0.0377",
           "Sept": "0.0387",
           "Oct": "0.0353",
           "Nov": "0.0339",
           "Dec": "0.0296",
           "AVE": "0.0316"
        },
        {
          "Sr. No": "10",
           "Year": "2010",
           "Jan": "0.0263",
           "Feb": "0.0214",
           "Mar": "0.0231",
           "Apr": "0.0224",
           "May": "0.0202",
           "Jun": "0.0105",
           "Jul": "0.0124",
           "Aug": "0.0115",
           "Sept": "0.0114",
           "Oct": "0.0117",
           "Nov": "0.0114",
           "Dec": "0.0150",
           "AVE": "0.0164"
        },
        {
          "Sr. No": "11",
           "Year": "2009",
           "Jan": "0.0003",
           "Feb": "0.0024",
           "Mar": "-0.0038",
           "Apr": "-0.0074",
           "May": "-0.0128",
           "Jun": "-0.0143",
           "Jul": "-0.0210",
           "Aug": "-0.0148",
           "Sept": "-0.0129",
           "Oct": "-0.0018",
           "Nov": "0.0184",
           "Dec": "0.0272",
           "AVE": "-0.0034"
        },
        {
          "Sr. No": "12",
           "Year": "2008",
           "Jan": "0.0428",
           "Feb": "0.0403",
           "Mar": "0.0398",
           "Apr": "0.0394",
           "May": "0.0418",
           "Jun": "0.0502",
           "Jul": "0.0560",
           "Aug": "0.0537",
           "Sept": "0.0494",
           "Oct": "0.0366",
           "Nov": "0.0107",
           "Dec": "0.0009",
           "AVE": "0.0385"
        },
        {
          "Sr. No": "13",
           "Year": "2007",
           "Jan": "0.0208",
           "Feb": "0.0242",
           "Mar": "0.0278",
           "Apr": "0.0257",
           "May": "0.0269",
           "Jun": "0.0269",
           "Jul": "0.0236",
           "Aug": "0.0197",
           "Sept": "0.0276",
           "Oct": "0.0354",
           "Nov": "0.0431",
           "Dec": "0.0408",
           "AVE": "0.0285"
        },
        {
          "Sr. No": "14",
           "Year": "2006",
           "Jan": "0.0399",
           "Feb": "0.0360",
           "Mar": "0.0336",
           "Apr": "0.0355",
           "May": "0.0417",
           "Jun": "0.0432",
           "Jul": "0.0415",
           "Aug": "0.0382",
           "Sept": "0.0206",
           "Oct": "0.0131",
           "Nov": "0.0197",
           "Dec": "0.0254",
           "AVE": "0.0324"
        },
        {
          "Sr. No": "15",
           "Year": "2005",
           "Jan": "0.0297",
           "Feb": "0.0301",
           "Mar": "0.0315",
           "Apr": "0.0351",
           "May": "0.0280",
           "Jun": "0.0253",
           "Jul": "0.0317",
           "Aug": "0.0364",
           "Sept": "0.0469",
           "Oct": "0.0435",
           "Nov": "0.0346",
           "Dec": "0.0342",
           "AVE": "0.0339"
        },
        {
          "Sr. No": "16",
           "Year": "2004",
           "Jan": "0.0193",
           "Feb": "0.0169",
           "Mar": "0.0174",
           "Apr": "0.0229",
           "May": "0.0305",
           "Jun": "0.0327",
           "Jul": "0.0299",
           "Aug": "0.0265",
           "Sept": "0.0254",
           "Oct": "0.0319",
           "Nov": "0.0352",
           "Dec": "0.0326",
           "AVE": "0.0268"
        },
        {
          "Sr. No": "17",
           "Year": "2003",
           "Jan": "0.0260",
           "Feb": "0.0298",
           "Mar": "0.0302",
           "Apr": "0.0222",
           "May": "0.0206",
           "Jun": "0.0211",
           "Jul": "0.0211",
           "Aug": "0.0216",
           "Sept": "0.0232",
           "Oct": "0.0204",
           "Nov": "0.0177",
           "Dec": "0.0188",
           "AVE": "0.0227"
        },
        {
          "Sr. No": "18",
           "Year": "2002",
           "Jan": "0.0114",
           "Feb": "0.0114",
           "Mar": "0.0148",
           "Apr": "0.0164",
           "May": "0.0118",
           "Jun": "0.0107",
           "Jul": "0.0146",
           "Aug": "0.0180",
           "Sept": "0.0151",
           "Oct": "0.0203",
           "Nov": "0.0220",
           "Dec": "0.0238",
           "AVE": "0.0159"
        },
        {
          "Sr. No": "19",
           "Year": "2001",
           "Jan": "0.0373",
           "Feb": "0.0353",
           "Mar": "0.0292",
           "Apr": "0.0327",
           "May": "0.0362",
           "Jun": "0.0325",
           "Jul": "0.0272",
           "Aug": "0.0272",
           "Sept": "0.0265",
           "Oct": "0.0213",
           "Nov": "0.0190",
           "Dec": "0.0155",
           "AVE": "0.0283"
        },
        {
          "Sr. No": "20",
           "Year": "2000",
           "Jan": "0.0274",
           "Feb": "0.0322",
           "Mar": "0.0376",
           "Apr": "0.0307",
           "May": "0.0319",
           "Jun": "0.0373",
           "Jul": "0.0366",
           "Aug": "0.0341",
           "Sept": "0.0345",
           "Oct": "0.0345",
           "Nov": "0.0345",
           "Dec": "0.0339",
           "AVE": "0.0338"
        },
        {
          "Sr. No": "21",
           "Year": "1999",
           "Jan": "0.0167",
           "Feb": "0.0161",
           "Mar": "0.0173",
           "Apr": "0.0228",
           "May": "0.0209",
           "Jun": "0.0196",
           "Jul": "0.0214",
           "Aug": "0.0226",
           "Sept": "0.0263",
           "Oct": "0.0256",
           "Nov": "0.0262",
           "Dec": "0.0268",
           "AVE": "0.0219"
        },
        {
          "Sr. No": "22",
           "Year": "1998",
           "Jan": "0.0157",
           "Feb": "0.0144",
           "Mar": "0.0137",
           "Apr": "0.0144",
           "May": "0.0169",
           "Jun": "0.0168",
           "Jul": "0.0168",
           "Aug": "0.0162",
           "Sept": "0.0149",
           "Oct": "0.0149",
           "Nov": "0.0155",
           "Dec": "0.0161",
           "AVE": "0.0155"
        },
        {
          "Sr. No": "23",
           "Year": "1997",
           "Jan": "0.0304",
           "Feb": "0.0303",
           "Mar": "0.0276",
           "Apr": "0.0250",
           "May": "0.0223",
           "Jun": "0.0230",
           "Jul": "0.0223",
           "Aug": "0.0223",
           "Sept": "0.0215",
           "Oct": "0.0208",
           "Nov": "0.0183",
           "Dec": "0.0170",
           "AVE": "0.0234"
        },
        {
          "Sr. No": "24",
           "Year": "1996",
           "Jan": "0.0273",
           "Feb": "0.0265",
           "Mar": "0.0284",
           "Apr": "0.0290",
           "May": "0.0289",
           "Jun": "0.0275",
           "Jul": "0.0295",
           "Aug": "0.0288",
           "Sept": "0.0300",
           "Oct": "0.0299",
           "Nov": "0.0326",
           "Dec": "0.0332",
           "AVE": "0.0293"
        },
        {
          "Sr. No": "25",
           "Year": "1995",
           "Jan": "0.0280",
           "Feb": "0.0286",
           "Mar": "0.0285",
           "Apr": "0.0305",
           "May": "0.0319",
           "Jun": "0.0304",
           "Jul": "0.0276",
           "Aug": "0.0262",
           "Sept": "0.0254",
           "Oct": "0.0281",
           "Nov": "0.0261",
           "Dec": "0.0254",
           "AVE": "0.0281"
        },
        {
          "Sr. No": "26",
           "Year": "1994",
           "Jan": "0.0252",
           "Feb": "0.0252",
           "Mar": "0.0251",
           "Apr": "0.0236",
           "May": "0.0229",
           "Jun": "0.0249",
           "Jul": "0.0277",
           "Aug": "0.0290",
           "Sept": "0.0296",
           "Oct": "0.0261",
           "Nov": "0.0267",
           "Dec": "0.0267",
           "AVE": "0.0261"
        },
        {
          "Sr. No": "27",
           "Year": "1993",
           "Jan": "0.0326",
           "Feb": "0.0325",
           "Mar": "0.0309",
           "Apr": "0.0323",
           "May": "0.0322",
           "Jun": "0.0300",
           "Jul": "0.0278",
           "Aug": "0.0277",
           "Sept": "0.0269",
           "Oct": "0.0275",
           "Nov": "0.0268",
           "Dec": "0.0275",
           "AVE": "0.0296"
        },
        {
          "Sr. No": "28",
           "Year": "1992",
           "Jan": "0.0260",
           "Feb": "0.0282",
           "Mar": "0.0319",
           "Apr": "0.0318",
           "May": "0.0302",
           "Jun": "0.0309",
           "Jul": "0.0316",
           "Aug": "0.0315",
           "Sept": "0.0299",
           "Oct": "0.0320",
           "Nov": "0.0305",
           "Dec": "0.0290",
           "AVE": "0.0303"
        },
        {
          "Sr. No": "29",
           "Year": "1991",
           "Jan": "0.0565",
           "Feb": "0.0531",
           "Mar": "0.0490",
           "Apr": "0.0489",
           "May": "0.0495",
           "Jun": "0.0470",
           "Jul": "0.0445",
           "Aug": "0.0380",
           "Sept": "0.0339",
           "Oct": "0.0292",
           "Nov": "0.0299",
           "Dec": "0.0306",
           "AVE": "0.0425"
        },
        {
          "Sr. No": "30",
           "Year": "1990",
           "Jan": "0.0520",
           "Feb": "0.0526",
           "Mar": "0.0523",
           "Apr": "0.0471",
           "May": "0.0436",
           "Jun": "0.0467",
           "Jul": "0.0482",
           "Aug": "0.0562",
           "Sept": "0.0616",
           "Oct": "0.0629",
           "Nov": "0.0627",
           "Dec": "0.0611",
           "AVE": "0.0539"
        },
        {
          "Sr. No": "31",
           "Year": "1989",
           "Jan": "0.0467",
           "Feb": "0.0483",
           "Mar": "0.0498",
           "Apr": "0.0512",
           "May": "0.0536",
           "Jun": "0.0517",
           "Jul": "0.0498",
           "Aug": "0.0471",
           "Sept": "0.0434",
           "Oct": "0.0449",
           "Nov": "0.0466",
           "Dec": "0.0465",
           "AVE": "0.0483"
        },
        {
          "Sr. No": "32",
           "Year": "1988",
           "Jan": "0.0405",
           "Feb": "0.0394",
           "Mar": "0.0393",
           "Apr": "0.0390",
           "May": "0.0389",
           "Jun": "0.0396",
           "Jul": "0.0413",
           "Aug": "0.0402",
           "Sept": "0.0417",
           "Oct": "0.0425",
           "Nov": "0.0425",
           "Dec": "0.0442",
           "AVE": "0.0408"
        },
        {
          "Sr. No": "33",
           "Year": "1987",
           "Jan": "0.0146",
           "Feb": "0.0210",
           "Mar": "0.0303",
           "Apr": "0.0378",
           "May": "0.0386",
           "Jun": "0.0365",
           "Jul": "0.0393",
           "Aug": "0.0428",
           "Sept": "0.0436",
           "Oct": "0.0453",
           "Nov": "0.0453",
           "Dec": "0.0443",
           "AVE": "0.0366"
        },
        {
          "Sr. No": "34",
           "Year": "1986",
           "Jan": "0.0389",
           "Feb": "0.0311",
           "Mar": "0.0226",
           "Apr": "0.0159",
           "May": "0.0149",
           "Jun": "0.0177",
           "Jul": "0.0158",
           "Aug": "0.0157",
           "Sept": "0.0175",
           "Oct": "0.0147",
           "Nov": "0.0128",
           "Dec": "0.0110",
           "AVE": "0.0191"
        },
        {
          "Sr. No": "35",
           "Year": "1985",
           "Jan": "0.0353",
           "Feb": "0.0352",
           "Mar": "0.0370",
           "Apr": "0.0369",
           "May": "0.0377",
           "Jun": "0.0376",
           "Jul": "0.0355",
           "Aug": "0.0335",
           "Sept": "0.0314",
           "Oct": "0.0323",
           "Nov": "0.0351",
           "Dec": "0.0380",
           "AVE": "0.0355"
        },
        {
          "Sr. No": "36",
           "Year": "1984",
           "Jan": "0.0419",
           "Feb": "0.0460",
           "Mar": "0.0480",
           "Apr": "0.0456",
           "May": "0.0423",
           "Jun": "0.0422",
           "Jul": "0.0420",
           "Aug": "0.0429",
           "Sept": "0.0427",
           "Oct": "0.0426",
           "Nov": "0.0405",
           "Dec": "0.0395",
           "AVE": "0.0430"
        },
        {
          "Sr. No": "37",
           "Year": "1983",
           "Jan": "0.0371",
           "Feb": "0.0349",
           "Mar": "0.0360",
           "Apr": "0.0390",
           "May": "0.0355",
           "Jun": "0.0258",
           "Jul": "0.0246",
           "Aug": "0.0256",
           "Sept": "0.0286",
           "Oct": "0.0285",
           "Nov": "0.0327",
           "Dec": "0.0379",
           "AVE": "0.0322"
        },
        {
          "Sr. No": "38",
           "Year": "1982",
           "Jan": "0.0839",
           "Feb": "0.0762",
           "Mar": "0.0678",
           "Apr": "0.0651",
           "May": "0.0668",
           "Jun": "0.0706",
           "Jul": "0.0644",
           "Aug": "0.0585",
           "Sept": "0.0504",
           "Oct": "0.0514",
           "Nov": "0.0459",
           "Dec": "0.0383",
           "AVE": "0.0616"
        },
        {
          "Sr. No": "39",
           "Year": "1981",
           "Jan": "0.1183",
           "Feb": "0.1141",
           "Mar": "0.1049",
           "Apr": "0.1000",
           "May": "0.0978",
           "Jun": "0.0955",
           "Jul": "0.1076",
           "Aug": "0.1080",
           "Sept": "0.1095",
           "Oct": "0.1014",
           "Nov": "0.0959",
           "Dec": "0.0892",
           "AVE": "0.1035"
        },
        {
          "Sr. No": "40",
           "Year": "1980",
           "Jan": "0.1391",
           "Feb": "0.1418",
           "Mar": "0.1476",
           "Apr": "0.1473",
           "May": "0.1441",
           "Jun": "0.1438",
           "Jul": "0.1313",
           "Aug": "0.1287",
           "Sept": "0.1260",
           "Oct": "0.1277",
           "Nov": "0.1265",
           "Dec": "0.1252",
           "AVE": "0.1358"
        },
        {
          "Sr. No": "41",
           "Year": "1979",
           "Jan": "0.0928",
           "Feb": "0.0986",
           "Mar": "0.1009",
           "Apr": "0.1049",
           "May": "0.1085",
           "Jun": "0.1089",
           "Jul": "0.1126",
           "Aug": "0.1182",
           "Sept": "0.1218",
           "Oct": "0.1207",
           "Nov": "0.1261",
           "Dec": "0.1329",
           "AVE": "0.1122"
        },
        {
          "Sr. No": "42",
           "Year": "1978",
           "Jan": "0.0684",
           "Feb": "0.0643",
           "Mar": "0.0655",
           "Apr": "0.0650",
           "May": "0.0697",
           "Jun": "0.0741",
           "Jul": "0.0770",
           "Aug": "0.0784",
           "Sept": "0.0831",
           "Oct": "0.0893",
           "Nov": "0.0889",
           "Dec": "0.0902",
           "AVE": "0.0762"
        },
        {
          "Sr. No": "43",
           "Year": "1977",
           "Jan": "0.0522",
           "Feb": "0.0591",
           "Mar": "0.0644",
           "Apr": "0.0695",
           "May": "0.0673",
           "Jun": "0.0687",
           "Jul": "0.0683",
           "Aug": "0.0662",
           "Sept": "0.0660",
           "Oct": "0.0639",
           "Nov": "0.0672",
           "Dec": "0.0670",
           "AVE": "0.0650"
        },
        {
          "Sr. No": "44",
           "Year": "1976",
           "Jan": "0.0672",
           "Feb": "0.0629",
           "Mar": "0.0607",
           "Apr": "0.0605",
           "May": "0.0620",
           "Jun": "0.0597",
           "Jul": "0.0535",
           "Aug": "0.0571",
           "Sept": "0.0549",
           "Oct": "0.0546",
           "Nov": "0.0488",
           "Dec": "0.0486",
           "AVE": "0.0575"
        },
        {
          "Sr. No": "45",
           "Year": "1975",
           "Jan": "0.1180",
           "Feb": "0.1123",
           "Mar": "0.1025",
           "Apr": "0.1021",
           "May": "0.0947",
           "Jun": "0.0939",
           "Jul": "0.0972",
           "Aug": "0.0860",
           "Sept": "0.0791",
           "Oct": "0.0744",
           "Nov": "0.0738",
           "Dec": "0.0694",
           "AVE": "0.0920"
        },
        {
          "Sr. No": "46",
           "Year": "1974",
           "Jan": "0.0939",
           "Feb": "0.1002",
           "Mar": "0.1039",
           "Apr": "0.1009",
           "May": "0.1071",
           "Jun": "0.1086",
           "Jul": "0.1151",
           "Aug": "0.1086",
           "Sept": "0.1195",
           "Oct": "0.1206",
           "Nov": "0.1220",
           "Dec": "0.1234",
           "AVE": "0.1103"
        },
        {
          "Sr. No": "47",
           "Year": "1973",
           "Jan": "0.0365",
           "Feb": "0.0387",
           "Mar": "0.0459",
           "Apr": "0.0506",
           "May": "0.0553",
           "Jun": "0.0600",
           "Jul": "0.0573",
           "Aug": "0.0738",
           "Sept": "0.0736",
           "Oct": "0.0780",
           "Nov": "0.0825",
           "Dec": "0.0871",
           "AVE": "0.0616"
        },
        {
          "Sr. No": "48",
           "Year": "1972",
           "Jan": "0.0327",
           "Feb": "0.0351",
           "Mar": "0.0350",
           "Apr": "0.0349",
           "May": "0.0323",
           "Jun": "0.0271",
           "Jul": "0.0295",
           "Aug": "0.0294",
           "Sept": "0.0319",
           "Oct": "0.0342",
           "Nov": "0.0367",
           "Dec": "0.0341",
           "AVE": "0.0327"
        },
        {
          "Sr. No": "49",
           "Year": "1971",
           "Jan": "0.0529",
           "Feb": "0.0500",
           "Mar": "0.0471",
           "Apr": "0.0416",
           "May": "0.0440",
           "Jun": "0.0464",
           "Jul": "0.0436",
           "Aug": "0.0462",
           "Sept": "0.0408",
           "Oct": "0.0381",
           "Nov": "0.0328",
           "Dec": "0.0327",
           "AVE": "0.0430"
        },
        {
          "Sr. No": "50",
           "Year": "1970",
           "Jan": "0.0618",
           "Feb": "0.0615",
           "Mar": "0.0582",
           "Apr": "0.0606",
           "May": "0.0604",
           "Jun": "0.0601",
           "Jul": "0.0598",
           "Aug": "0.0541",
           "Sept": "0.0566",
           "Oct": "0.0563",
           "Nov": "0.0560",
           "Dec": "0.0557",
           "AVE": "0.0584"
        },
        {
          "Sr. No": "51",
           "Year": "1969",
           "Jan": "0.0440",
           "Feb": "0.0468",
           "Mar": "0.0525",
           "Apr": "0.0552",
           "May": "0.0551",
           "Jun": "0.0548",
           "Jul": "0.0544",
           "Aug": "0.0571",
           "Sept": "0.0570",
           "Oct": "0.0567",
           "Nov": "0.0593",
           "Dec": "0.0620",
           "AVE": "0.0546"
        },
        {
          "Sr. No": "52",
           "Year": "1968",
           "Jan": "0.0365",
           "Feb": "0.0395",
           "Mar": "0.0394",
           "Apr": "0.0393",
           "May": "0.0392",
           "Jun": "0.0420",
           "Jul": "0.0449",
           "Aug": "0.0448",
           "Sept": "0.0446",
           "Oct": "0.0475",
           "Nov": "0.0473",
           "Dec": "0.0472",
           "AVE": "0.0427"
        },
        {
          "Sr. No": "53",
           "Year": "1967",
           "Jan": "0.0346",
           "Feb": "0.0281",
           "Mar": "0.0280",
           "Apr": "0.0248",
           "May": "0.0279",
           "Jun": "0.0278",
           "Jul": "0.0277",
           "Aug": "0.0245",
           "Sept": "0.0275",
           "Oct": "0.0243",
           "Nov": "0.0274",
           "Dec": "0.0304",
           "AVE": "0.0278"
        },
        {
          "Sr. No": "54",
           "Year": "1966",
           "Jan": "0.0192",
           "Feb": "0.0256",
           "Mar": "0.0256",
           "Apr": "0.0287",
           "May": "0.0287",
           "Jun": "0.0253",
           "Jul": "0.0285",
           "Aug": "0.0348",
           "Sept": "0.0348",
           "Oct": "0.0379",
           "Nov": "0.0379",
           "Dec": "0.0346",
           "AVE": "0.0301"
        },
        {
          "Sr. No": "55",
           "Year": "1965",
           "Jan": "0.0097",
           "Feb": "0.0097",
           "Mar": "0.0129",
           "Apr": "0.0162",
           "May": "0.0162",
           "Jun": "0.0194",
           "Jul": "0.0161",
           "Aug": "0.0194",
           "Sept": "0.0161",
           "Oct": "0.0193",
           "Nov": "0.0160",
           "Dec": "0.0192",
           "AVE": "0.0159"
        },
        {
          "Sr. No": "56",
           "Year": "1964",
           "Jan": "0.0164",
           "Feb": "0.0164",
           "Mar": "0.0131",
           "Apr": "0.0131",
           "May": "0.0131",
           "Jun": "0.0131",
           "Jul": "0.0130",
           "Aug": "0.0098",
           "Sept": "0.0130",
           "Oct": "0.0097",
           "Nov": "0.0130",
           "Dec": "0.0097",
           "AVE": "0.0128"
        },
        {
          "Sr. No": "57",
           "Year": "1963",
           "Jan": "0.0133",
           "Feb": "0.0100",
           "Mar": "0.0133",
           "Apr": "0.0099",
           "May": "0.0099",
           "Jun": "0.0132",
           "Jul": "0.0132",
           "Aug": "0.0132",
           "Sept": "0.0099",
           "Oct": "0.0132",
           "Nov": "0.0132",
           "Dec": "0.0164",
           "AVE": "0.0124"
        },
        {
          "Sr. No": "58",
           "Year": "1962",
           "Jan": "0.0067",
           "Feb": "0.0101",
           "Mar": "0.0101",
           "Apr": "0.0134",
           "May": "0.0134",
           "Jun": "0.0134",
           "Jul": "0.0100",
           "Aug": "0.0134",
           "Sept": "0.0133",
           "Oct": "0.0133",
           "Nov": "0.0133",
           "Dec": "0.0133",
           "AVE": "0.0120"
        },
        {
          "Sr. No": "59",
           "Year": "1961",
           "Jan": "0.0171",
           "Feb": "0.0136",
           "Mar": "0.0136",
           "Apr": "0.0102",
           "May": "0.0102",
           "Jun": "0.0068",
           "Jul": "0.0135",
           "Aug": "0.0101",
           "Sept": "0.0135",
           "Oct": "0.0067",
           "Nov": "0.0067",
           "Dec": "0.0067",
           "AVE": "0.0107"
        },
        {
          "Sr. No": "60",
           "Year": "1960",
           "Jan": "0.0103",
           "Feb": "0.0173",
           "Mar": "0.0173",
           "Apr": "0.0172",
           "May": "0.0172",
           "Jun": "0.0172",
           "Jul": "0.0137",
           "Aug": "0.0137",
           "Sept": "0.0102",
           "Oct": "0.0136",
           "Nov": "0.0136",
           "Dec": "0.0136",
           "AVE": "0.0146"
        },
        {
          "Sr. No": "61",
           "Year": "1959",
           "Jan": "0.0140",
           "Feb": "0.0105",
           "Mar": "0.0035",
           "Apr": "0.0035",
           "May": "0.0035",
           "Jun": "0.0069",
           "Jul": "0.0069",
           "Aug": "0.0104",
           "Sept": "0.0138",
           "Oct": "0.0173",
           "Nov": "0.0138",
           "Dec": "0.0173",
           "AVE": "0.0101"
        },
        {
          "Sr. No": "62",
           "Year": "1958",
           "Jan": "0.0362",
           "Feb": "0.0325",
           "Mar": "0.0360",
           "Apr": "0.0358",
           "May": "0.0321",
           "Jun": "0.0285",
           "Jul": "0.0247",
           "Aug": "0.0212",
           "Sept": "0.0212",
           "Oct": "0.0212",
           "Nov": "0.0211",
           "Dec": "0.0176",
           "AVE": "0.0273"
        },
        {
          "Sr. No": "63",
           "Year": "1957",
           "Jan": "0.0299",
           "Feb": "0.0336",
           "Mar": "0.0373",
           "Apr": "0.0372",
           "May": "0.0370",
           "Jun": "0.0331",
           "Jul": "0.0328",
           "Aug": "0.0366",
           "Sept": "0.0328",
           "Oct": "0.0291",
           "Nov": "0.0327",
           "Dec": "0.0290",
           "AVE": "0.0334"
        },
        {
          "Sr. No": "64",
           "Year": "1956",
           "Jan": "0.0037",
           "Feb": "0.0037",
           "Mar": "0.0037",
           "Apr": "0.0075",
           "May": "0.0112",
           "Jun": "0.0187",
           "Jul": "0.0224",
           "Aug": "0.0187",
           "Sept": "0.0186",
           "Oct": "0.0223",
           "Nov": "0.0223",
           "Dec": "0.0299",
           "AVE": "0.0152"
        },
        {
          "Sr. No": "65",
           "Year": "1955",
           "Jan": "-0.0074",
           "Feb": "-0.0074",
           "Mar": "-0.0074",
           "Apr": "-0.0037",
           "May": "-0.0074",
           "Jun": "-0.0074",
           "Jul": "-0.0037",
           "Aug": "-0.0037",
           "Sept": "0.0037",
           "Oct": "0.0037",
           "Nov": "0.0037",
           "Dec": "0.0037",
           "AVE": "-0.0028"
        },
        {
          "Sr. No": "66",
           "Year": "1954",
           "Jan": "0.0113",
           "Feb": "0.0151",
           "Mar": "0.0113",
           "Apr": "0.0075",
           "May": "0.0075",
           "Jun": "0.0037",
           "Jul": "0.0037",
           "Aug": "0.0000",
           "Sept": "-0.0037",
           "Oct": "-0.0074",
           "Nov": "-0.0037",
           "Dec": "-0.0074",
           "AVE": "0.0032"
        },
        {
          "Sr. No": "67",
           "Year": "1953",
           "Jan": "0.0038",
           "Feb": "0.0076",
           "Mar": "0.0114",
           "Apr": "0.0076",
           "May": "0.0114",
           "Jun": "0.0113",
           "Jul": "0.0037",
           "Aug": "0.0075",
           "Sept": "0.0075",
           "Oct": "0.0112",
           "Nov": "0.0075",
           "Dec": "0.0075",
           "AVE": "0.0082"
        },
        {
          "Sr. No": "68",
           "Year": "1952",
           "Jan": "0.0433",
           "Feb": "0.0233",
           "Mar": "0.0194",
           "Apr": "0.0233",
           "May": "0.0193",
           "Jun": "0.0232",
           "Jul": "0.0309",
           "Aug": "0.0309",
           "Sept": "0.0230",
           "Oct": "0.0191",
           "Nov": "0.0114",
           "Dec": "0.0075",
           "AVE": "0.0229"
        },
        {
          "Sr. No": "69",
           "Year": "1951",
           "Jan": "0.0809",
           "Feb": "0.0936",
           "Mar": "0.0932",
           "Apr": "0.0932",
           "May": "0.0928",
           "Jun": "0.0882",
           "Jul": "0.0747",
           "Aug": "0.0658",
           "Sept": "0.0697",
           "Oct": "0.0650",
           "Nov": "0.0688",
           "Dec": "0.0600",
           "AVE": "0.0788"
        },
        {
          "Sr. No": "70",
           "Year": "1950",
           "Jan": "-0.0208",
           "Feb": "-0.0126",
           "Mar": "-0.0084",
           "Apr": "-0.0126",
           "May": "-0.0042",
           "Jun": "-0.0042",
           "Jul": "0.0169",
           "Aug": "0.0210",
           "Sept": "0.0209",
           "Oct": "0.0380",
           "Nov": "0.0378",
           "Dec": "0.0593",
           "AVE": "0.0109"
        },
        {
          "Sr. No": "71",
           "Year": "1949",
           "Jan": "0.0127",
           "Feb": "0.0128",
           "Mar": "0.0171",
           "Apr": "0.0042",
           "May": "-0.0042",
           "Jun": "-0.0083",
           "Jul": "-0.0287",
           "Aug": "-0.0286",
           "Sept": "-0.0245",
           "Oct": "-0.0287",
           "Nov": "-0.0165",
           "Dec": "-0.0207",
           "AVE": "-0.0095"
        },
        {
          "Sr. No": "72",
           "Year": "1948",
           "Jan": "0.1023",
           "Feb": "0.0930",
           "Mar": "0.0685",
           "Apr": "0.0868",
           "May": "0.0913",
           "Jun": "0.0955",
           "Jul": "0.0991",
           "Aug": "0.0889",
           "Sept": "0.0652",
           "Oct": "0.0609",
           "Nov": "0.0476",
           "Dec": "0.0299",
           "AVE": "0.0774"
        },
        {
          "Sr. No": "73",
           "Year": "1947",
           "Jan": "0.1813",
           "Feb": "0.1878",
           "Mar": "0.1967",
           "Apr": "0.1902",
           "May": "0.1838",
           "Jun": "0.1765",
           "Jul": "0.1212",
           "Aug": "0.1139",
           "Sept": "0.1275",
           "Oct": "0.1058",
           "Nov": "0.0845",
           "Dec": "0.0884",
           "AVE": "0.1465"
        },
        {
          "Sr. No": "74",
           "Year": "1946",
           "Jan": "0.0225",
           "Feb": "0.0169",
           "Mar": "0.0281",
           "Apr": "0.0337",
           "May": "0.0335",
           "Jun": "0.0331",
           "Jul": "0.0939",
           "Aug": "0.1160",
           "Sept": "0.1271",
           "Oct": "0.1492",
           "Nov": "0.1768",
           "Dec": "0.1813",
           "AVE": "0.0843"
        },
        {
          "Sr. No": "75",
           "Year": "1945",
           "Jan": "0.0230",
           "Feb": "0.0230",
           "Mar": "0.0230",
           "Apr": "0.0171",
           "May": "0.0229",
           "Jun": "0.0284",
           "Jul": "0.0226",
           "Aug": "0.0226",
           "Sept": "0.0226",
           "Oct": "0.0226",
           "Nov": "0.0226",
           "Dec": "0.0225",
           "AVE": "0.0227"
        },
        {
          "Sr. No": "76",
           "Year": "1944",
           "Jan": "0.0296",
           "Feb": "0.0296",
           "Mar": "0.0116",
           "Apr": "0.0057",
           "May": "0.0000",
           "Jun": "0.0057",
           "Jul": "0.0172",
           "Aug": "0.0231",
           "Sept": "0.0172",
           "Oct": "0.0172",
           "Nov": "0.0172",
           "Dec": "0.0230",
           "AVE": "0.0164"
        },
        {
          "Sr. No": "77",
           "Year": "1943",
           "Jan": "0.0764",
           "Feb": "0.0696",
           "Mar": "0.0750",
           "Apr": "0.0807",
           "May": "0.0736",
           "Jun": "0.0736",
           "Jul": "0.0610",
           "Aug": "0.0485",
           "Sept": "0.0545",
           "Oct": "0.0419",
           "Nov": "0.0357",
           "Dec": "0.0296",
           "AVE": "0.0600"
        },
        {
          "Sr. No": "78",
           "Year": "1942",
           "Jan": "0.1135",
           "Feb": "0.1206",
           "Mar": "0.1268",
           "Apr": "0.1259",
           "May": "0.1319",
           "Jun": "0.1088",
           "Jul": "0.1156",
           "Aug": "0.1074",
           "Sept": "0.0927",
           "Oct": "0.0915",
           "Nov": "0.0909",
           "Dec": "0.0903",
           "AVE": "0.1097"
        },
        {
          "Sr. No": "79",
           "Year": "1941",
           "Jan": "0.0144",
           "Feb": "0.0071",
           "Mar": "0.0143",
           "Apr": "0.0214",
           "May": "0.0286",
           "Jun": "0.0426",
           "Jul": "0.0500",
           "Aug": "0.0643",
           "Sept": "0.0786",
           "Oct": "0.0929",
           "Nov": "0.1000",
           "Dec": "0.0993",
           "AVE": "0.0511"
        },
        {
          "Sr. No": "80",
           "Year": "1940",
           "Jan": "-0.0071",
           "Feb": "0.0072",
           "Mar": "0.0072",
           "Apr": "0.0145",
           "May": "0.0145",
           "Jun": "0.0217",
           "Jul": "0.0145",
           "Aug": "0.0145",
           "Sept": "-0.0071",
           "Oct": "0.0000",
           "Nov": "0.0000",
           "Dec": "0.0071",
           "AVE": "0.0073"
        },
        {
          "Sr. No": "81",
           "Year": "1939",
           "Jan": "-0.0141",
           "Feb": "-0.0142",
           "Mar": "-0.0142",
           "Apr": "-0.0282",
           "May": "-0.0213",
           "Jun": "-0.0213",
           "Jul": "-0.0213",
           "Aug": "-0.0213",
           "Sept": "0.0000",
           "Oct": "0.0000",
           "Nov": "0.0000",
           "Dec": "0.0000",
           "AVE": "-0.0130"
        },
        {
          "Sr. No": "82",
           "Year": "1938",
           "Jan": "0.0071",
           "Feb": "0.0000",
           "Mar": "-0.0070",
           "Apr": "-0.0070",
           "May": "-0.0208",
           "Jun": "-0.0208",
           "Jul": "-0.0276",
           "Aug": "-0.0276",
           "Sept": "-0.0342",
           "Oct": "-0.0411",
           "Nov": "-0.0345",
           "Dec": "-0.0278",
           "AVE": "-0.0201"
        },
        {
          "Sr. No": "83",
           "Year": "1937",
           "Jan": "0.0217",
           "Feb": "0.0217",
           "Mar": "0.0365",
           "Apr": "0.0438",
           "May": "0.0511",
           "Jun": "0.0435",
           "Jul": "0.0432",
           "Aug": "0.0357",
           "Sept": "0.0429",
           "Oct": "0.0429",
           "Nov": "0.0357",
           "Dec": "0.0286",
           "AVE": "0.0373"
        },
        {
          "Sr. No": "84",
           "Year": "1936",
           "Jan": "0.0147",
           "Feb": "0.0073",
           "Mar": "0.0000",
           "Apr": "-0.0072",
           "May": "-0.0072",
           "Jun": "0.0073",
           "Jul": "0.0146",
           "Aug": "0.0219",
           "Sept": "0.0219",
           "Oct": "0.0219",
           "Nov": "0.0145",
           "Dec": "0.0145",
           "AVE": "0.0104"
        },
        {
          "Sr. No": "85",
           "Year": "1935",
           "Jan": "0.0303",
           "Feb": "0.0301",
           "Mar": "0.0301",
           "Apr": "0.0376",
           "May": "0.0376",
           "Jun": "0.0224",
           "Jul": "0.0224",
           "Aug": "0.0224",
           "Sept": "0.0074",
           "Oct": "0.0148",
           "Nov": "0.0222",
           "Dec": "0.0299",
           "AVE": "0.0256"
        },
        {
          "Sr. No": "86",
           "Year": "1934",
           "Jan": "0.0233",
           "Feb": "0.0472",
           "Mar": "0.0556",
           "Apr": "0.0556",
           "May": "0.0556",
           "Jun": "0.0551",
           "Jul": "0.0229",
           "Aug": "0.0152",
           "Sept": "0.0303",
           "Oct": "0.0227",
           "Nov": "0.0227",
           "Dec": "0.0152",
           "AVE": "0.0351"
        },
        {
          "Sr. No": "87",
           "Year": "1933",
           "Jan": "-0.0979",
           "Feb": "-0.0993",
           "Mar": "-0.1000",
           "Apr": "-0.0935",
           "May": "-0.0803",
           "Jun": "-0.0662",
           "Jul": "-0.0368",
           "Aug": "-0.0222",
           "Sept": "-0.0149",
           "Oct": "-0.0075",
           "Nov": "0.0000",
           "Dec": "0.0076",
           "AVE": "-0.0509"
        },
        {
          "Sr. No": "88",
           "Year": "1932",
           "Jan": "-0.1006",
           "Feb": "-0.1019",
           "Mar": "-0.1026",
           "Apr": "-0.1032",
           "May": "-0.1046",
           "Jun": "-0.0993",
           "Jul": "-0.0993",
           "Aug": "-0.1060",
           "Sept": "-0.1067",
           "Oct": "-0.1074",
           "Nov": "-0.1020",
           "Dec": "-0.1027",
           "AVE": "-0.1030"
        },
        {
          "Sr. No": "89",
           "Year": "1931",
           "Jan": "-0.0702",
           "Feb": "-0.0765",
           "Mar": "-0.0769",
           "Apr": "-0.0882",
           "May": "-0.0947",
           "Jun": "-0.1012",
           "Jul": "-0.0904",
           "Aug": "-0.0848",
           "Sept": "-0.0964",
           "Oct": "-0.0970",
           "Nov": "-0.1037",
           "Dec": "-0.0932",
           "AVE": "-0.0894"
        },
        {
          "Sr. No": "90",
           "Year": "1930",
           "Jan": "0.0000",
           "Feb": "-0.0058",
           "Mar": "-0.0059",
           "Apr": "0.0059",
           "May": "-0.0059",
           "Jun": "-0.0175",
           "Jul": "-0.0405",
           "Aug": "-0.0462",
           "Sept": "-0.0405",
           "Oct": "-0.0462",
           "Nov": "-0.0520",
           "Dec": "-0.0640",
           "AVE": "-0.0266"
        },
        {
          "Sr. No": "91",
           "Year": "1929",
           "Jan": "-0.0116",
           "Feb": "0.0000",
           "Mar": "-0.0058",
           "Apr": "-0.0117",
           "May": "-0.0116",
           "Jun": "0.0000",
           "Jul": "0.0117",
           "Aug": "0.0117",
           "Sept": "0.0000",
           "Oct": "0.0058",
           "Nov": "0.0058",
           "Dec": "0.0058",
           "AVE": "0.0000"
        },
        {
          "Sr. No": "92",
           "Year": "1928",
           "Jan": "-0.0114",
           "Feb": "-0.0172",
           "Mar": "-0.0116",
           "Apr": "-0.0116",
           "May": "-0.0115",
           "Jun": "-0.0284",
           "Jul": "-0.0116",
           "Aug": "-0.0058",
           "Sept": "0.0000",
           "Oct": "-0.0115",
           "Nov": "-0.0058",
           "Dec": "-0.0116",
           "AVE": "-0.0115"
        },
        {
          "Sr. No": "93",
           "Year": "1927",
           "Jan": "-0.0223",
           "Feb": "-0.0279",
           "Mar": "-0.0281",
           "Apr": "-0.0335",
           "May": "-0.0225",
           "Jun": "-0.0056",
           "Jul": "-0.0114",
           "Aug": "-0.0115",
           "Sept": "-0.0114",
           "Oct": "-0.0114",
           "Nov": "-0.0226",
           "Dec": "-0.0226",
           "AVE": "-0.0192"
        },
        {
          "Sr. No": "94",
           "Year": "1926",
           "Jan": "0.0347",
           "Feb": "0.0407",
           "Mar": "0.0289",
           "Apr": "0.0407",
           "May": "0.0289",
           "Jun": "0.0114",
           "Jul": "-0.0113",
           "Aug": "-0.0169",
           "Sept": "-0.0113",
           "Oct": "-0.0056",
           "Nov": "-0.0167",
           "Dec": "-0.0112",
           "AVE": "0.0094"
        },
        {
          "Sr. No": "95",
           "Year": "1925",
           "Jan": "0.0000",
           "Feb": "0.0000",
           "Mar": "0.0117",
           "Apr": "0.0118",
           "May": "0.0176",
           "Jun": "0.0294",
           "Jul": "0.0351",
           "Aug": "0.0412",
           "Sept": "0.0351",
           "Oct": "0.0291",
           "Nov": "0.0465",
           "Dec": "0.0347",
           "AVE": "0.0244"
        },
        {
          "Sr. No": "96",
           "Year": "1924",
           "Jan": "0.0298",
           "Feb": "0.0238",
           "Mar": "0.0179",
           "Apr": "0.0059",
           "May": "0.0059",
           "Jun": "0.0000",
           "Jul": "-0.0058",
           "Aug": "-0.0058",
           "Sept": "-0.0058",
           "Oct": "-0.0058",
           "Nov": "-0.0058",
           "Dec": "0.0000",
           "AVE": "0.0045"
        },
        {
          "Sr. No": "97",
           "Year": "1923",
           "Jan": "-0.0059",
           "Feb": "-0.0059",
           "Mar": "0.0060",
           "Apr": "0.0120",
           "May": "0.0120",
           "Jun": "0.0180",
           "Jul": "0.0238",
           "Aug": "0.0301",
           "Sept": "0.0361",
           "Oct": "0.0359",
           "Nov": "0.0298",
           "Dec": "0.0237",
           "AVE": "0.0180"
        },
        {
          "Sr. No": "98",
           "Year": "1922",
           "Jan": "-0.1105",
           "Feb": "-0.0815",
           "Mar": "-0.0874",
           "Apr": "-0.0773",
           "May": "-0.0565",
           "Jun": "-0.0511",
           "Jul": "-0.0508",
           "Aug": "-0.0621",
           "Sept": "-0.0514",
           "Oct": "-0.0457",
           "Nov": "-0.0345",
           "Dec": "-0.0231",
           "AVE": "-0.0610"
        },
        {
          "Sr. No": "99",
           "Year": "1921",
           "Jan": "-0.0155",
           "Feb": "-0.0564",
           "Mar": "-0.0711",
           "Apr": "-0.1084",
           "May": "-0.1408",
           "Jun": "-0.1579",
           "Jul": "-0.1490",
           "Aug": "-0.1281",
           "Sept": "-0.1250",
           "Oct": "-0.1206",
           "Nov": "-0.1212",
           "Dec": "-0.1082",
           "AVE": "-0.1085"
        },
        {
          "Sr. No": "100",
           "Year": "1920",
           "Jan": "0.1697",
           "Feb": "0.2037",
           "Mar": "0.2012",
           "Apr": "0.2156",
           "May": "0.2189",
           "Jun": "0.2367",
           "Jul": "0.1954",
           "Aug": "0.1469",
           "Sept": "0.1236",
           "Oct": "0.0994",
           "Nov": "0.0703",
           "Dec": "0.0265",
           "AVE": "0.1590"
        },
        {
          "Sr. No": "101",
           "Year": "1919",
           "Jan": "0.1786",
           "Feb": "0.1489",
           "Mar": "0.1714",
           "Apr": "0.1761",
           "May": "0.1655",
           "Jun": "0.1497",
           "Jul": "0.1523",
           "Aug": "0.1494",
           "Sept": "0.1338",
           "Oct": "0.1313",
           "Nov": "0.1350",
           "Dec": "0.1455",
           "AVE": "0.1531"
        },
        {
          "Sr. No": "102",
           "Year": "1918",
           "Jan": "0.1966",
           "Feb": "0.1750",
           "Mar": "0.1667",
           "Apr": "0.1270",
           "May": "0.1328",
           "Jun": "0.1308",
           "Jul": "0.1797",
           "Aug": "0.1846",
           "Sept": "0.1805",
           "Oct": "0.1852",
           "Nov": "0.2074",
           "Dec": "0.2044",
           "AVE": "0.1726"
        },
        {
          "Sr. No": "103",
           "Year": "1917",
           "Jan": "0.1250",
           "Feb": "0.1538",
           "Mar": "0.1429",
           "Apr": "0.1887",
           "May": "0.1963",
           "Jun": "0.2037",
           "Jul": "0.1852",
           "Aug": "0.1927",
           "Sept": "0.1982",
           "Oct": "0.1947",
           "Nov": "0.1739",
           "Dec": "0.1810",
           "AVE": "0.1780"
        },
        {
          "Sr. No": "104",
           "Year": "1916",
           "Jan": "0.0297",
           "Feb": "0.0400",
           "Mar": "0.0606",
           "Apr": "0.0600",
           "May": "0.0594",
           "Jun": "0.0693",
           "Jul": "0.0693",
           "Aug": "0.0792",
           "Sept": "0.0990",
           "Oct": "0.1078",
           "Nov": "0.1165",
           "Dec": "0.1262",
           "AVE": "0.0764"
        },
        {
          "Sr. No": "105",
           "Year": "1915",
           "Jan": "0.0100",
           "Feb": "0.0101",
           "Mar": "0.0000",
           "Apr": "0.0204",
           "May": "0.0202",
           "Jun": "0.0202",
           "Jul": "0.0100",
           "Aug": "-0.0098",
           "Sept": "-0.0098",
           "Oct": "0.0099",
           "Nov": "0.0098",
           "Dec": "0.0198",
           "AVE": "0.0092"
        },
        {
          "Sr. No": "106",
           "Year": "1914",
           "Jan": "0.0204",
           "Feb": "0.0102",
           "Mar": "0.0102",
           "Apr": "0.0000",
           "May": "0.0206",
           "Jun": "0.0102",
           "Jul": "0.0101",
           "Aug": "0.0303",
           "Sept": "0.0200",
           "Oct": "0.0100",
           "Nov": "0.0099",
           "Dec": "0.0100",
           "AVE": "0.0135"
        },
        
      ];

      let tempData = [...data];
      tempData = tempData.reverse();
      let arr = {
        title: {
          text: "Historical Inflation Rate [CPI]"
        },
        animationEnabled: true,
        animationDuration: 1200,
        data: [{
          type: "column",
          dataPoints: []
        }]

      };
      for(let i= tempData.length-1; i>=0; i--){
          arr.data[0].dataPoints.push({ label: tempData[i].Year, y: tempData[i].AVE*100 })
      }

      console.log("printing data points after adding data in it", arr.data[0].dataPoints);

      arr.data[0].dataPoints = arr.data[0].dataPoints.reverse();

      return(
        <CanvasJSChart options={arr} />
      )
}
export default Chart;