import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import PremiumAllianceLogo from "../../../assets/images/PremiumAllianceLogo.png";
import MyAdvisorCloudLogo from "../../../assets/images/MyAdvisorCloudLogo.png";
import {
  Alert,
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Snackbar,
} from "@mui/material";
import "./LoginHeader.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MenuIcon from "@mui/icons-material/Menu";
import { Context as AuthContext } from "../../../context/auth_context";


const LoginHeader = ({isLogin}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { state,SetErrorMessageToNull } = useContext(AuthContext);

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const handleClose = () =>{
    SetErrorMessageToNull(["",""])
  }
  return (
    <Grid container className="header_login">
      {state.message != "" && state.message !="Invalid credentials" && state.message != "You are rejected by administrator." && 
        <Snackbar open={true} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
        {state.message}
        </Alert>
      </Snackbar>
      }
      
      {/* <div className="hamburgerMenu">
        <IconButton onClick={toggleDrawer}>
          <MenuIcon sx={{ color: "black" }} />
        </IconButton>
        <Drawer
        className="hamburgerMenu"
          anchor="left"
          open={open}
          onClose={toggleDrawer}
          onOpen={toggleDrawer}
          swipeAreaWidth={80}
        >
          <Grid className="sideBarBox" width={"220px"}>
            <Grid item className="sideBarItem" >
              {" "}
              <Link
                underline="none"
                color="black"
                href="https://premiumalliance.com"
                style={{ cursor: "pointer", margin: "0px 15px" }}
              >
                Home
              </Link>
            </Grid>
            <Grid item>
              <Link
                underline="none"
                color="black"
                href="https://premiumalliance.com/contact"
                style={{ cursor: "pointer", margin: "0px 15px" }}
              >
                Contact Us
              </Link>
            </Grid>
            <Grid item>
              <span
                onClick={() => {
                  navigate("/login");
                }}
                style={{ cursor: "pointer", margin: "0px 15px" }}
              >
                Login
              </span>
            </Grid>
          </Grid>
        </Drawer>
      </div> */}
      <Grid item className="left_section">
        <Grid container display={"flex"} justifyContent={"space-between"} >
          <Grid item>
            <a
            className="premiumallianceLogo"
              href="https://premiumalliance.com/"
            
            >
              <img
                src={PremiumAllianceLogo}
                alt="premium alliance logo"
                className="second_image_logo"
              />
            </a>
          </Grid>
          &nbsp;&nbsp;&nbsp;
          <Grid item>
            <span
              onClick={() => navigate("/home")}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={MyAdvisorCloudLogo}
                alt="my advisor cloud logo"
                className="first_image_logo"
              />
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className="right_section">
        <Link
          underline="none"
          color="black"
          href="https://premiumalliance.com"
          style={{ cursor: "pointer", margin: "0px 15px" }}
        >
          Home
        </Link>
        <Link
          underline="none"
          color="black"
          href="https://premiumalliance.com/contact"
          style={{ cursor: "pointer", margin: "0px 15px" }}
        >
          Contact Us
        </Link>
        {/* <Link
          underline="none"
          color="black"
          href="https://premiumalliance.com"
          style={{ cursor: 'pointer', margin: '0px 15px' }}
        >
          About Us
        </Link> */}
        <span
          onClick={() => {
            navigate("/login");
          }}
          style={{ cursor: "pointer", margin: "0px 15px",color: isLogin ? "white" : "black" }}
        >
          Login
        </span>
      </Grid>
    </Grid>
  );
};

export default LoginHeader;
