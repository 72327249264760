import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Checkbox,
  CssBaseline,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  Menu,
  MenuItem,
  Select,
  Snackbar,
  styled,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import React, { useContext, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Header from "../../components/header/header";
import "./UserListNew.css";
import { Context as AuthContext } from "../../context/auth_context";
import { GridToolbar } from "@mui/x-data-grid";
import { CheckCircleRounded, ErrorRounded } from "@mui/icons-material";
import {StyledSubTabs} from "./SubTabs.style"
import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import RejectionNotePopup from "./RejectionNotePopup.jsx"

import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import Fade from "@mui/material/Fade";
// import RPage from "../RPage/RPage";
import Stack from "@mui/material/Stack";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  GetAllUsersAPI,
  UpdateUserStatusAPI,
} from "../../service/pendingusers.service";
import arrowUp from "../../assets/images/up-arrow.png";
import arrowDown from "../../assets/images/arrow-down.png";
// Popup images
import dustbin from "../../assets/images/dustbin.png";
import yellowAlert from "../../assets/images/yellowAlert.png";
import rightTickmarkImg from "../../assets/images/rightTickMarkimg.png";
import redAlertImg from "../../assets/images/redAlertImg.png";

// Radio Button
const IOSSwitch = styled((props) => (
  <Switch
    onChange={props.onChange}
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#c8bba1" : "#c8bba1",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 15,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

function NoRowsOverlay() {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      No Data Found
      {/* <pre>(rows=&#123;[]&#125;)</pre> */}
    </Stack>
  );
}

const EmailVerifiedComponent = ({ is_verified }) => {
  return (
    <Typography
      sx={{
        textAlign: "center",
        padding: "0.2rem 0.7rem",
        fontSize: "0.7rem",
        borderRadius: "1rem",
        color: is_verified === 0 ? "red" : "green",
        border: is_verified === 0 ? "1px solid red" : "1px solid green",
        width: "fit-content",
        fontWeight: "500",
        letterSpacing: "0.4px",
      }}
    >
      {is_verified === 0 ? "Not Verified" : "Verified"}
    </Typography>
  );
};

const UserListNew = (props) => {
  const [flagApprovePopup, setFlagApprovePopup] = useState(false);
  const [flagAgentApproved, setFlagAgentApproved] = useState(false);

  const [flagCancelPopup, setFlagCancelPopup] = useState(false);
  const [flagAgentCancel, setFlagAgentCancel] = useState(false);

  const [flagRejectPopup, setFlagRejectPopup] = useState(false);
  const [flagAgentReject, setFlagAgentReject] = useState(false);
  const [message, setMessage] = useState({
    status: false,
    message: "",
    error: false,
  });
  const [agentToDeleteInfo, setAgentToDeleteInfo] = useState({});
  const [currentStatusId, setCurrentStatusId] = useState(-1);
  const [editAgent, setEditAgent] = useState({});
  const [editFlag, setEditFlag] = useState(false);
  const [globalAgentList, setGlobalAgentList] = React.useState([{}]);
  const [orderAscDesc, setOrderAscDesc] = useState("asc");
  const [filterTableFlag, setFilterTableFlag] = useState(false);
  const [filterTable, setFilterTable] = useState("user_firstname");
  const [agentList, setAgentList] = useState([]);
  const [flagAgentDeleted, setFlagAgentDeleted] = useState(false);

  const { GetAllUserData, setflagloader, state } = useContext(AuthContext);

  const { allUserData } = state;
  console.log("aaaaaaaaaaaaaaa:", allUserData);

  // console.log("pendingUsersData:", allUserData);

  const [userData, setUserData] = useState([]);
  const [rowData, setRowData] = useState([]);
  console.log("userData:", userData);

  const [selectedAgent, setSelectedAgent] = React.useState({});

  // const { UpdateLoader, previousUrl, state } = useContext(mainContext);
  const [open, setOpen] = React.useState({
    status: false,
    id: 0,
  });
  const [flagDeleteUserPopup, setFlagDeleteUserPopup] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [flagUserStatusChange, setFlagUserStatusChange] = useState(false);
  const [flagApproveUserPopup, setFlagApproveUserPopup] = useState(false);
  const [flagRejectUserPopup, setFlagRejectUserPopup] = useState(false);
  const [isUserPending, setIsUserPending] = useState(false);
  const [isEmailVerificationPending, setIsEmailVerificationPending] =
    useState(false);
    const [showRejectionNotePopup, setShowRejectionNotePopup] = useState(false)
    const [rejectionNotePopupDetail,setRejectionNotePopupDetail] = useState({})

  const [selectShowPendingButton, setSelectShowPendingButton] = useState(false);
  const [selectShowAllButton, setSelectShowAllButton] = useState(true);
  const [buttonClick, setButtonClick] = useState("Agents List");

  const open1 = Boolean(anchorEl);
  const location = useLocation();
  const navigate = useNavigate();

  function GetAllUser() {
    GetAllUsersAPI(
      (res) => {
        if (res.data.status) {
          // debugger

          setUserData(res.data?.data[0]);
          if (buttonClick === "Pending") {
            
            if (selectedTab == "Rejected") {
              const temprow = res.data.data[0].agents
                .filter((row) => {
                  return row.user_status == "Rejected";
                })
                .map((rr, i) => {
                  return { ...rr, user_increment_id: i + 1 };
                });
                setRowData(temprow);
            } else {
              const temprow = res.data.data[0].agents
                .filter((row) => {
                  return row.user_status == "Pending";
                })
                .map((rr, i) => {
                  return { ...rr, user_increment_id: i + 1 };
                });
                setRowData(temprow);
            }
            
          } else {
            var data = res.data?.data[0].agents
              .filter((row) => {
                return (
                  row.user_status == "Active" || row.user_status == "Inactive"
                );
              })
              .sort((a, b) =>
                a.user_full_name.toLowerCase() > b.user_full_name.toLowerCase()
                  ? 1
                  : b.user_full_name.toLowerCase() >
                    a.user_full_name.toLowerCase()
                  ? -1
                  : 0
              )
              .map((rr, i) => {
                return { ...rr, user_increment_id: i + 1 };
              });
            // var data = res.data?.data[0].agents;
            setRowData(data);
          }
          // setButtonClick("Agents List");
          // setSelectShowPendingButton(false);
          // setSelectShowAllButton(true);
        } else {
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  useEffect(() => {
    GetAllUser();
  }, []);

  const queryParams = new URLSearchParams(window.location.search);
  const LinkId = queryParams.get("agent_id");
  const [togglePopup, setTogglePopup] = useState(false);
  const [togglePopupReject, setTogglePopupReject] = useState(false);

  function UserStatusIosSwitchChange() {
    setTogglePopup(true);
  }


  function UpdateUserStatus(user_id, status_id,rejection_note) {
    let obj = {
      user_id: user_id,
      status_id: status_id,
      rejection_note:rejection_note ? rejection_note : null,
      send_notification: true,
    };
    setflagloader(true);

    UpdateUserStatusAPI(
      obj,
      (res) => {
        if (res.data.status) {
          GetAllUser();
          setflagloader(false);
          switch (status_id) {
            case 1:
              setOpenSnackbar({
                ...openSnackbar,
                flag: true,
                message: "User Status changed successfully",
              });
              break;
            case 2:
              setOpenSnackbar({
                ...openSnackbar,
                flag: true,
                message: "User approved successfully",
              });
              break;
            case 3:
              setOpenSnackbar({
                ...openSnackbar,
                flag: true,
                message: "User rejected successfully ",
              });
              break;
            case 4:
              setOpenSnackbar({
                ...openSnackbar,
                flag: true,
                message: "User deleted successfully",
              });
              break;
            default:
          }
        } else {
          alert("Error");
        }
      },
      (err) => {
        setflagloader(false);
        console.log("error", err);
      }
    );
  }

  const [defaultSearch, setDefaultSearch] = useState({
    filter: {
      filterModel: {
        items: [{ columnField: "user_id", operatorValue: "=", value: LinkId }],
      },
    },
  });

  const handleClick = (event) => {
    console.log("aaaaaaaaaaaa", event.currentTarget);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setEditAgent({});
    setOpen(false);
    setAnchorEl(null);
  };

  const handleClickOpen = () => {
    setEditAgent({});
    setOpen({
      status: true,
      id: 1,
    });
  };

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });

  //all Columns

  const columns = [
    {
      field: "user_increment_id",
      headerName: "#",
      width: 50,
      maxWidth: 50,
      minWidth: 50,
      filterable: false,
      align: "center",
      headerAlign: "center",
      flex: 1,
    },

    {
      field: "user_full_name",
      headerName: "Name",
      maxWidth: 400,
      minWidth: 200,
      type: "string",
      align: "left",
      flex: 1,
      cellClassName: "user_full_name_column_style",
      headerClassName: "user_full_name_column_style",
      defaultSort: "asc",

      // flex: 1,
      renderCell: (params) => {
        var firstname = params.row.user_full_name;

        return (
          <span>
            {firstname}{" "}
            {params.row.user_type_name == "Admin" && (
              <span className="agent-admin-badge">Admin</span>
            )}
          </span>
        );
      },
    },
    {
      field: "user_email",
      headerName: "Email",
      maxWidth: 500,
      minWidth: 300,
      editable: false,
      flex: 1,
      cellClassName: "user_email_column_style",
    },
    {
      field: "created_at",
      headerName: "Created Date",
      maxWidth: 300,
      minWidth: 200,
      type: "date",
      cellClassName: "created_at_column_style",
      valueFormatter: (params) =>
        moment(params?.value).format("MM/DD/YYYY hh:mm A"),
      align: "left",
      headerAlign: "left",
      flex: 1,
    },

    {
      field: "user_status_id",
      headerName: "Status",
      cellClassName: "status_column_style",
      headerClassName: "status_column_style",
      maxWidth: 200,
      minWidth: 200,
      align: "center",
      type: "string",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        let row = params.row;
        return (
          <>
            {true ? (
              <IOSSwitch
                checked={row.user_status_id == 2 ? true : false}
                onChange={() => {
                  // debugger;

                  setTogglePopup(true);
                  // console.log("row111", row);

                  setSelectedAgent({ ...row });
                  setPopUpDetails({
                    topImage: yellowAlert,
                    messageTitle: "Change Status",
                    messageText:
                      "Are you sure you want to change status of " +
                      row.user_full_name +
                      "?",
                    trueButtonFunction: test(),
                    content: iosTogglePopupButtonsHTML(row),
                  });
                  // let tempData = [...rowData];
                  // tempData.map((ele) => {
                  //   if (ele.user_id == row.user_id) {
                  //     ele.user_status_id = 5;
                  //   }
                  // });
                  // setRowData(tempData);
                  // UpdateUserStatus(row.user_id, 1);
                  // console.log("toggle");
                }}
              />
            ) : row.user_status_id == 5 ? (
              <IOSSwitch checked={false} />
            ) : (
              ""
            )}
          </>
        );
      },
    },

    {
      field: "user_id",
      headerName: "",
      maxWidth: 80,
      minWidth: 80,
      sortable: false,
      type: "",
      disableColumnMenu: true,
      // flex: 1,
      // align: "left",
      // headerAlign: "left",
      cellClassName: "lastColumn_style",
      renderCell: (params) => {
        let row = params.row;

        return (
          <>
            <Button
              id="fade-button"
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(e) => {
                console.log("row:", row);
                // debugger
                if (row.is_verified == 0) {
                  setIsEmailVerificationPending(true);
                  handleClick(e);
                } else {
                  if (row.user_status_id === 1) {
                    setIsUserPending(true);
                  } else {
                    setIsUserPending(false);
                  }
                  setIsEmailVerificationPending(false);
                }
                setSelectedAgent(row);
                console.log(row);
                handleClick(e);
              }}
            >
              <MoreVertIcon className="optionIcon" color="black" />
            </Button>
          </>
        );
      },
    },
  ];

  //* columns for registration request

  const RegistrationRequestColumns = [
    {
      field: "user_increment_id",
      headerName: "#",
      width: 50,
      maxWidth: 50,
      filterable: false,
      align: "center",
      headerAlign: "center",
      flex: 1,

      renderCell: (params) => {
        console.log("params:", params);
        return (
          <div>
            {params.api.getRowIndexRelativeToVisibleRows(params.row.user_id) +
              1}
          </div>
        );
      },
    },
    {
      field: "user_full_name",
      headerName: "Name",
      maxWidth: 310,
      minWidth: 200,
      type: "string",
      align: "left",
      flex: 1,
      padding: "0px 0px 0px 10px",
      headerClassName: "RegistrationRequestColumns_user_full_name",
      cellClassName: "RegistrationRequestColumns_user_full_name ",

      // flex: 1,
      renderCell: (params) => {
        var firstname = params.row.user_full_name;

        return (
          <span>
            {firstname}{" "}
            {params.row.user_type_name == "Admin" && (
              <span className="agent-admin-badge">Admin</span>
            )}
          </span>
        );
      },
    },
    {
      field: "user_email",
      headerName: "Email",
      maxWidth: 400,
      minWidth: 250,
      flex: 1,
      editable: false,
      headerClassName: "RegistrationRequestColumns_user_email",
      cellClassName: "RegistrationRequestColumns_user_email ",
    },
    {
      field: "created_at",
      headerName: "Created Date",
      maxWidth: 250,
      minWidth: 150,
      type: "date",
      flex: 1,
      valueFormatter: (params) =>
        moment(params?.value).format("MM/DD/YYYY hh:mm A"),
      align: "left",
      headerAlign: "left",
    },

    {
      field: "is_verified",
      headerName: "Email Verification",
      maxWidth: 150,
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        let row = params.row;
        return (
          <>
            {row.is_verified == 0 && (
              <span class="agent-label-warning">Pending</span>
            )}
          </>
        );
      },
    },
    {
      field: "1",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      maxWidth: 250,
      minWidth: 250,
      flex: 1,
      renderCell: (params) => {
        let row = params.row;
        return row.is_verified ? (
          <>
            <Typography
              onClick={() => {
                setSelectedAgent(row);
                // setFlagApproveUserPopup(true);
                console.log("1111", params);
                setTogglePopup(true);
                setPopUpDetails({
                  topImage: rightTickmarkImg,
                  messageTitle: "Approve",
                  messageText:
                    "Are you sure you want to approve " +
                    row.user_full_name +
                    "?",
                  content: ApprovePopupButtonsHTML(row),
                });
              }}
              sx={{
                textAlign: "center",
                padding: "0.2rem 0.7rem",
                fontSize: "0.9rem",
                borderRadius: "5px",
                background: "#47921c",
                color: "#fff",
                // color: is_verified === 0 ? "red" : "green",
                // border: is_verified === 0 ? "1px solid red" : "1px solid green",
                width: "fit-content",
                fontWeight: "500",
                letterSpacing: "0.4px",
                marginRight: "0.5rem",
                cursor: "pointer",
              }}
            >
              {/* #47921c */}
              {/* #df3131 */}
              Approve
            </Typography>
            <Typography
              onClick={() => {
                setSelectedAgent(row);
                // setFlagRejectUserPopup(true);
                setTogglePopupReject(true);
                setPopUpDetails({
                  topImage: redAlertImg,
                  messageTitle: "Reject",
                  messageText:
                    "Are you sure you want to reject " +
                    row.user_full_name +
                    "?",
                });
              }}
              sx={{
                textAlign: "center",
                padding: "0.2rem 0.7rem",
                fontSize: "0.9rem",
                borderRadius: "5px",
                background: "#df3131",
                color: "#fff",
                // color: is_verified === 0 ? "red" : "green",
                // border: is_verified === 0 ? "1px solid red" : "1px solid green",
                width: "fit-content",
                fontWeight: "400",
                letterSpacing: "0.4px",
                cursor: "pointer",
              }}
            >
              Reject
            </Typography>
          </>
        ) : (
          ""
        );
      },
    },
  ];

  const RejectedAgentsColumns = [
    {
      field: "user_increment_id",
      headerName: "#",
      width: 50,
      maxWidth: 50,
      filterable: false,
      align: "center",
      headerAlign: "center",
      flex: 1,

      renderCell: (params) => {
        console.log("params:", params);
        return (
          <div>
            {params.api.getRowIndexRelativeToVisibleRows(params.row.user_id) +
              1}
          </div>
        );
      },
    },
    {
      field: "user_full_name",
      headerName: "Name",
      maxWidth: 250,
      minWidth: 200,
      type: "string",
      align: "left",
      flex: 1,
      padding: "0px 0px 0px 10px",
      headerClassName: "RegistrationRequestColumns_user_full_name",
      cellClassName: "RegistrationRequestColumns_user_full_name ",

      // flex: 1,
      renderCell: (params) => {
        var firstname = params.row.user_full_name;

        return (
          <span>
            {firstname}{" "}
            {params.row.user_type_name == "Admin" && (
              <span className="agent-admin-badge">Admin</span>
            )}
          </span>
        );
      },
    },
    {
      field: "user_email",
      headerName: "Email",
      maxWidth: 350,
      minWidth: 250,
      flex: 1,
      editable: false,
      headerClassName: "RegistrationRequestColumns_user_email",
      cellClassName: "RegistrationRequestColumns_user_email ",
    },

    {
      field: "created_at",
      headerName: "Created Date",
      maxWidth: 200,
      minWidth: 150,
      type: "date",
      
      flex: 1,
      valueFormatter: (params) =>
        moment(params?.value).format("MM/DD/YYYY"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "deleted_at",
      headerName: "Rejected Date",
      maxWidth: 150,
      minWidth: 150,
      type: "date",
      
      flex: 1,
      valueFormatter: (params) =>
        moment(params?.value).format("MM/DD/YYYY"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "user_rejected_by",
      headerName: "Rejected by",
      maxWidth: 150,
      minWidth: 150,
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
  


    

    /*   {
      field: "is_verified",
      headerName: "Email Verification",
      maxWidth: 150,
      minWidth: 150,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        let row = params.row;
        return (
          <>
            {row.is_verified == 0 && (
              <span class="agent-label-warning">Pending</span>
            )}
          </>
        );
      },
    }, */
    {
      field: "1",
      headerName: "Action",
      // headerAlign: "center",
      // align: "center",
      maxWidth: 150,
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        let row = params.row;
        return true ? (
          <>
            <Typography
              onClick={() => {
                setSelectedAgent(row);
                // setFlagApproveUserPopup(true);
                console.log("1111", params);
                // setTogglePopup(true);
                setTogglePopup(true);
                setPopUpDetails({
                  topImage: rightTickmarkImg,
                  messageTitle: "Approve",
                  messageText:
                    "Are you sure you want to approve " +
                    row.user_full_name +
                    "?",
                  content: ApprovePopupButtonsHTML(row),
                });
              }}
              sx={{
                textAlign: "center",
                padding: "0.2rem 0.7rem",
                fontSize: "0.9rem",
                borderRadius: "5px",
                background: "#47921c",
                color: "#fff",
                // color: is_verified === 0 ? "red" : "green",
                // border: is_verified === 0 ? "1px solid red" : "1px solid green",
                width: "fit-content",
                fontWeight: "500",
                letterSpacing: "0.4px",
                cursor: "pointer",
              }}
            >
              {/* #47921c */}
              {/* #df3131 */}
              Approve
            </Typography>
            {/*   <Typography
              onClick={() => {
                setSelectedAgent(row);
                // setFlagRejectUserPopup(true);
                setTogglePopup(true);
                setPopUpDetails({
                  topImage: redAlertImg,
                  messageTitle: "Reject",
                  messageText:
                    "Are you sure you want to reject " +
                    row.user_full_name +
                    "?",
                  content: rejectPopupButtonsHTML(row),
                });
              }}
              sx={{
                textAlign: "center",
                padding: "0.2rem 0.7rem",
                fontSize: "0.9rem",
                borderRadius: "5px",
                background: "#df3131",
                color: "#fff",
                // color: is_verified === 0 ? "red" : "green",
                // border: is_verified === 0 ? "1px solid red" : "1px solid green",
                width: "fit-content",
                fontWeight: "400",
                letterSpacing: "0.4px",
                cursor: "pointer",
              }}
            >
              Reject
            </Typography> */}
          </>
        ) : (
          ""
        );
      },
    },
    {
      field: "user_rejection_note",
      headerName: "Note",
      maxWidth: 180,
      minWidth: 150,
      flex: 1,
      align: "left",
      headerAlign: "left",
      // onClick: setShowRejectionNotePopup(true)
      renderCell: (params) => {

console.log(params.row,"hello");
       return ( <div
          onClick={() => {;
            setShowRejectionNotePopup(true)
           setRejectionNotePopupDetail(params.row)}}
          style={{ cursor: 'pointer',color:"blue" }}
        >
         {params.row.user_rejection_note !== null ?params.row.user_rejection_note?.slice(0,25) + "...":""}
        </div>)
      },
    },
 
  ];

  // end all columns list here

  //handle tabs in request section
  const [selectedTab, setSelectedTab] = useState("Requests");
  const handleSelectTab = (e, newValue) => {
    console.log("newValue", newValue);
    setSelectedTab(newValue);
    let temprow;

    if (newValue == "Rejected") {
      temprow = userData.agents
        .filter((row) => {
          return row.user_status == "Rejected";
        })
        .map((rr, i) => {
          return { ...rr, user_increment_id: i + 1,rejected_date: new Date(),rejection_note:"this is the rejection note, added by admin while rejecting the user", rejected_by:"Meteena" };
        });
    } else {
      temprow = userData.agents
        .filter((row) => {
          return row.user_status == "Pending";
        })
        .map((rr, i) => {
          return { ...rr, user_increment_id: i + 1 };
        });
    }
    setRowData(temprow);
  };

  const handleOptionClose = () => {
    setAnchorEl(null);
  };

  const ShowPendingButtonClick = () => {
    // debugger
    setButtonClick("Pending");
    setSelectShowAllButton(false);
    setSelectShowPendingButton(true);

    if (selectedTab == "Rejected") {
      const temprow = userData.agents
        .filter((row) => {
          return row.user_status == "Rejected";
        })
        .map((rr, i) => {
          return { ...rr, user_increment_id: i + 1,rejected_date: new Date(),rejection_note:"this is the rejection note, added by admin while rejecting the user", rejected_by:"Meteena" };
        });
        setRowData(temprow);
    } else {
      const temprow = userData.agents
        .filter((row) => {
          return row.user_status == "Pending";
        })
        .map((rr, i) => {
          return { ...rr, user_increment_id: i + 1 };
        });
        setRowData(temprow);
    }
    
  
  };
  //
  const ShowAllUserButtonClick = () => {
    setButtonClick("Agents List");
    setSelectShowAllButton(true);
    setSelectShowPendingButton(false);

    let temp = userData.agents
      .filter((row) => {
        return row.user_status == "Active" || row.user_status == "Inactive";
      })
      .sort((a, b) =>
        a.user_full_name.toLowerCase() > b.user_full_name.toLowerCase()
          ? 1
          : b.user_full_name.toLowerCase() > a.user_full_name.toLowerCase()
          ? -1
          : 0
      )
      .map((rr, i) => {
        return { ...rr, user_increment_id: i + 1 };
      });
    // setRowData(temp);
    setRowData(temp);
  };

  const [popUpDetail, setPopUpDetails] = useState({});
  const [isAccordionexpanded, setIsAccordionexpanded] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState({
    flag: false,
    message: "",
  });
  const handleChange = (e) => {
    console.log("ttttttttttttttttttt", e);
    console.log("ttttttttttttttttttt", e.target.value);
    props.setRejectionNote(e.target.value);
  };
  useEffect(() => {
    console.log("popupDetail", popUpDetail);
  }, [popUpDetail]);

  const [rejectionNote, setRejectionNote] = useState("");

  const PopUp = ({
    topImage,
    messageTitle,
    messageText,
    setTogglePopup,
    content,
    isTitleRed,
    addNote,
  }) => {
    console.log("isTitleRed", isTitleRed);
    return (
      <>
        <Dialog
          open={true}
          // onClose={() => { setFlagAgentDeleted(false) }}

          onClose={props.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            <Grid
              container
              className="popUpContainer"
              sx={{
                peddingLeft: "10px",
                width: "500px",
                height: addNote ? "400px" : "300px",
                "@media screen and (max-width:600px)": {
                  height: addNote ? "420px" : "290px",
                },
                "@media screen and (max-width:400px)": {
                  height: addNote ? "420px" : "290px",
                },
              }}
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid
                item
                sx={{
                  marginLeft: "auto",
                  transform: "translate(9px, -25px) !important",
                  "@media screen and (max-width:600px)": {
                    transform: "translate(9px, -15px) !important",
                  },
                  // fontSize:"25px !important"
                }}
                onClick={() => setTogglePopup(false)}
              >
                <CloseIcon sx={{ fontSize: "30px" }} />
              </Grid>
              <Grid item height={"60px"}>
                {" "}
                <img src={topImage} />
              </Grid>
              <Grid item height={"70px"}>
                <Typography
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    fontWeight: "600",
                    color: "red",
                    // color: currentAction === "Active" ? "green" : "red",
                    margin: "1rem 0",
                  }}
                >
                  <span
                    style={{
                      fontSize: "25px",
                      textTransform: "capitalize",
                      color: isTitleRed == true ? "#d13d2b" : "black",
                      marginBottom: "50px",
                    }}
                  >
                    {messageTitle}
                  </span>
                </Typography>
              </Grid>

              <Grid item height={"50px"}>
                {" "}
                <Typography
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    fontWeight: "600",
                    margin: "30px 0 35",
                    wordWrap: "break-word",
                    marginBottom: "10px",
                    // color: "RGBA(88, 109, 125, 1)"
                  }}
                >
                  {messageText}
                </Typography>
              </Grid>
            
              <Grid item width={"100%"}>
                <Grid
                  container
                  direction={"row"}
                  justifyContent={"center"}
                  sx={{
                    marginTop: "0px",
                    flexWrap:"nowrap",
                    "@media screen and (max-width:600px)": {
                      marginTop: "30px !important",
                    },
                  }}
                >
                  {content}
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </>
    );
  };

  const PopUpForReject = ({
    topImage,
    messageTitle,
    messageText,
    setTogglePopupReject

  }) => {
    const [rejectionNote,setRejectionNote] = useState("")

    
    return (
      <>
        <Dialog
          open={true}
          // onClose={() => { setFlagAgentDeleted(false) }}

          onClose={props.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            <Grid
              container
              className="popUpContainer"
              sx={{
                peddingLeft: "10px",
                width: "500px",
                height:  "400px" ,
                "@media screen and (max-width:600px)": {
                  height:  "410px",
                },
                "@media screen and (max-width:400px)": {
                  height: "410px" ,
                },
              }}
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid
                item
                sx={{
                  marginLeft: "auto",
                  transform: "translate(9px, -25px) !important",
                  "@media screen and (max-width:600px)": {
                    transform: "translate(9px, -15px) !important",
                  },
                  // fontSize:"25px !important"
                }}
                onClick={() => setTogglePopupReject(false)}
              >
                <CloseIcon sx={{ fontSize: "30px" }} />
              </Grid>
              <Grid item height={"60px"}>
                {" "}
                <img src={topImage} />
              </Grid>
              <Grid item height={"70px"}>
                <Typography
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    fontWeight: "600",
                    color: "red",
                    // color: currentAction === "Active" ? "green" : "red",
                    margin: "1rem 0",
                  }}
                >
                  <span
                    style={{
                      fontSize: "25px",
                      textTransform: "capitalize",
                      color:"black",
                      marginBottom: "50px",
                    }}
                  >
                    {messageTitle}
                  </span>
                </Typography>
              </Grid>

              <Grid item height={"50px"}>
                {" "}
                <Typography
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    fontWeight: "600",
                    margin: "30px 0 35",
                    wordWrap: "break-word",
                    marginBottom: "10px",
                    // color: "RGBA(88, 109, 125, 1)"
                  }}
                >
                  {messageText}
                </Typography>
              </Grid>

              <Grid
                item
                width={"85%"}
                sx={{
                  fontWeight: "bold",
                  margin: "0px 0px 0px 0px",
                  "@media screen and (max-width:600px)": {
                    margin: "20px 0px 0px 0px",
                    width: "95%",
                  },
                }}
              >
                Note
              </Grid>
              <Grid
                item
                width={"85%"}
                sx={{
                  margin: "0px 0px 20px 0px",
                  "@media screen and (max-width:600px)": {
                    width: "95%",
                    margin: " 0px",
                  },
                }}
              >
                <textarea
                  // variant="filled"
                  key={0}
                  id="rejectionNote"
                  type="text"
                  name="rejectionNote"
                  value={rejectionNote}
                  placeholder="Please enter rejection note"
                  autoFocus
                  style={{
                    padding: "5px 7px",
                    width: "96%",
                    border:"none",
                    outline: "none",
                    background:"#f4f1ec",
                  }}
                  // value={rejectionNote}
                  onChange={(e) => {
                    setRejectionNote(e.target.value);
                    console.log("Rejection");
                  }}
                />
              </Grid>

              <Grid item width={"100%"}>
                <Grid
                  container
                  direction={"row"}
                  justifyContent={"center"}
                  sx={{
                    marginTop: "0px",
                    "@media screen and (max-width:600px)": {
                      marginTop: "30px !important",
                    },
                  }}
                >
                  <Grid item>
                    <Button
                      color="success"
                      variant="outlined"
                      sx={{
                        border: "1px solid #c8bba1",
                        color: "#000",
                        textTransform:"none",
                        marginRight: "20px",
                        "&:hover": {
                          border: "1px solid #c8bba1",
                        },
                      }}
                      onClick={() => setTogglePopupReject(false)}
                    >
                      No
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      //  color="#c8bba1"
                      sx={{
                        background: "#c8bba1",
                        color: "#000",
                        textTransform:"none",
                        "&:hover": {
                          backgroundColor: "#c8bba1",
                        },
                      }}
                      onClick={() => {

                        //TODO : make call with rejection Note
                        setTogglePopupReject(false);
                        console.log("454545 rejectionNote",rejectionNote)
                        UpdateUserStatus(selectedAgent.user_id, 3,rejectionNote);
                        console.log("454545", selectedAgent.user_id);
                      }}
                    >
                      Yes,Reject
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </>
    );
  };

  function test() {
    console.log("testttttttttttttttttttttt");
  }

  const deleteButtonHTML = (row) => {
    return (
      <>
        <Grid item>
          <Button
            variant="outlined"
            sx={{
              border: "1px solid #c8bba1",
              color: "#000",
              textTransform:"none",
              marginRight: "20px",
              "&:hover": {
                border: "1px solid #c8bba1",
              },
            }}
            onClick={() => setTogglePopup(false)}
          >
            No
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            sx={{
              background: "#c8bba1",
              color: "#000",
              textTransform:"none",
              "&:hover": {
                backgroundColor: "#c8bba1",
              },
            }}
            onClick={() => {
              setTogglePopup(false);

              UpdateUserStatus(selectedAgent.user_id, 4);
            }}
          >
            Yes,Delete
          </Button>
        </Grid>
      </>
    );
  };

  const iosTogglePopupButtonsHTML = (row) => {
    console.log("statusToChange", row);

    return (
      <>
        <Grid item>
          <Button
            variant="outlined"
            sx={{
              border: "1px solid #c8bba1",
              color: "#000",
              marginRight: "20px",
              textTransform:"none",
              "&:hover": {
                border: "1px solid #c8bba1",
              },
            }}
            onClick={() => setTogglePopup(false)}
          >
            No
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            sx={{
              background: "#c8bba1",
              textTransform:"none",
              color: "#000",
              "&:hover": {
                backgroundColor: "#c8bba1",
              },
            }}
            onClick={() => {
              setTogglePopup(false);
              // console.log("row.user",row.user_id,row.user_status_id == 1 ? 2 : 1);
              UpdateUserStatus(row.user_id, row.user_status_id == 1 ? 2 : 1);
            }}
          >
            Yes,Change
          </Button>
        </Grid>
      </>
    );
  };

  const ApprovePopupButtonsHTML = (row) => {
    console.log("rowwwww", row);
    return (
      <>
        <Grid item>
          <Button
            variant="outlined"
            sx={{
              border: "1px solid #c8bba1",
              textTransform:"none",
              color: "#000",
              marginRight: "20px",
              "&:hover": {
                border: "1px solid #c8bba1",
              },
            }}
            onClick={() => setTogglePopup(false)}
          >
            No
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            sx={{
              background: "#c8bba1",
              color: "#000",
              textTransform:"none",
              "&:hover": {
                backgroundColor: "#c8bba1",
              },
            }}
            onClick={() => {
              setTogglePopup(false);
              console.log("selectedAgent", selectedAgent);

              UpdateUserStatus(row.user_id, 2);
            }}
          >
            Yes,Approve
          </Button>
        </Grid>
      </>
    );
  };

  const rejectPopupButtonsHTML = (row) => {
    console.log("rejectRow", row);
    return (
      <>
        <Grid item>
          <Button
            color="success"
            variant="outlined"
            sx={{
              border: "1px solid #c8bba1",
              color: "#000",
              marginRight: "20px",
              "&:hover": {
                border: "1px solid #c8bba1",
              },
            }}
            onClick={() => setTogglePopup(false)}
          >
            No
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            //  color="#c8bba1"
            sx={{
              background: "#c8bba1",
              color: "#000",
              "&:hover": {
                backgroundColor: "#c8bba1",
              },
            }}
            onClick={() => {
              setTogglePopup(false);
              UpdateUserStatus(row.user_id, 3);
            }}
          >
            Yes
          </Button>
        </Grid>
      </>
    );
  };
  const options = [
    { value: "agentsList", label: "Agents List" },
    { value: "registrationRequests", label: "Registration Requests" },
  ];

  const [dropdownSelectedValue, setDropdownSelectedValue] =
    useState("Agents List");

  function HandleSelection(e) {
    console.log("eeeeeeeeeeeee", e.target.value);
    setDropdownSelectedValue(e.target.value);
  }

  return (
    <>
      <Header />
      <CssBaseline />

      {/* //snack bar  */}
      <Snackbar
        open={openSnackbar.flag}
        autoHideDuration={5000}
        onClose={() =>
          setOpenSnackbar((prevState) => ({ ...prevState, flag: false }))
        }
      >
        <Alert
          onClose={() =>
            setOpenSnackbar((prevState) => ({ ...prevState, flag: false }))
          }
          severity="success"
          sx={{ width: "100%" }}
        >
          {openSnackbar.message}
        </Alert>
      </Snackbar>

      {/* popUp */}
      {/* //Accordion */}

      {togglePopup && popUpDetail.content !== undefined && (
        <PopUp
          topImage={popUpDetail.topImage}
          messageTitle={popUpDetail.messageTitle}
          messageText={popUpDetail.messageText}
          trueButtonFunction={popUpDetail.trueButtonFunction}
          falseButtonFunction={popUpDetail.trueButtonFunction}
          setTogglePopup={setTogglePopup}
          content={popUpDetail.content}
          isTitleRed={popUpDetail.isTitleRed}
          addNote={popUpDetail.addNote}
        />
      )}

      {togglePopupReject && popUpDetail && (
        <PopUpForReject
          topImage={popUpDetail.topImage}
          messageTitle={popUpDetail.messageTitle}
          messageText={popUpDetail.messageText}
          setTogglePopupReject={setTogglePopupReject}
        />
      )}

      {showRejectionNotePopup && (<RejectionNotePopup setShowRejectionNotePopup = {setShowRejectionNotePopup} rejectionNotePopupDetail = {rejectionNotePopupDetail}/>)}

      {
        <>
          <Grid container>
            <Grid item xs={12}>
              <Grid container className="na-main-container">
                <Grid
                  item
                  className="root-grid-item"
                  xs={11}
                  sx={{ textAlign: "center" }}
                >
                  <Grid container>
                    <Grid item xs={11} sx={{ margin: "0 auto" }}>
                      <Grid container>
                        <Grid item xs={4}></Grid>

                        <Grid
                          item
                          xs={4}
                          classNames="na-main-item1"
                          textAlign="center"
                        >
                          <span className="na-agnet-main-heading">Agents</span>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} className="na-blue-white-main-item">
                      <Grid container className="na-table-blue-container">
                        {window.innerWidth <= 500 && (
                          <>
                            <Grid
                              item
                              width={"100% !important"}
                              sx={{ background: "white", marginRight: "5px" }}
                            >
                              {" "}
                              <Grid
                                container
                                justifyContent={"space-between"}
                                width={"100% !important"}
                                direction={"row"}
                                // className="na-table-white-container1"
                              >
                                <Accordion
                                  sx={{
                                    // width: "500px",
                                    // margin: "10px 0px 0px 0rem",
                                    borderRadius: "5px",
                                  }}
                                  className="accordion"
                                  expanded={isAccordionexpanded}
                                >
                                  <AccordionSummary
                                    sx={{ display: "none" }}
                                  ></AccordionSummary>
                                  <AccordionDetails>
                                    <Grid
                                      item
                                      // xl={4}
                                      lg={7}
                                      md={12}
                                      sm={12}
                                      xs={12}
                                      className="tp_box_status_container_parent_item"
                                    >
                                      <Grid
                                        container
                                        className="tp_box_status_container"
                                        justifyContent={"center"}
                                        width={"101% !important"}
                                        spacing={0.5}
                                      >
                                        <Grid
                                          item
                                          sx={{
                                            "@media screen and (max-width:600px)":
                                              {
                                                display: "none",
                                              },
                                          }}
                                          xl={2.3}
                                          lg={2.2}
                                          md={2.3}
                                          sm={2.3}
                                          xs={6}
                                        >
                                          <Grid container direction={"column"}>
                                            <Grid
                                              item
                                              className="tp_status_pending"
                                              sx={{
                                                backgroundColor: "white",
                                              }}
                                            >
                                              <span className="tp_box_status_label">
                                                {" "}
                                              </span>
                                            </Grid>
                                            <Grid
                                              item
                                              className="tp_status_pending_count"
                                              sx={{
                                                "@media screen and (max-width:600px)":
                                                  {
                                                    display: "none",
                                                  },
                                              }}
                                            >
                                              <span className="tp_box_status_label">
                                                {"# Agents"}
                                              </span>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                        <Grid
                                          item
                                          xl={2.3}
                                          lg={2.25}
                                          xs={5.6}
                                          md={2.3}
                                          sm={2.3}
                                        >
                                          <Grid container direction={"column"}>
                                            <Grid
                                              item
                                              className="tp_status_pending"
                                            >
                                              <span className="tp_box_status_label">
                                                {window.innerWidth > 500
                                                  ? "Active"
                                                  : "Active Agents"}
                                              </span>
                                            </Grid>
                                            <Grid
                                              item
                                              className="tp_status_pending_count"
                                            >
                                              <span className="tp_box_status_label">
                                                {
                                                  userData.total_active_agent_count
                                                }
                                              </span>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                        <Grid
                                          item
                                          xl={2.3}
                                          lg={2.3}
                                          xs={5.6}
                                          md={2.3}
                                          sm={2.3}
                                        >
                                          <Grid container direction={"column"}>
                                            <Grid
                                              item
                                              className="tp_status_active"
                                            >
                                              <span className="tp_box_status_label">
                                                {window.innerWidth > 500
                                                  ? "Inactive"
                                                  : "Inactive Agents"}
                                              </span>
                                            </Grid>
                                            <Grid
                                              item
                                              className="tp_status_active_count"
                                            >
                                              <span className="tp_box_status_label">
                                                {
                                                  userData.total_inactive_agent_count
                                                }
                                              </span>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                        <Grid
                                          item
                                          xl={2.3}
                                          lg={2.3}
                                          xs={5.6}
                                          md={2.3}
                                          sm={2.2}
                                        >
                                          <Grid container direction={"column"}>
                                            <Grid
                                              item
                                              className="tp_status_pending"
                                            >
                                              <span className="tp_box_status_label">
                                                {window.innerWidth > 500
                                                  ? "Request"
                                                  : "Request Agents"}
                                              </span>
                                            </Grid>
                                            <Grid
                                              item
                                              className="tp_status_pending_count"
                                            >
                                              <span className="tp_box_status_label">
                                                {
                                                  userData.total_pending_agent_count
                                                }
                                              </span>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                        <div class="vl"></div>
                                        <Grid
                                          item
                                          xl={2.3}
                                          lg={2.3}
                                          xs={5.6}
                                          md={2.3}
                                          sm={2.2}
                                        >
                                          <Grid container direction={"column"}>
                                            <Grid
                                              item
                                              className="tp_status_pending"
                                            >
                                              <span className="tp_box_status_label">
                                                Admin
                                              </span>
                                            </Grid>

                                            <Grid
                                              item
                                              className="tp_status_pending_count"
                                            >
                                              <span className="tp_box_status_label">
                                                {userData.total_admin_count}
                                              </span>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </AccordionDetails>
                                </Accordion>
                                <Grid item xs={6}>
                                  {" "}
                                  <Select
                                    sx={{
                                      width: "210px",
                                      marginLeft: "5px",
                                      border: "1px solid #c8bba1",
                                      color: "black",
                                      height: "40px",
                                      fontSize: "13px",
                                    }}
                                    width={"250px"}
                                    value={dropdownSelectedValue}
                                    onChange={HandleSelection}
                                    className="llllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllll"
                                  >
                                    <MenuItem
                                      value={"Agents List"}
                                      onClick={() => {
                                        ShowAllUserButtonClick();
                                      }}
                                      className="dropdown-menuitem"
                                    >
                                      {/* <Grid
                                      item
                                      xl={4}
                                      lg={5}
                                      md={5}
                                      sm={4.5}
                                      xs={12}
                                      className="na-agentlist-btn-item"
                                    >
                                      <Button
                                        variant="outlined"
                                        className="na-agentlist-btn-class"
                                        // fullWidth
                                        sx={{ width: "80%" }}
                                        id={
                                          selectShowAllButton == true
                                            ? "na-agent-reg-btn-effect"
                                            : "tp-agent-reg-btn-effect-default"
                                        }
                                        onClick={ShowAllUserButtonClick}
                                      >
                                        Agents List
                                      </Button>
                                    </Grid> */}
                                      Agent List
                                    </MenuItem>
                                    <MenuItem
                                      value={"Registration Requests"}
                                      className="dropdown-menuitem"
                                      onClick={() => {
                                        ShowPendingButtonClick();
                                      }}
                                    >
                                      Registration Requests
                                    </MenuItem>
                                  </Select>
                                </Grid>
                                <Grid item xs={3.3}>
                                  <Button
                                    sx={{
                                      width: "10px",
                                      background: "#c8bba1 !important",
                                      color: "black",
                                      height: "29px",
                                    }}
                                    onClick={() =>
                                      setIsAccordionexpanded(
                                        !isAccordionexpanded
                                      )
                                    }
                                  >
                                    {isAccordionexpanded ? (
                                      <img
                                        src={arrowUp}
                                        style={{ width: "18px" }}
                                      />
                                    ) : (
                                      <img
                                        src={arrowDown}
                                        style={{ width: "24px " }}
                                      />
                                    )}
                                  </Button>
                                </Grid>{" "}
                              </Grid>
                            </Grid>
                          </>
                        )}

                        {window.innerWidth > 500 && (
                          <Grid item xs={12}>
                            <Grid
                              container
                              justifyContent={"center"}
                              className="na-table-white-container1"
                            >
                              <Grid
                                item
                                xl={12}
                                lg={12}
                                md={11.9}
                                sm={11.9}
                                xs={11.9}
                              >
                                <Grid
                                  container
                                  className="tp-total-status-combine-container"
                                >
                                  <Grid item xs={12}>
                                    <Grid container>
                                      <Grid
                                        item
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        className="tp_box_main_item"
                                      >
                                        <Grid
                                          container
                                          className="tp_box_main_container"
                                        >
                                          <Grid
                                            item
                                            xl={12}
                                            lg={12}
                                            md={12}
                                            xs={12}
                                          >
                                            <Grid
                                              container
                                              justifyContent="space-between"
                                              className="tp_box_secondary_container"
                                            >
                                              {/* agent & admin */}
                                              <Grid
                                                item
                                                xl={5}
                                                lg={5}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                                className="user-list-toggle-button-container_parent_item"
                                              >
                                                <Grid
                                                  container
                                                  className="user-list-toggle-button-container"
                                                >
                                                  <Grid
                                                    item
                                                    xl={4}
                                                    lg={5}
                                                    md={5}
                                                    sm={4.5}
                                                    xs={12}
                                                    className="na-agentlist-btn-item"
                                                  >
                                                    <Button
                                                      variant="outlined"
                                                      className="na-agentlist-btn-class"
                                                      // fullWidth
                                                      sx={{ width: "80%" }}
                                                      id={
                                                        selectShowAllButton ==
                                                        true
                                                          ? "na-agent-reg-btn-effect"
                                                          : "tp-agent-reg-btn-effect-default"
                                                      }
                                                      onClick={
                                                        ShowAllUserButtonClick
                                                      }
                                                    >
                                                      Agents List
                                                    </Button>
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xl={4}
                                                    lg={5}
                                                    md={5.4}
                                                    sm={4.5}
                                                    xs={12}
                                                    className="na-registration-btn-item"
                                                  >
                                                    <Button
                                                      variant="outlined"
                                                      className="na-registration-btn-class"
                                                      // fullWidth
                                                      sx={{ width: "80%" }}
                                                      id={
                                                        selectShowPendingButton ==
                                                        true
                                                          ? "na-agent-reg-btn-effect"
                                                          : "tp-agent-reg-btn-effect-default"
                                                      }
                                                      onClick={
                                                        ShowPendingButtonClick
                                                      }
                                                    >
                                                      Registration Requests
                                                    </Button>
                                                    {/* <Button variant='contained' className="na-agent-pending-btn" fullWidth onClick={chnagebtnapproval}>{btnClickOpen == "Agents List" ? "Registration Requests" : "Agents List"}</Button> */}
                                                  </Grid>
                                                </Grid>
                                              </Grid>

                                              {/* Status */}
                                              {window.innerWidth > 500 && (
                                                <Grid
                                                  item
                                                  // xl={4}
                                                  lg={7}
                                                  md={12}
                                                  sm={12}
                                                  xs={12}
                                                  className="tp_box_status_container_parent_item"
                                                >
                                                  <Grid
                                                    container
                                                    className="tp_box_status_container"
                                                    alignItems={"end"}
                                                    spacing={0.5}
                                                  >
                                                    <Grid
                                                      item
                                                      sx={{
                                                        "@media screen and (max-width:600px)":
                                                          {
                                                            display: "none",
                                                          },
                                                      }}
                                                      xl={2.3}
                                                      lg={2.2}
                                                      md={2.3}
                                                      sm={2.3}
                                                      xs={5.6}
                                                    >
                                                      <Grid
                                                        container
                                                        direction={"column"}
                                                      >
                                                        <Grid
                                                          item
                                                          className="tp_status_pending"
                                                          sx={{
                                                            backgroundColor:
                                                              "white",
                                                          }}
                                                        >
                                                          <span className="tp_box_status_label">
                                                            {" "}
                                                          </span>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          className="tp_status_pending_count"
                                                          sx={{
                                                            "@media screen and (max-width:600px)":
                                                              {
                                                                display: "none",
                                                              },
                                                          }}
                                                        >
                                                          <span className="tp_box_status_label">
                                                            {"# Agents"}
                                                          </span>
                                                        </Grid>
                                                      </Grid>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      xl={2.3}
                                                      lg={2.25}
                                                      xs={5.6}
                                                      md={2.3}
                                                      sm={2.3}
                                                    >
                                                      <Grid
                                                        container
                                                        direction={"column"}
                                                      >
                                                        <Grid
                                                          item
                                                          className="tp_status_pending"
                                                        >
                                                          <span className="tp_box_status_label">
                                                            Active
                                                          </span>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          className="tp_status_pending_count"
                                                        >
                                                          <span className="tp_box_status_label">
                                                            {
                                                              userData.total_active_agent_count
                                                            }
                                                          </span>
                                                        </Grid>
                                                      </Grid>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      xl={2.3}
                                                      lg={2.3}
                                                      xs={5.6}
                                                      md={2.3}
                                                      sm={2.3}
                                                    >
                                                      <Grid
                                                        container
                                                        direction={"column"}
                                                      >
                                                        <Grid
                                                          item
                                                          className="tp_status_active"
                                                        >
                                                          <span className="tp_box_status_label">
                                                            Inactive
                                                          </span>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          className="tp_status_active_count"
                                                        >
                                                          <span className="tp_box_status_label">
                                                            {
                                                              userData.total_inactive_agent_count
                                                            }
                                                          </span>
                                                        </Grid>
                                                      </Grid>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      xl={2.3}
                                                      lg={2.3}
                                                      xs={5.6}
                                                      md={2.3}
                                                      sm={2.2}
                                                    >
                                                      <Grid
                                                        container
                                                        direction={"column"}
                                                      >
                                                        <Grid
                                                          item
                                                          className="tp_status_pending"
                                                        >
                                                          <span className="tp_box_status_label">
                                                            Request
                                                          </span>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          className="tp_status_pending_count"
                                                        >
                                                          <span className="tp_box_status_label">
                                                            {
                                                              userData.total_pending_agent_count
                                                            }
                                                          </span>
                                                        </Grid>
                                                      </Grid>
                                                    </Grid>
                                                    <div class="vl"></div>
                                                    <Grid
                                                      item
                                                      xl={2.3}
                                                      lg={2.3}
                                                      xs={5.6}
                                                      md={2.3}
                                                      sm={2.2}
                                                    >
                                                      <Grid
                                                        container
                                                        direction={"column"}
                                                      >
                                                        <Grid
                                                          item
                                                          className="tp_status_pending"
                                                        >
                                                          <span className="tp_box_status_label">
                                                            Admin
                                                          </span>
                                                        </Grid>

                                                        <Grid
                                                          item
                                                          className="tp_status_pending_count"
                                                        >
                                                          <span className="tp_box_status_label">
                                                            {
                                                              userData.total_admin_count
                                                            }
                                                          </span>
                                                        </Grid>
                                                      </Grid>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                              )}
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}

                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Grid
                            container
                            width={"unset !important"}
                            className="na-table-white-container2"
                          >
                            <Grid item xs={12} className="na-datagrid-item">
                              {buttonClick === "Agents List" && (
                                <DataGrid
                                  className="na-datagrid"
                                  // getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'wss-datagrid-even-row' : 'wss-datagrid-odd-row'}
                                  rows={rowData}
                                  columns={columns}
                                  getRowId={(row) => row.user_id}
                                  id={Math.random()}
                                  pageSize={10}
                                  rowsPerPageOptions={[10]}
                                  density="compact"
                                  onPaginationModelChange={setPaginationModel}
                                  paginationModel={paginationModel}
                                  getRowClassName={"rowClassName"}
                                  autoHeight
                                  disableRowSelectionOnClick
                                  initialState={{
                                    sorting: {
                                      sortModel: [
                                        {
                                          field: "user_full_name",
                                          sort: "asc",
                                        },
                                      ],
                                    },
                                  }}
                                  // disableColumnSelector
                                  initialState={{
                                    sorting: {
                                      sortModel: [
                                        {
                                          field: "user_full_name",
                                          sort: "asc",
                                        },
                                      ],
                                    },
                                  }}
                                  // sortModel={[{ field: "name", sort: "asc" }]}
                                  components={{
                                    Toolbar: GridToolbar,
                                    NoRowsOverlay,
                                  }}
                                  hideScrollbar={"true"}
                                  cellClassName={(params) =>
                                    params.field ===
                                    columns[columns.length - 1].field
                                      ? "lastColumn_style" // Apply the custom class to the last column cells
                                      : "lastColumn_style"
                                  }
                                  headerStyle={{ background: "red !important" }}
                                  sx={{
                                    backgroundColor: "white",
                                    "@global": {
                                      ".MuiDataGrid-cell:focus": {
                                        outline: 0,
                                      },
                                    },
                                    "& .MuiDataGrid-cell:hover": {
                                      color: "primary.main",
                                    },

                                    "& .MuiDataGrid-row:hover": {
                                      backgroundColor: "none",
                                    },
                                    "& .MuiDataGrid-root": {
                                      overflow: "unset !important",
                                    },
                                    ".MuiDataGrid-iconSeparator": {
                                      display: "none",
                                      visibility: "none",
                                    },
                                    "& .MuiDataGrid-rowSelected": {
                                      border: "none", // Remove the default border for selected rows
                                    },
                                    "& .MuiDataGrid-columnHeader--selected": {
                                      borderBottom: "none", // Remove the bottom border for the selected header cell
                                    },
                                    "& .MuiDataGrid-cell": {
                                      // backgroundColor: "#F3F5F9",
                                      innerHeight: "37px",
                                      outline: "unset !important",
                                      // minWidth: "300px !important",
                                    },

                                    "& .MuiDataGrid-cell:focus": {
                                      outline: "none",
                                    },
                                    "& .MuiDataGrid-columnHeaderTitle": {
                                      pointerEvents: "none",
                                    },
                                    "& .MuiDataGrid-cell": {
                                      border: "none",
                                    },
                                  }}
                                  // initialState={LinkId ? defaultSearch : ""}
                                />
                              )}
                              {buttonClick === "Pending" && (
                                <>
                                  <Grid
                                    item
                                    className="tabs"
                                    sx={{
                                      "& .MuiTabs-flexContainer": {
                                        height: "45px !important",
                                      },
                                      "& .MuiTab-root": {
                                        paddingTop: "2px !important",
                                      },
                                      "& .Mui-selected": {
                                        color: "black !important",
                                        // fontWeight: "bold",
                                        border: "1px solid #c8bba1",
                                        backgroundColor: "#f4f1ec",
                                        borderRadius:"8px"
                                      },
                                      "& .MuiTabs-indicator": {
                                        background: "none",
                                      },
                                      "& .MuiTabs-scroller":{
                                        borderBottom:"1px solid #c8bba1",
                                      },
                                      "@media screen and (max-width:500px)": {
                                        "& .MuiTab-root": {
                                          paddingTop: "2px !important",
                                          width: "110px",
                                        },
                                        "& .MuiTabs-flexContainer":{
                                          justifyContent: "space-between",
                                        }
                                      },

                                    }}
                                  >
                                    <Tabs
                                      value={selectedTab}
                                      onChange={handleSelectTab}
                                    >
                                      <Tab
                                        label="Registration Requests"
                                        value={"Requests"}
                                        sx={{ textTransform: "none" }}
                                      />
                                      <Tab
                                        label="Rejected Requests"
                                        value={"Rejected"}
                                        sx={{ textTransform: "none" }}
                                      />
                                    </Tabs>
                                  </Grid>{" "}
                                  {selectedTab == "Requests" && (
                                    <DataGrid
                                      className="na-datagrid"
                                      rows={rowData}
                                      columns={RegistrationRequestColumns}
                                      getRowId={(row) => row.user_id}
                                      // id={Math.random()}
                                      pageSize={10}
                                      paginationModel={paginationModel}
                                      getRowClassName={"rowClassName"}
                                      onPaginationModelChange={
                                        setPaginationModel
                                      }
                                      rowsPerPageOptions={[10]}
                                      components={{
                                        Toolbar: GridToolbar,
                                        NoRowsOverlay,
                                      }}
                                      density="compact"
                                      disableRowSelectionOnClick
                                      // disableColumnSelector
                                      autoHeight
                                      sx={{
                                        backgroundColor: "white",
                                        "& .MuiDataGrid-cell:hover": {
                                          color: "primary.main",
                                        },
                                        "& .MuiDataGrid-row:hover": {
                                          backgroundColor: "none",
                                        },
                                        "& .MuiDataGrid-root": {
                                          overflow: "unset !important",
                                        },
                                        ".MuiDataGrid-iconSeparator": {
                                          display: "none",
                                          visibility: "none",
                                        },
                                        "& .MuiDataGrid-rowSelected": {
                                          border: "none", // Remove the default border for selected rows
                                        },
                                        "& .MuiDataGrid-columnHeader--selected":
                                          {
                                            borderBottom: "none",
                                            outline: "unset !important",
                                          },
                                        "& .MuiDataGrid-cell": {
                                          // backgroundColor: "#F3F5F9",
                                          innerHeight: "37px",
                                          outline: "unset !important",
                                          // minWidth: "300px !important",
                                        },
                                      }}
                                    />
                                  )}
                                  {selectedTab == "Rejected" && (
                                    <DataGrid
                                      className="na-datagrid"
                                      rows={rowData}
                                      columns={RejectedAgentsColumns}
                                      getRowId={(row) => row.user_id}
                                      // id={Math.random()}
                                      pageSize={10}
                                      paginationModel={paginationModel}
                                      getRowClassName={"rowClassName"}
                                      onPaginationModelChange={
                                        setPaginationModel
                                      }
                                      rowsPerPageOptions={[10]}
                                      components={{
                                        Toolbar: GridToolbar,
                                        NoRowsOverlay,
                                      }}
                                      density="compact"
                                      disableRowSelectionOnClick
                                      // disableColumnSelector
                                      autoHeight
                                      sx={{
                                        backgroundColor: "white",
                                        "& .MuiDataGrid-cell:hover": {
                                          color: "primary.main",
                                        },
                                        "& .MuiDataGrid-row:hover": {
                                          backgroundColor: "none",
                                        },
                                        "& .MuiDataGrid-root": {
                                          overflow: "unset !important",
                                        },
                                        ".MuiDataGrid-iconSeparator": {
                                          display: "none",
                                          visibility: "none",
                                        },
                                        "& .MuiDataGrid-rowSelected": {
                                          border: "none", // Remove the default border for selected rows
                                        },
                                        "& .MuiDataGrid-columnHeader--selected":
                                          {
                                            borderBottom: "none",
                                            outline: "unset !important",
                                          },
                                        "& .MuiDataGrid-cell": {
                                          // backgroundColor: "#F3F5F9",
                                          innerHeight: "37px",
                                          outline: "unset !important",
                                          // minWidth: "300px !important",
                                        },
                                      }}
                                    />
                                  )}
                                </>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      }
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open1}
        onClose={handleOptionClose}
        TransitionComponent={Fade}
        className="agent-edit-dlt-menu"
      >
        {/* {isEmailVerificationPending == true ? (
          <>
            {" "}
            <MenuItem
              className="optionsClass"
              onClick={() => {
                debugger;
                setEditFlag(true);
                setFlagDeleteUserPopup(true);
                setAnchorEl(null);

                setTogglePopup(true);
              }}
              sx={{ color: "black !important" }}
            >
              <ModeEditOutlineOutlinedIcon
                id="agent-edit-btn"
                fontSize="small"
              />
              &nbsp;<span id="menu-edit-agent">Delete</span>
            </MenuItem>
          </>
        ) : (
          // <> */}
        {true && (
          <>
            {" "}
            <MenuItem
              className="optionsClass"
              onClick={() => {
                setTogglePopup(true);
                setPopUpDetails({
                  topImage: dustbin,
                  messageTitle: "Delete",
                  messageText:
                    "Are you sure you want to delete " +
                    selectedAgent.user_full_name +
                    "?",
                  trueButtonFunction: test(),
                  content: deleteButtonHTML(),
                  isTitleRed: true,
                });
              }}
            >
              <img src={dustbin} alt="" style={{ width: "15px" }} />
              {/* <DeleteForeverOutlinedIcon
                    fontSize="small"
                    id="agent-delete-btn"
                  /> */}
              &nbsp;<span id="menu-delete-agent">Delete</span>
            </MenuItem>
          </>
        )}
        {/*    {isUserPending && (
              <>
                {" "}
                <MenuItem
                  className="optionsClass"
                  onClick={() => {
                    setEditFlag(true);
                    setFlagApproveUserPopup(true);
                    setOpen({
                      status: true,
                      id: 0,
                    });
                    setAnchorEl(null);
                  }}
                >
                  <ModeEditOutlineOutlinedIcon
                    id="agent-edit-btn"
                    fontSize="small"
                  />
                  &nbsp;<span id="menu-edit-agent">Active</span>
                </MenuItem>
                <MenuItem
                  className="optionsClass"
                  onClick={() => {
                    setAnchorEl(null);
                    setFlagRejectUserPopup(true);
                  }}
                >
                  <DeleteForeverOutlinedIcon
                    fontSize="small"
                    id="agent-delete-btn"
                  />
                  &nbsp;<span id="menu-delete-agent">Inactive</span>
                </MenuItem>
              </>
            )} */}
      </Menu>
    </>
  );
};

export default UserListNew;
