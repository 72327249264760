import Axios from "./axios";

export const UpdatePasswordAPI  = async(data,onSuccess ,onError)=>{
    try{
        console.log("ID Data UpdatePasswordAPI in service file ",data);
          //debugger;
        const response =await Axios.post('/api/user/update-password',  JSON.stringify(data), {
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },
            withCredentials: true
        });
        console.log("printing responce of UpdatePasswordAPI in service file", response)
        // debugger;
        onSuccess && onSuccess(response);
       //  debugger;
    }
    catch(err){
        console.log("got an error",err);
        onError && onError(err)
    }
}


