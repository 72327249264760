//updated



import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";
import "./login.css";
import {
  Box,
  TextField,
  CssBaseline,
  Container,
  Button,
  Alert,
  CircularProgress,
  Backdrop,
  Typography,
  Input,
  Grid,
  Link,
  AlertTitle,
} from "@mui/material";
import Header from "../components/header/header";
import MyAdvisorCloudLogo from "../../src/assets/images/MyAdvisorCloudLogo.png";
import PremiumAllianceLogo from "../../src/assets/images/PremiumAllianceLogo.png";
import Dashboard from "../pages/dashboard/dashboard";
import { Navigate, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { LoginAPI } from "../service/login.service";
import { Context as AuthContext } from "../context/auth_context";
import "../assets/images/bg_img2.png";
import $ from "jquery";
import { ClassNames } from "@emotion/react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { height, style, width } from "@mui/system";
import Cookies from "js-cookie";
import emailIcon from "../assets/images/InputFieldIcons/emailIcon.png";
import LockRounded from "../assets/images/InputFieldIcons/lockRounded.png";
import Loader from "../pages/dashboard/loader";
import premiumAllianceMac from "../assets/images/premium-alliance-mac.mp4";
// import Vlitejs from 'vlitejs';
import LockIcon from "@mui/icons-material/Lock";
import videoLogo from "../assets/images/videoPlayBottonimage.png";
import { CheckBox } from "@mui/icons-material";
import ImageBG from "../assets/images/bg_img2.png";
import { Player } from "video-react";
import LoginHeader from "../components/header/LoginHeader/LoginHeader";
import viewPassword from "../assets/images/InputFieldIcons/viewPassword.png";
import hidePassword from "../assets/images/InputFieldIcons/hidePassWord.png";
import ErrorIcon from "@mui/icons-material/Error";
import ReCAPTCHA from "react-google-recaptcha";
const Login = () => {
  const navigate = useNavigate();

  const {
    ChangeFlagEmailSent,
    resetpasswordcall,
    loginCall,
    state,
    ChangeAlertClassName,
    SetErrorMessageToNull,
    verifyLink
  } = useContext(AuthContext);
  const {
    message,
    error_message,
    successful_message,
    alertclassName,
    flag_loader,
    flag_email_sent,
    isRedirectedFromVerifyEmail,
  } = state;
  const [flagDisableBtn, setFlagDisableBtn] = useState(1);
  const [userDetail, setUserDetail] = useState({
    email: "",
    password: "",
  });
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [displayVideoBtn, setDisplayVideoBtn] = useState(true);
  const [showEmptyFieldError, setShowEmptyFieldError] = useState(false);
  const [recaptchaValue,setRecaptchaValue] = useState("")
  console.log("Error message from the Login page ", error_message);
  console.log("Error message from the Login page 1-----------", alertclassName);
  console.log("flag_loader", flag_loader);


  // const LoginUser = () => {
  //     console.log("login ckicked")
  //     const { email, password } = userDetail;
  //     if (email === "" || password === "") {
  //         alert("Please fill all the fields.");
  //     }
  // }

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  console.log("state classname", alertclassName);
  const location = useLocation();
  useEffect(() => {
    console.log("Printing login for this page", location.pathname);
  }, []);

  useEffect(() => {
    // debugger
    const { email, password } = userDetail;
    console.log("email of use effect:", email);
    if (email === "" || password === "" || recaptchaValue =="") {
      setFlagDisableBtn(0);
    } else {
      setFlagDisableBtn(1);
    }
  }, [userDetail,recaptchaValue]);

  useEffect(() => {}, [flag_loader]);

  const videoRef = useRef();

  useEffect(() => {
    console.log("Printng video ref in useEffect", videoRef?.current?.pause());
    //
  }, [videoRef]);

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const videoEl = useRef(null);


  // veriy link call
  // const [ searchParams ] = useSearchParams();
  // const token = searchParams?.get('token');
  // useEffect(()=>{
  //   // debugger;
  //   console.log("Printing token in login page", token);
  //   if(token){
  //     verifyLink({token});
  //   }
  // }, [])


  // if(isVideoPlaying == true){
  //     setDisplayVideoBtn(false)
  // }

  //   function playvideo() {
  //     if(videoRef?.current !== undefined) {
  //          videoRef?.current?.play();
  //          setIsVideoPlaying(true);
  //          setDisplayVideoBtn(!displayVideoBtn);
  //         //  circlePlayButtonRef?.current?.style.display.none;
  //     }
  //   }

  const PlayPauseVideo = () => {
    if (videoRef.current !== undefined) {
      if (isVideoPlaying === true) {
        setIsVideoPlaying(false);
        setDisplayVideoBtn(true);
      } else {
        setIsVideoPlaying(true);
        setDisplayVideoBtn(false);
      }
    }
  };

  function LoginButtonPress() {
    if (!flagDisableBtn) {
      setShowEmptyFieldError("Please enter all details");
      SetErrorMessageToNull();
    } else {
      if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userDetail.email)
      ) {
        loginCall({
          email: userDetail.email,
          password: userDetail.password,
        });
        setShowEmptyFieldError(false);
      } else {
        setShowEmptyFieldError("Please pass the valid Email address!");
        SetErrorMessageToNull();
      }
    }
  }

  //const [errMsg ,setErrMsg] =useState("");

  return (
    <Fragment>
      <CssBaseline />

      {flag_loader === true ? <Loader /> : null}
      <LoginHeader isLogin="true" />

      <Box
        className="main_box"
        sx={{
          width: "100%",
          background: "rgba(0, 0, 0, 0.5);",
          backgroundImage: `url(${ImageBG})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundBlendMode: "multiply",
        }}
      >
        <Box className=" main_part">
          <Grid container direction={"column"} className="login_form_container">
            {/* {(message == "")? <span style={{display:"none",opacity:"0",visibility:"0"}}>
                            </span> :<span style={{display:"block",opacity:"0",visibility:"0"}}></span> } */}

            {/* <div style={{border:"2px solid green" ,backgroundColor:'green'}}>
                                <span className={alertclassName}>{message}</span><span></span>
                           </div> */}
            {/* <span className='errMsg '  >{error_message}</span><span></span>
                                <span className='successful_msg  ' >{successful_message}</span><span></span> */}
            {isRedirectedFromVerifyEmail==true && (
              <Grid
                item
                marginBottom={"30px"}
                // className="inputField"
                style={{
                  // fontWeight: "600",
                  // color: "white",

                  fontSize: "15px",
                  width: "100%",
                  marginBottom: "30px",
                }}
              >
                <Alert severity="success">
                  <AlertTitle>Congratulations!</AlertTitle>
                  Your email address has been successfully verified.<br/>Please
                  login to continue.
                </Alert>
              </Grid>
            )}
            {!isRedirectedFromVerifyEmail && (
              <Grid
                item
                style={{
                  fontWeight: "600",
                  color: "white",
                  fontSize: "15px",
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                Welcome Back
              </Grid>
            )}
            {!isRedirectedFromVerifyEmail && (
              <Grid
                item
                style={{
                  fontWeight: "600",
                  color: "white",
                  marginBottom: "30px",
                  fontSize: "25px",
                  width: "100%",
                }}
              >
                Login to your account
              </Grid>
            )}

            {showEmptyFieldError && (
              <>
                <div
                  className="alert alert-danger"
                  role="alert"
                  style={{ padding: "11px 5px 8px 15px" }}
                >
                  <span style={{ marginRight: "10px" }}>
                    <ErrorIcon />
                  </span>
                  <span style={{}}>
                    {showEmptyFieldError
                      ? showEmptyFieldError
                      : "Please enter all details"}
                  </span>
                </div>
              </>
            )}
            {alertclassName == "errMsg" ? (
              <>
                <div
                  className="alert alert-danger"
                  role="alert"
                  style={{ padding: "11px 5px 8px 15px" }}
                >
                  <span style={{ marginRight: "10px" }}>
                    <ErrorIcon />
                  </span>
                  <span style={{}}>{state.message}</span>
                </div>
              </>
            ) : (
              ""
            )}

            <Grid item style={{ marginBottom: "20px", width: "100%" }}>
              <TextField
                type="email"
                required
                placeholder="Email"
                className="inputField"
                InputProps={{
                  startAdornment: (
                    <div style={{ padding: "10px" }}>
                      <img src={emailIcon} />
                    </div>
                  ),
                  disableUnderline: true,
                  style: { paddingLeft: 10 },
                }}
                value={userDetail?.email}
                onChange={(e) => {
                  console.log(e.target.value);
                  setUserDetail({
                    ...userDetail,
                    email: e.target.value,
                  });
                }}
                autoComplete="off"
              />
            </Grid>
            <Grid item className="password" width={"100%"}> 
              <TextField
                type={showPassword ? "text" : "password"}
                id="password"
                className="inputField"
                required
                value={userDetail?.password}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <div style={{ padding: "12px" }}>
                      <img src={LockRounded} />
                    </div>
                  ),
                  endAdornment: (
                    <div style={{ paddingLeft: "10px", cursor: "pointer" }}>
                      <img
                        src={showPassword ? hidePassword : viewPassword}
                        style={{ width: "20px" }}
                        alt={showPassword ? "Hide Password" : "Show Password"}
                        onClick={togglePasswordVisibility}
                      />
                    </div>
                  ),
                  disableUnderline: true,
                  style: { paddingLeft: 10 },
                }}
                placeholder="Password"
                backgroundImage="emailIcon"
                onChange={(e) => {
                  console.log(e.target.value);
                  setUserDetail({
                    ...userDetail,
                    password: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item width={"100%"}>
              <Grid
                container
                justifyContent={"space-between"}
                color={"white"}
                fontWeight={"600"}
                alignItems={"center"}
              >
                <Grid item marginTop={"10px"}>
                  {/* <Grid container>
                    <Grid item>
                      <CheckBox
                        sx={{
                          fontSize: "18px",
                          padding: "1px 5px 0px 0px",
                        }}
                      />
                    </Grid>
                    <Grid item>
                      {" "}
                      <span className="rememberMeText">Remember me</span>
                    </Grid>
                  </Grid> */}
                </Grid>
                <Grid
                  item
                  sx={{
                    cursor: "pointer",
                    fontWeight: "600 !important",
                    marginTop: "10px",
                  }}
                  onClick={() => {
                    ChangeFlagEmailSent({ flag_email_sent: false });
                    ChangeAlertClassName("");
                    navigate("/confirm-mail");
                  }}
                >
                  {" "}
                  <span className="forgotPassText">
                    <u>Forgot Password?</u>
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item width={"100%"} marginTop={"20px"} >
            <div className="recaptcha_perent">
              <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} onChange={(val) => {setRecaptchaValue(val)}} />


              </div>
            </Grid>
            

            <Grid item width={"100%"} marginBottom={"10px"}>
              {" "}
              <Button
                className="login_button"
                // disabled={flagDisableBtn}
                sx={{ textTransform: "unset" }}
                onClick={(data) => {
                  LoginButtonPress();
                }}
                // disabled={flagDisableBtn === 0 ? true : false}
              >
                Login
              </Button>
            </Grid>
            <Grid item width={"100%"}>
              <Typography className="bottomText" margin={"0px 35px 0px auto"}>
                Don't have an account?{" "}
                <span
                  onClick={() => {
                    navigate("/register");
                  }}
                  style={{ fontWeight: "600", cursor: "pointer" }}
                >
                  <u>Register here</u>
                </span>
              </Typography>
            </Grid>

            {/* <Link  > */}

            {/* </Link> */}
            {/* <Button  href='/dashboard'  type='submit'>Log in</Button> */}
            {/* //!Forgot pass and register button */}
            {/* <div className="forget">
                  <Button
                    className="button2"
                    onClick={() => {
                      ChangeFlagEmailSent({ flag_email_sent: false });
                      ChangeAlertClassName("");
                      navigate("/confirm-mail");
                    }}
                  >
                    Forgot Password
                  </Button>

                  <Button
                    className="button3"
                    onClick={() => {
                      navigate("/register");
                    }}
                  >
                    Register
                  </Button>
                </div> */}
          </Grid>
        </Box>
        <Box className="video_box">
          {/* <Player
            id="video"
            ref={videoRef}
            poster={videoLogo}
            onClick={() => {
              PlayPauseVideo();
            }}
            style={{
              height: "960px !important",
              width: "640px",
              marginTop: "70px",
              borderRadius: "20px",
            }}
            
          >
            <source src={premiumAllianceMac} type="video/mp4"></source>
          </Player> */}
          <video
            playsInline
            id="video"
            ref={videoRef}
            poster={videoLogo}
            // poster= {`url(${videoLogo})`}
            onClick={() => {
              PlayPauseVideo();
            }}
            style={{
              height: "960px !important",
              width: "640px",
              marginTop: "70px",
              borderRadius: "20px",
              background: "red !important",
            }}
            controls
          >
            <source src={premiumAllianceMac} type="video/mp4"></source>
          </video>
        </Box>
      </Box>
    </Fragment>
  );
};
export default Login;
