import createDataContext from "./create_data_context";
import Cookies from "js-cookie";
import { LoginAPI, UserMeAPI, LogoutUserAPI } from "../service/login.service";

import { RegisterAPI } from "../service/register.service";
import { ResetPasswordAPI } from "../service/resetpassword.service";
import { UpdatePasswordAPI } from "../service/updatepassword.service";
import { useEffect, useState } from "react";
import { verifyEmailAPI, verifyLinkAPI } from "../service/verifyemail.servise";
import { Navigate, useNavigate } from "react-router-dom";
import $ from "jquery";
import {
  GetAllUsersAPI,
  UpdateUserStatusAPI,
} from "../service/pendingusers.service";
import { Context as AuthContext } from "../context/auth_context";
import { type } from "@testing-library/user-event/dist/type";

const auth_context_Reducer = (state, action) => {
  console.log("action.payload", action);
  switch (action.type) {
    case "setUserDetail":
      return { ...state, user_detail: action.payload };
    case "setErrorMessage":
      //debugger
      return {
        ...state,
        message: action.payload.message,
        alertclassName: action.payload.alertclassName,
      };
    default:
      return state;
    case "setUserMeRunningStatus":
      return {
        ...state,
        user_detail: {
          ...state.user_detail,
          flag_running_userme: action.payload.flag_running_userme,
        },
      };
    case "VerifyEmail": {
      console.log("Action paload", action.payload);
      //  debugger;
      return {
        ...state,
        user_detail: {
          ...state.user_detail,
          flag_email_verified: action.payload,
        },
      };
    }
    case "verifyingEmailAddress": {
      // debugger;
      return { ...state, verifyingEmailAddress: action.payload };
    }
    case "setMeassage": {
      // debugger;
      return {
        ...state,
        message: action.payload.message,
        alertclassName: action.payload.alertclassName,
      };
    }
    case "set_error_message_Msg": {
      // debugger;
      return {
        ...state,
        message: action.payload.message,
        alertclassName: action.payload.alertclassName,
      };
    }
    case "setdata": {
      debugger;
      return {
        ...state,
        message: action.payload.message,
        alertclassName: action.payload.alertclassName,
      };
    }
    case "loader_update": {
      // debugger;
      return { ...state, flag_loader: action.payload.flag_loader };
    }
    case "flag_email_sent": {
      // debugger;
      return { ...state, flag_email_sent: action.payload.flag_email_sent };
    }
    case "chageAlertClassName": {
      // debugger;
      return {
        ...state,
        alertclassName: action.payload,
      };
    }
    case "allUserData": {
      console.log(action.payload);
      return {
        ...state,
        allUserData: action.payload,
      };
    }
    case "setVerifyingEmailAddressMessage":{
      return{
        ...state,
        verifyingEmailAddressMessage : action.payload
      }
    }
    case "setIsRedirectedFromVerifyEmail":{
      return{
        ...state,
        isRedirectedFromVerifyEmail : action.payload
      }
    }
    case "setRejectionNote" :{
      return{
        ...state,
        rejectionNote:action.payload
      }
    }
  }
};



const setflagloader = (dispatch) => (data)=> {
  dispatch({
    type: "loader_update",
    payload: {
      flag_loader: data,
    },
  });
  return
}
const loginCall =
  (dispatch) =>
  async ({ email, password }) => {
    dispatch({
      type: "setErrorMessage",
      payload: {
        message: "",
      },
    });
    dispatch({
      type: "loader_update",
      payload: {
        flag_loader: true,
      },
    });
    LoginAPI(
      {
        email,
        password,
      },

      (res) => {
    // debugger

        console.log("Login call successfull response", res.data);
        if (res.data.status == true) {
          Cookies.set("visibleGoogleChat", true, {
            path: "/",
          });
          Cookies.set("email", res.data.data.user_email, {
            path: "/",
          });
          dispatch({
            type: "setUserDetail",
            payload: {
              access_token: res.data.data.accessToken,
              user_email: res.data.data.user_email,
              user_firstname: res.data.data.first_name,
              user_lastname: res.data.data.last_name,
              flag_authenticated: true,
              flag_email_verified: res.data.data.verify,
              flag_admin_approval:res.data.data.adminApproval,
              role: res.data.data.roles[0].user_type,
            },
          });
        
          dispatch({
            type: "loader_update",
            payload: {
              flag_loader: false,
            },
          });

         
        } else {
          dispatch({
            type: "setErrorMessage",
            payload: {
              message: res.data.error.message,
              alertclassName: "errMsg",
            },
          });
          dispatch({
            type: "setUserDetail",
            payload: {
              access_token: "",
              user_email: "",
              user_firstname: "",
              user_lastname: "",
              flag_authenticated: false,
              flag_email_verified: false,
              flag_admin_approval:false,
              role: "",
            },
          });
          dispatch({
            type: "loader_update",
            payload: {
              flag_loader: false,
            },
          });
        }
      }
    );
  };
const logoutCall = (dispatch) => async () => {
  dispatch({
    type: "setUserDetail",
    payload: {
      access_token: "",
      user_email: "",
      user_firstname: "",
      user_lastname: "",
      flag_authenticated: false,
      flag_email_verified: false,
      flag_admin_approval:false,
      role: "",
    },
  });
  dispatch({
    type: "loader_update",
    payload: {
      flag_loader: true,
    },
  });
  

  LogoutUserAPI(
    {
      email: "",
      password: "",
    },
    
    (res) => {
      console.log("Printing response of logout user api call", res);
      Cookies.set("email", "", {
        path: "/",
      });

      if (res.data.status === true) {
        window.location.replace("/login");
        dispatch({
          type: "loader_update",
          payload: {
            flag_loader: false,
          },
        });
      } else {
        dispatch({
          type: "loader_update",
          payload: {
            flag_loader: false,
          },
        });
        // alert("Something went wrong, Please try again later.!");
      }
    },
    (err) => {
      dispatch({
        type: "loader_update",
        payload: {
          flag_loader: false,
        },
      });
      // debugger
      //  alert("Something went wrong, Please try again later.!",err);
    }
  );
};
const checkUserLoggedIn = (dispatch) => async () => {
  UserMeAPI(
    (res) => {
      console.log("UserMe API Response in Auth Context page ", res.data);
      dispatch({
        type: "setUserMeRunningStatus",
        payload: {
          flag_running_userme: true,
        },
      });
      if (res.data.status == true) {
        dispatch({
          type: "setUserDetail",
          payload: {
            access_token: res.data.data.accessToken,
            user_email: res.data.data.user_email,
            user_firstname: res.data.data.first_name,
            user_lastname: res.data.data.last_name,
            flag_authenticated: true,
            flag_email_verified: res.data.data.verify,
            flag_admin_approval: res.data.data.adminApproval,
            flag_running_userme: false,
            role: res.data.data.roles[0].user_type,
          },
        });
      } else {
        dispatch({
          type: "setErrorMessage",
          payload: {
            message: "Incorrect Email address or password",
          },
        });
        dispatch({
          type: "setUserDetail",
          payload: {
            access_token: "",
            user_email: "",
            user_firstname: "",
            user_lastname: "",
            flag_authenticated: false,
            role: "",
          },
        });
      }
    },
    (err) => {
      console.log("Error response on User Me call in Auth Context", err);
      dispatch({
        type: "setUserDetail",
        payload: {
          access_token: "",
          user_email: "",
          user_firstname: "",
          user_lastname: "",
          flag_authenticated: false,
          role: "",
        },
      });
    }
  );
};


const registercall =
  (dispatch) =>
  async ({ firstname, lastname, email, password, phone }) => {
    dispatch({
      type: "setErrorMessage",
      payload: {
        message: "",
      },
    });
    dispatch({
      type: "loader_update",
      payload: {
        flag_loader: true,
      },
    });

    RegisterAPI(
      {
        firstname,
        lastname,
        email,
        password,
        phone,
      },
      (res) => {
        console.log("res.data", res.data);
        if (res.data.status == true) {
          dispatch({
            type: "loader_update",
            payload: {
              flag_loader: false,
            },
          });
          dispatch({
            type: "setUserDetail",
            payload: {
              user_firstname: res.data.firstname,
              user_lastname: res.data.lastname,
              user_email: res.data.email,
              user_password: res.data.password,
              user_phone: res.data.phone,
              flag_authenticated: true,
              flag_email_verified: false,
              role: res.data.data.roles[0].user_type,
            },
          });
        } else {
          dispatch({
            type: "setErrorMessage",
            payload: {
              message: "Incorrect Email address or password from register page",
            },
          });
          dispatch({
            type: "setUserDetail",
            payload: {
              user_firstname: "",
              user_lastname: "",
              user_email: "",
              user_password: "",
              user_phone: "",
              flag_authenticated: false,
              flag_email_verified: false,
              role: "",
            },
          });
          dispatch({
            type: "loader_update",
            payload: {
              flag_loader: false,
            },
          });
        }
      }
    );
  };

const verifyemail =
  (dispatch) =>
  async ({ emailAddress }) => {
    console.log("Verify Email in Auth Context called", emailAddress);
    verifyEmailAPI(
      {
        emailAddress,
      },
      (res) => {
        console.log("res.data of email verification", res.data);
        if (res.data.status == true) {
          Navigate("/home");
        } else {
          dispatch({
            type: "setErrorMessage",
            payload: {
              message:
                "Incorrect Email address or password from email verification  page",
            },
          });
          dispatch({
            type: "setUserDetail",
            payload: {
              email: "",
              flag_authenticated: false,
            },
          });
        }
      }
    );
  };

const verifyLink = (dispatch) =>
  async ({ token }) => {
    dispatch({
      type: "loader_update",
      payload: {
        flag_loader: true,
      },
    });
    console.log("Verify Link in Auth Context called", token);
    dispatch({
      type: "verifyingEmailAddress",
      payload: 1,
    });
    await verifyLinkAPI(
      {
        token,
      },
      (res) => {
        console.log("res.data of Verify Link Auth Context", res);
        if (res?.data?.status === true) {
          dispatch({
            type: "verifyingEmailAddress",
            payload: 2,
          });
          dispatch({
            type: "setVerifyingEmailAddressMessage",
            payload : "Congratulations, your email address has been successfully verified! Please login to continue."
          })
          dispatch({
            type: "setIsRedirectedFromVerifyEmail",
            payload : true,
          })
          dispatch({
            type: "loader_update",
            payload: {
              flag_loader: false,
            },
          });
         
          
          setTimeout(() => {
            dispatch({
              type: "VerifyEmail",
              payload: true,
            });
          }, 5000);
          

        } else {
          //  debugger;
          dispatch({
            type: "verifyingEmailAddress",
            payload: 3,
          });
          // dispatch({
          //   type: "setErrorMessage",
          //   payload: {
          //     message:
          //       "Incorrect Email address or password from email verification  page",
          //   },
          // });
          dispatch({
            type: "setVerifyingEmailAddressMessage",
            payload : "Some error occurred"
          })
          dispatch({
            type: "setIsRedirectedFromVerifyEmail",
            payload : true,
          })
          dispatch({
            type: "setUserDetail",
            payload: {
              email: "",
              flag_authenticated: false,
            },
          });
          dispatch({
            type: "VerifyEmail",
            payload: false,
          });
          dispatch({
            type: "loader_update",
            payload: {
              flag_loader: false,
            },
          });
          console.log("status is false when done api call for verify link");
        }
      },(err) =>{
        dispatch({
          type: "setVerifyingEmailAddressMessage",
          payload : "Something Went wrong!"
        })
        dispatch({
          type: "setIsRedirectedFromVerifyEmail",
          payload : true,
        })
        dispatch({
          type: "setUserDetail",
          payload: {
            email: "",
            flag_authenticated: false,
          },
        });
        dispatch({
          type: "VerifyEmail",
          payload: false,
        });
        dispatch({
          type: "loader_update",
          payload: {
            flag_loader: false,
          },
        });
      }
    );
  };

const ChangeFlagEmailSent =(dispatch) =>
  ({ flag_email_sent }) => {
    dispatch({
      type: "flag_email_sent",
      payload: {
        flag_email_sent,
      },
    });
  };
const resetpasswordcall = (dispatch) =>
  async ({ email, password }) => {
    dispatch({
      type: "setErrorMessage",
      payload: {
        message: "",
      },
    });
    dispatch({
      type: "loader_update",
      payload: {
        flag_loader: true,
      },
    });
    dispatch({
      type: "changeAlertClassName",
      payload: "",
    });
    ResetPasswordAPI(
      {
        email,
      },
      (res) => {
        console.log("res.data in resetpasswordcall in auth context", res.data);
        //debugger;
        if (res.data.status == true) {
          console.log("status is true");
          console.log("res.data.data", res.data);
          dispatch({
            type: "setMeassage",
            payload: {
              // user_email:res.data.data.user_email,
              message: res.data.data,
              alertclassName: "successful_msg",
            },
          });
          dispatch({
            type: "flag_email_sent",
            payload: {
              flag_email_sent: true,
            },
          });
          dispatch({
            type: "loader_update",
            payload: {
              flag_loader: false,
            },
          });
        } else {
          dispatch({
            type: "setErrorMessage",
            payload: {
              message: res.data.error.message,
              alertclassName: "errMsg",
            },
          });
          dispatch({
            type: "loader_update",
            payload: {
              flag_loader: false,
            },
          });
          dispatch({
            type: "flag_email_sent",
            payload: {
              flag_email_sent: false,
            },
          });
        }
      },
      (res) => {
        console.log("res.data in resetpasswordcall in auth context", res.data);
        //debugger;
        dispatch({
          type: "setErrorMessage",
          payload: {
            message: res.data.error.message,
            alertclassName: "errMsg",
          },
        });
        dispatch({
          type: "loader_update",
          payload: {
            flag_loader: false,
          },
        });
        dispatch({
          type: "flag_email_sent",
          payload: {
            flag_email_sent: false,
          },
        });
      }
    );
  };
const updatepasswordcall = (dispatch) =>
  async ({ email, token, password }) => {
    dispatch({
      type: "loader_update",
      payload: {
        flag_loader: true,
      },
    });
    dispatch({
      type: "setErrorMessage",
      payload: {
        message: "",
      },
    });

    UpdatePasswordAPI(
      {
        password,
        token,
      },
      (res) => {
        console.log("res.data in UpdatePasswordAPI in auth context", res.data);
        if (res.data.status == true) {
          // debugger0;
          console.log("status is true");
          //  alert(res.data.data)
          dispatch({
            type: "setdata",
            payload: {
              token: token,
              password: password,
              message: res.data.data,
              alertclassName: "successful_message",
            },
          });
          dispatch({
            type: "loader_update",
            payload: {
              flag_loader: false,
            },
          });
        } else {
          dispatch({
            type: "set_error_message_Msg",
            payload: {
              message: res.data,
              className: "errMsg",
            },
          });
          dispatch({
            type: "loader_update",
            payload: {
              flag_loader: false,
            },
          });

          // dispatch({
          //   type:"setEmail",
          //   payload: {
          //     user_email:"",
          //     flag_authenticated: true
          //   }
          //})
        }
      }
    );
  };

const ChangeAlertClassName = (dispatch) => (data) => {
  console.log("Printing data to change alert claSS NAME", data);

  dispatch({
    type: "chageAlertClassName",
    payload: data,
  });
};

const GetAllUserData = (dispatch) => async () => {
  GetAllUsersAPI(
    (res) => {
      console.log(res.data.data);
      if (res.data.status) {
        // res.data.data = res.data.data.filter((rr, i) => {
        //   return rr.user_status === "Pending";
        // });
        // res.data.data = res.data.data.map((rr, i) => {
        //   return {
        //     ...rr,
        //     id: i + 1,
        //   };
        // });
        dispatch({
          type: "allUserData",
          payload: res.data.data[0]
        });
      } else {
        alert("Error");
      }
    },
    (err) => {
      console.log("error", err);
    }
  );
};

const SetErrorMessageToNull = (dispatch) => (data) => {
  dispatch({
    type: "setErrorMessage",
    payload: {
      message:(data[0])?data[0]:"",
      alertclassName: data[1]?data[1]:""
    }
  })
};
const SetRejectionNote = (dispatch) => (data) => {
  dispatch({
    type: "setRejectionNote",
    payload: data
  })
}
export const { Provider, Context } = createDataContext(
  auth_context_Reducer,
  {
    loginCall,
    checkUserLoggedIn,
    logoutCall,
    registercall,
    resetpasswordcall,
    verifyemail,
    verifyLink,
    updatepasswordcall,
    ChangeFlagEmailSent,
    ChangeAlertClassName,
    GetAllUserData,
    SetErrorMessageToNull,
    setflagloader,
    SetRejectionNote
  },
  {
    // User Detail Object
    //access_token: "",
    // user_email:"",
    // user_firstname:"",
    // user_lastname:"",
    // flag_authenticated: false
    user_detail: {
      access_token: "",
      user_email: "",
      user_firstname: "",
      user_lastname: "",
      user_password: "",
      user_phone: "",
      flag_authenticated: false,
      flag_email_verified: false,
      flag_admin_approval:false,
      flag_running_userme: true,
    },
    flag_loader: false,
    flag_email_sent: false,
    message: "",
    verifyngEmailAddress: 0,
    alertclassName: "",
    allUserData: [],
    verifyingEmailAddressMessage:"",
    isRedirectedFromVerifyEmail:false,
    rejectionNote:""
  }
);
