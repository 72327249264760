import { Box, Divider } from "@mui/material";
import React, { useState,useEffect, useContext} from "react";
import { Navigate,useNavigate, useSearchParams, useLocation  } from "react-router-dom";
import Header from "../components/header/header";
import LeftSection from "./dashboard/leftsection";
import RightSection from "./dashboard/rightsection";
import "../pages/verifyemail.css"
// import { verifyEmailAPI } from "../service/verifyemail.service";
import { Context as AuthContext } from '../context/auth_context'; 
import Loader from "./dashboard/loader";

// const verifyEmailAPI = (emailAddress,(res) =>{
//   if(res?.data?.status===false){
//      alert("Incorrect Email address")
//   }
//   console.log("printing responce of  verifyemail  api" ,res) 
// },(err) => {
// alert("Got Error of verifyemail api",err)
// })

const VerifyLink = () => {
  const { state, verifyemail, verifyLink, redirectTo } = useContext(AuthContext);
  const navigate = useNavigate();
  const {user_detail,flag_loader,} = state;
  const location = useLocation();
  const queryParams = location;
  const [ searchParams ] = useSearchParams();
  console.log("Search Params ", searchParams)
  const token = searchParams?.get('token');
  console.log("Verify Email Address called", token);
  console.log("Printing state for auth context", state);

  useEffect(()=>{
    // debugger;
    console.log("Printig otojen in useffet", token);
    if(token){
      verifyLink({token},(res)=>{Navigate("/not-approved")});
    }
  }, [])

  useEffect(()=>{
    console.log("Printing verifying email address", state?.verifyingEmailAddress);
    //debugger;

    if(state?.verifyingEmailAddress === 2){
      navigate("/login");
    }
  }, [state?.verifyingEmailAddress])

  return (<>
    <Header/>
    {flag_loader === true ? <Loader /> : null}
       <div className='email_box_main'>
       <div className='email_box '>
          <h4 style={{display:"flex" , justifyContent:"center",padding:"10px" , paddingTop:"70px",color:"green"}}>
            {state?.verifyingEmailAddress === 1 ? "We are verifying your email address" : state?.verifyingEmailAddress === 2 ? "Your Email Address has been verified" : state?.verifyingEmailAddress === 3 ? "Incorrect Email Address." : ""}
          </h4>
          <h4 style={{display:"flex" , justifyContent:"center",padding:"10px",color:"green"}}>
          Redirecting...</h4>
          
           </div>
        </div>      
      
  
  </>)
};

export default VerifyLink;
