import React, { Fragment, useEffect, useState } from 'react';
import { Navigate ,useNavigate} from "react-router-dom";
import LeftSection from './leftsection';
import RightSection from './rightsection';
import $ from "jquery"
import { Box,Divider} from "@mui/material";
import "../dashboard/SendUsCase.css";
import Header from '../../components/header/header';


const PremiumAllianceSampleValuation = () => {
       
    useEffect(()=>{
        $(document).ready(function(){
            $(this).scrollTop(0);
        });
      },[])

     
      const navigate = useNavigate();
    return (
        <Fragment>
            
        {(window.outerWidth < 1200) ?
            <>
            <Header/>
                <div className='dashboard_page' >
                <div className='middle_section '>
                        <Box className="middle_header">
                            <div className='upload_tool'>Premium Alliance Information Bussiness valuation Request</div> 
                        </Box>
                        <Divider/>
                        <iframe src="https://storage.googleapis.com/farmers-life-ins-public-storage/Premium%20Alliance%20Marketing%20Material/Premium_Alliance_-_Informal_Business_Valuation_Request.pdf" className='iframe_premium_finance_checklist' style={{minHeight:"100vh",width:"95%",margin:"2%"}}></iframe>
                    </div>
                    <RightSection/>
                    <LeftSection/>
                </div>
            </>  :
            <>
            <Header/>
                <div className='dashboard_page ' >
                    <LeftSection/>
                    <div className='middle_section '>
                        <Box className="middle_header">
                            <div className='upload_tool'>Premium Alliance Information Bussiness valuation Request</div> 
                        </Box>
                        <Divider/>
                        <iframe src="https://storage.googleapis.com/farmers-life-ins-public-storage/Premium%20Alliance%20Marketing%20Material/Premium_Alliance_-_Informal_Business_Valuation_Request.pdf" className='iframe_premium_finance_checklist' style={{minHeight:"100vh",width:"95%",margin:"2%"}}></iframe>
                    </div>
                    <RightSection/>
                </div>
                    
            </>
        }
        </Fragment>
    )
};

export default PremiumAllianceSampleValuation;