import Axios from "./axios";

export const ResetPasswordAPI  = async(data,onSuccess ,onError)=>{
    try{
        console.log("ID Data resetpassword in service file ",data);
        //  debugger;
        const response =await Axios.post('/api/user/resetPassword', data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        console.log("printing responce of resetpassword in service file", response)
        //  debugger;
        onSuccess && onSuccess(response);
        // debugger;
    }
    catch(err){
        console.log("got an error",err);
        onError && onError(err)
    }
}


