/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Redirect,
  Routes,
  Route,
  Switch,
  useNavigate,
  withRouter,
  Navigate,
  useSearchParams,
} from "react-router-dom";
import HomePage from "../pages/dashboard/dashboard";
import LoginPage from "../login/login.js";
import { Context as AuthContext } from "../context/auth_context";
import TrainingPage from "../pages/dashboard/trainingpage";
import UploadTool from "../pages/dashboard/uploadtool";
import Inflation from "../pages/dashboard/Inflationapp";
import Register from "../pages/dashboard/register";
import VerifyLink from "../pages/verifylink";
import Login from "../login/login.js";
import VerifyEmail from "../pages/verifyemail";
import ConfirmMail from "../pages/confirmMail";
import ResetPassword from "../pages/resetpassword";
import SendUsCase from "../pages/dashboard/SendUsCase";
import PremiumFinanceChecklist from "../pages/dashboard/PremiumFinanceChecklist";
import ForeignNationals from "../pages/dashboard/ForeignNationals";
import InitiateAPremiumFinanceCase from "../pages/dashboard/InitiateAPremiumFinanceCase";
import PremiumFinanceChecklists from "../pages/dashboard/PremiumFinanceChecklist";
import CustomizablePremiumFinanceMarketing from "../pages/dashboard/customizablePremiumFinanceMarketing";
import MostImportatntConsiderationsForPremiumFinance from "../pages/dashboard/MostImportatntConsiderationsForPremiumFinance";
import KeyPersonProductOption from "../pages/dashboard/keyPersonProductOption";
import PremiumAllianceSampleValuation from "../pages/dashboard/PremiumAllianceSampleValuation";
import PremiumAllianceInformationBussinessvaluationRequest from "../pages/dashboard/PremiumAllianceInformationBussinessvaluationRequest";
import PremiumAllianceCryptoValuationCheatsheet from "../pages/dashboard/PremiumAllianceCryptoValuationCheatsheet";
import { Box, CircularProgress } from "@mui/material";
import Cookies from "js-cookie";
import PendingUsers from "../pages/dashboard/UsersList";
import UserListNew from "../pages/UserList/UserListNew";
import NotApprovedPage from "../pages/NotApprovedPage.jsx";
//export const Routes = withRouter(({ history }) => {

export const RoutesMain = () => {
  const { state, checkUserLoggedIn,verifyLink } = useContext(AuthContext);
  const { user_detail, flag_loader } = state;
  console.log("User Detail from Routes Main", user_detail);
  console.log("User Detail from Routes Main", state);

  // console.log(first)
  const [searchParams] = useSearchParams();

  useEffect(async () => {
    console.log("UseEffect of Routes Main called.");

    // if (!user_detail.flag_authenticated)
    const token = searchParams?.get('token');
    if(token){
      await verifyLink({token});
      checkUserLoggedIn()
    }else{
      checkUserLoggedIn();
    }
  }, []);

  useEffect(() => {
    if (state?.flag_authenticated) {
      checkUserLoggedIn();
      // alert("add styles");
      if (document.getElementById("ghoogle-chat-btn") !== null) {
        document.getElementById("ghoogle-chat-btn").style.display = "block";
        // document.getElementById("ghoogle-chat-btn").style.position = "fixed";
        // document.getElementById("ghoogle-chat-btn").style.top = "85px";
      }
    } else {
      if (document.getElementById("ghoogle-chat-btn") !== null) {
        console.log(document.getElementById("ghoogle-chat-btn").style);
        document.getElementById("ghoogle-chat-btn").style.display = "none";
      }
    }
  }, [state?.flag_authenticated]);

  if (user_detail.flag_running_userme) {
    return <></>;
  }

  if (user_detail.flag_authenticated) {
    // debugger;
    console.log("Route Page: User authenticated.");
    console.log("user detail", user_detail);
    if (user_detail.flag_email_verified) {
      // debugger
      if (user_detail.flag_admin_approval) {
        return (
          <>
            <Routes>
              <Route path="/login" element={<Navigate to="/home" />} />
              <Route path="/verify-email" element={<Navigate to="/home" />} />
              <Route path="/register" element={<Navigate to="/home" />} />
              <Route path="/home" element={<HomePage />} />
              <Route path="/training-app" element={<TrainingPage />} />
              <Route path="/send-us-case" element={<SendUsCase />} />
              {state.user_detail.role === "Admin" && (
                <Route path="/agentList" element={<UserListNew />} />
              )}

              {/* //TODO Remove below  path */}
              <Route path="/userslistold" element={<PendingUsers />} />
              <Route
                path="/Premium-Finance-Checklist"
                element={<PremiumFinanceChecklist />}
              />
              <Route path="/Forign-Nationals" element={<ForeignNationals />} />
              <Route path="/upload-tool" element={<UploadTool />} />
              <Route path="/Inflation-app" element={<Inflation />} />
              <Route path="/verify-link" element={<VerifyLink />} />
              <Route
                path="/initiate-a-premium-finance-case"
                element={<InitiateAPremiumFinanceCase />}
              />
              <Route
                path="/premium-finance-checklists"
                element={<PremiumFinanceChecklists />}
              />
              <Route
                path="/customizable-premium-finance-marketing"
                element={<CustomizablePremiumFinanceMarketing />}
              />
              <Route
                path="/4-Most-Importatnt-Considerations-For-Premium-Finance"
                element={<MostImportatntConsiderationsForPremiumFinance />}
              />
              <Route
                path="/Premium-Alliance-Sample-Valuation"
                element={<PremiumAllianceSampleValuation />}
              />
              <Route
                path="/key-Person-Product-Option"
                element={<KeyPersonProductOption />}
              />
              <Route
                path="/Premium-Alliance-Crypto-Valuation-Cheatsheet"
                element={<PremiumAllianceCryptoValuationCheatsheet />}
              />
              <Route
                path="/Premium-Alliance-Information-Bussiness-valuation-Request"
                element={
                  <PremiumAllianceInformationBussinessvaluationRequest />
                }
              />
              {/* <Route path="/pendingusers" element={<PendingUsers />} /> */}
              <Route path="*" element={<Navigate to="/home" />} />
            </Routes>
          </>
        );
      } else {
        return (
          <Routes>
            <Route path="/not-approved" element={<NotApprovedPage />} />
            <Route path="*" element={<Navigate to="/not-approved" />} />
          </Routes>
        );
      }
    } else {
      return (
        <>
          <Routes>
            {/* <Route path="/login" element={<Login />} /> */}
            <Route path="/verify-link" element={<VerifyLink />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="*" element={<Navigate to="/verify-email" />} />
          </Routes>
        </>
      );
    }
  } else {
    console.log("Route Page: User not authenticated.");

    //if (user is authenticated via useme)
    //! remove path from below that should not open when user is not authenticated
    return (
      <>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/verify-link" element={<VerifyLink />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          {/* <Route path="/resend-verify-email" element={<ResendVerifyEmail/>}/> */}
          <Route path="/confirm-mail" element={<ConfirmMail />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </>
    );
  }

  return (
    flag_loader === true && (
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "1399",
          background: "rgba()0, 0, 0, 0.5",
          color: "#fff",
        }}
      >
        <CircularProgress style={{ size: "45px" }} />
      </Box>
    )
  );
};
