import styled from "@emotion/styled";
import {Tabs} from "@mui/material";

export const StyledSubTabs = styled(Tabs)`
  .MuiButtonBase-root.MuiTab-root {
    background: white;
    border-radius: 8px 8px 0 0;

    :hover {
      background: pink;
    }
  }

  .MuiButtonBase-root.MuiTab-root.Mui-selected {
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-bottom: none; /* not working */
    z-index: 10;

    :hover {
      background-color: #f4f1ec ;
    }
  }
  .MuiButtonBase-root.MuiTab-root {
    border-bottom: 1px solid black;
    z-index: 10;

    :hover {
      background-color: pink;
    }
  }

  .MuiTabs-indicator {
    display: none;
  }
  min-height:60px;
  max-height:65px;
`;
