import React, { Fragment } from 'react';
import "./app.css";
import { Provider as AuthProvider } from "./context/auth_context";
import { RoutesMain } from "./router/RoutesMain";
const App = () => {
    return (
        <Fragment>
            <AuthProvider>  
                <RoutesMain />
            </AuthProvider>
        </Fragment>
    )

};

export default App;
