import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Link,
  CssBaseline,
  Input,
  Container,
  Grid,
  Alert,
  AlertTitle,
} from "@mui/material";
import "../../pages/dashboard/register.css";
import { RegisterAPI } from "../../service/register.service";
import { Navigate, useNavigate } from "react-router-dom";
import MyAdvisorCloudLogo from "../../assets/images/MyAdvisorCloudLogo.png";
import PremiumAllianceLogo from "../../assets/images/PremiumAllianceLogo.png";
import { Context as AuthContext } from "../../context/auth_context";
import Loader from "./loader";
import premiumAllianceMac from "../../assets/images/premium-alliance-mac.mp4";
import videoLogo from "../../assets/images/videoPlayBottonimage.png";
import { UserMeAPI } from "../../service/login.service";
import ErrorIcon from "@mui/icons-material/Error";
import LockRounded from "../../assets/images/InputFieldIcons/lockRounded.png";
import emailIcon from "../../assets/images/InputFieldIcons/emailIcon.png";
import personIcon from "../../assets/images/InputFieldIcons/personIcon.png";
import phoneIcon from "../../assets/images/InputFieldIcons/phoneIcon.png";
import imageBG from "../../assets/images/bg_img2.png";
import LoginHeader from "../../components/header/LoginHeader/LoginHeader";

import "bootstrap/dist/css/bootstrap.css";
import { Opacity } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import viewPassword from "../../assets/images/InputFieldIcons/viewPassword.png";
import hidePassword from "../../assets/images/InputFieldIcons/hidePassWord.png";
import ReCAPTCHA from "react-google-recaptcha";

const Register = () => {
  const [userDetail, setUserDetail] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
  });
  const { state, checkUserLoggedIn,setflagloader,SetErrorMessageToNull } = useContext(AuthContext);
  const { flag_loader } = state;
  const navigate = useNavigate();
  const videoRef = useRef();
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [displayVideoBtn, setDisplayVideoBtn] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const [showMessage, setShowMessage] = useState({
    status:"",
    message:""
  });


  const[recaptchaValue, setRecaptchaValue] = useState("")



  function HandelRecaptcha(){

  }

  const [showEmptyFieldError, setShowEmptyFieldError] = useState(false);

  const RegisterUser = () => {
    const { first_name, last_name, email, phone, password } = userDetail;

    debugger
    if (
      first_name == "" ||
      last_name == "" ||
      email == "" ||
      password == "" ||
      phone == ""||
      recaptchaValue==""
    ) {
      setShowEmptyFieldError(true);
      setShowMessage({
        status:"error",
        message:"Please fill all the fields"
      })
    } else {
      if(password.length < 8  || password.length > 15){
        setShowEmptyFieldError(true);
        setShowMessage({
          status:"error",
          message:"password length must be atleast 8 to 15 characters"
        })
        return 
      }
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userDetail.email))
      {
        setflagloader(true)
        setShowMessage({
          status:"",
          message:""
        })
        RegisterAPI(
          { first_name, last_name, email, phone, password,recaptchaValue },
          (res) => {
            if (res?.data?.status === true) {
              setShowMessage({
                status:"success",
                message:"User registered successfully"
              })
              SetErrorMessageToNull(["User registered successfully",""])
              navigate('/login')
            } else {
              setShowMessage({
                status:"error",
                message:res.data.error.message
              })
            }
            setflagloader(false)
            console.log("printing responce of get register api", res);
            // checkUserLoggedIn();
          },
          (err) => {
            
            setShowMessage({
              status:"error",
              message:"Something went wrong!"
            })
          }
        );
      }else{
        setShowEmptyFieldError(true);
        setShowMessage({
          status:"error",
          message:"Please pass the valid Email address!"
        })
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const PlayPauseVideo = () => {
    if (videoRef.current !== undefined) {
      if (isVideoPlaying === true) {
        setIsVideoPlaying(false);
        setDisplayVideoBtn(true);
      } else {
        setIsVideoPlaying(true);
        setDisplayVideoBtn(false);
      }
    }
  };
  
  return (
    <>
      <CssBaseline />
      {flag_loader === true ? <Loader /> : null}
      <LoginHeader />

      <Box
        className="register_main"
        sx={{
          width: "100%",
          background: "rgba(0, 0, 0, 0.5);",
          backgroundImage: `url(${imageBG})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundBlendMode: "multiply",
        }}
      >
        <Box className="register_form">
          <Grid Container className="form_container" direction={"column"}>
            {/* <Grid
              item
              width={"100%"}
              style={{
                fontWeight: "600",
                color: "white",
                fontSize: "16px",
                marginBottom: "10px",
              }}
            >
              Hello!
            </Grid> */}
            <Grid
              item
              width={"100%"}
              className="welcomeText"
              marginBottom={"30px"}
            >
              Welcome to Premium Alliance
            </Grid>
      
            {showMessage.status && 
              <div
              className= {showMessage.status=="error" ? "alert alert-danger":"alert alert-success" }
              role="alert"
              style={{ padding: "11px 5px 8px 15px",display:"flex",direction:"row" }}
            >
              <span style={{ marginRight: "10px" }}>
              {showMessage.status=="error" ? <ErrorIcon />:<CheckCircleIcon />}
              </span>
              <span style={{}}>{showMessage.message}</span>
            </div>
            }
     
            <Grid item marginBottom={"20px"} width={"100%"}>
              {" "}
              <TextField
                placeholder="First Name"
                className="inputField"
                marginBottom={"20px"}
                InputProps={{
                  startAdornment: (
                    <div style={{ padding: "10px" }}>
                      <img src={personIcon} />
                    </div>
                  ),
                  disableUnderline: true,
                  style: { paddingLeft: 10 },
                }}
                onChange={(e) => {
                  console.log("firstname", e.target.value);
                  setUserDetail({
                    ...userDetail,
                    first_name: e.target.value,
                  });
                }}
                required
                autoComplete="off"
              />
            </Grid>
            <Grid item marginBottom={"20px"} width={"100%"}>
              {" "}
              <TextField
                type="text"
                placeholder="Last Name"
                className="inputField"
                InputProps={{
                  startAdornment: (
                    <div style={{ padding: "10px" }}>
                      <img src={personIcon} />
                    </div>
                  ),
                  disableUnderline: true,
                  style: { paddingLeft: 10 },
                }}
                onChange={(e) => {
                  console.log("lastname", e.target.value);
                  setUserDetail({
                    ...userDetail,
                    last_name: e.target.value,
                  });
                }}
                autoComplete="off"
              />
            </Grid>
            <Grid
              item
              className="email_root"
              marginBottom={"20px"}
              width={"100%"}
            >
              {" "}
              <TextField
                type="email"
                placeholder="Email"
                className="inputField"
                InputProps={{
                  startAdornment: (
                    <div style={{ padding: "10px" }}>
                      <img src={emailIcon} />
                    </div>
                  ),
                  disableUnderline: true,
                  style: { paddingLeft: 10 },
                }}
                onChange={(e) => {
                  console.log("email", e.target.value);
                  setUserDetail({ ...userDetail, email: e.target.value });
                }}
                autoComplete="off"
              />
            </Grid>
            <Grid
              item
              className="password_root"
              marginBottom={"20px"}
              width={"100%"}
            >
              <TextField
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                className="inputField"
                InputProps={{
                  startAdornment: (
                    <div style={{ padding: "10px" }}>
                      <img src={LockRounded} />
                    </div>
                  ),
                  endAdornment: (
                    <div style={{ paddingLeft: "10px", cursor: "pointer" }}>
                      <img
                        src={showPassword ? hidePassword : viewPassword}
                        style={{ width: "20px" }}
                        alt={showPassword ? "Hide Password" : "Show Password"}
                        onClick={togglePasswordVisibility}
                      />
                    </div>
                  ),
                  disableUnderline: true,
                  style: { paddingLeft: 10 },
                }}
                onChange={(e) => {
                  console.log("password", e.target.value);
                  setUserDetail({
                    ...userDetail,
                    password: e.target.value,
                  });
                }}
                autoComplete="off"
              />
            </Grid>
            <Grid
              item
              className="phoneNumber_root"
              marginBottom={"20px"}
              width={"100%"}
            >
              {" "}
              <TextField
                type="tel"
                placeholder="Phone Number"
                className="inputField"
                maxLength={10}
                inputProps={{ maxLength: 10,inputMode: 'numeric' }}
                value={userDetail.phone}
                InputProps={{
                  
                  startAdornment: (
                    <div style={{ padding: "10px" }}>
                      <img src={phoneIcon} />
                    </div>
                  ),
                  disableUnderline: true,
                  style: { paddingLeft: 10 },
                }}
                onChange={(e) => {
                  console.log("phoneno", e.target.value);
                  Number(e.target.value).toString() !== "NaN" && setUserDetail({ ...userDetail, phone: e.target.value });
                }}
                autoComplete="off"
              />
            </Grid>
            <Grid item marginBottom={"20px"} width={"100%"}>
              <div className="recaptcha_perent">
              <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} onChange={(val) => setRecaptchaValue(val)} />


              </div>
            </Grid>

            <Grid item marginBottom={"10px"} width={"100%"}>
              <Button
                className="create_account_button"
                onClick={RegisterUser}
                disableElevation
                sx={{ textTransform: "none" }}
                variant="contained"
              >
                <span style={{ fontWeight: "600" }}>Register</span>
              </Button>
            </Grid>
            <Grid item width={"100%"}>
              <Typography
                className="registerBottomText"
                margin={"20px 30px 0px auto"}
              >
                Already have an account?{" "}
                <span
                  onClick={() => {
                    navigate("/login");
                  }}
                  style={{ fontWeight: "600", cursor: "pointer",textDecoration:"underline"}}
                >
                  Login here
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box className="video_main_register">
          <video
            className="video_register video"
            ref={videoRef}
            poster={videoLogo}
            onClick={() => {
              PlayPauseVideo();
            }}
            playsInline
            controls
            style={{
              height: "960px !important",
              width: "640px",
              marginTop: "40px !important",
              borderRadius: "20px",
            }}
          >
            <source src={premiumAllianceMac}></source>
          </video>
          {/* <div class="play-button-wrapper_register">
            { isVideoPlaying == true
             ? 
            <div title="Play video"  class="play-gif play-gif_register" id="circle-play-b" style={{display:"none"}} >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
                <path d="M40 0a40 40 0 1040 40A40 40 0 0040 0zM26 61.56V18.44L64 40z" />
            </svg>
            </div>
        :
            <div title="Play video"   class="play-gif play-gif_register " id="circle-play-b" style={{display:"block"}}>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
					<path d="M40 0a40 40 0 1040 40A40 40 0 0040 0zM26 61.56V18.44L64 40z" />
				</svg>
			</div>
            
        }
			
		        </div> */}
        </Box>
      </Box>
    </>
  );
};

export default Register;
