import Axios from "./axios";

export const RegisterAPI  = async(data,onSuccess ,onError)=>{
    try{
        console.log("ID Data in register page",data);
        // debugger;
        const response =await Axios.post('/api/user/register', data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        console.log("printing response in register api ", response)
        // debugger;
        onSuccess && onSuccess(response);
        // debugger;
    }
    catch(err){
        console.log("got an error",err);
        onError && onError(err)
    }
}