import Axios from "./axios";

export const LoginAPI  = async(dataa,onSuccess ,onError)=>{
    try{
        console.log("ID Data abc ",dataa);
        // debugger;
        const responce =await Axios.post('/api/user/login', dataa, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        console.log("printing responce", responce)
        // debugger;
        onSuccess && onSuccess(responce);
        // debugger;
    }
    catch(err){
        console.log("got an error",err);
        onError && onError(err)
    }
}
 export const UserMeAPI  = async(onSuccess ,onError)=>{
     try{
         console.log("User me API called from login service file ");
         // debugger;
         const responce =await Axios.get('/api/user/me', {
             headers: {
                 "Content-Type": "application/json"
             },
             withCredentials: true
         });
         console.log("printing responce of user me", responce)
         // debugger;
         onSuccess && onSuccess(responce);
         // debugger;
     }
     catch(err){
         console.log("got an error",err);
         onError && onError(err)
     }
 }

export const LogoutUserAPI = async (data, onSuccess, onError)=>{
    try{
        console.log("Printing data of logout service file", data);
        const response = await Axios.post("api/user/logout", data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });

        onSuccess && onSuccess(response);
    }
    catch(err){
        onError && onError(err);
    }
}