import axios from "axios";
import AxiosInstance from "./axios";
import { useContext } from 'react';



export const BankRatesAPI = async(data,onSuccess ,onError)=>{
    console.log("Bank Rate API Call: Data ", data)
    try{
       
        const responce =await AxiosInstance.get('/api/user/bank-rates', {
            withCredentials: true,
            headers: {
                "Content-Type": "application/json"
            }});
        console.log("printing responce", responce)
        onSuccess && onSuccess(responce);
        // debugger;
    }
    catch(err){
        console.log("got an error",err);
        onError && onError(err)
    }
}