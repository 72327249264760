import React, { Fragment, useEffect, useState, useContext } from "react";

import {
  Box,
  TextField,
  CssBaseline,
  Container,
  Button,
  Alert,
  Link,
  InputAdornment,
  IconButton,
  Grid,
  Typography,
} from "@mui/material";
import Header from "../components/header/header";
import { useSearchParams } from "react-router-dom";
import MyAdvisorCloudLogo from "../../src/assets/images/MyAdvisorCloudLogo.png";
import PremiumAllianceLogo from "../../src/assets/images/PremiumAllianceLogo.png";
import { Context as AuthContext } from "../context/auth_context";
import { Navigate, useNavigate } from "react-router-dom";
import "../assets/images/bg_img2.png";
import ImageBG from "../assets/images/bg_img2.png";
import "./resetPassword.css";
import LockRounded from "../assets/images/InputFieldIcons/lockRounded.png";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import emailIcon from "../assets/images/InputFieldIcons/emailIcon.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoginHeader from "../components/header/LoginHeader/LoginHeader";
import hidePassword from "../assets/images/InputFieldIcons/hidePassWord.png"
import viewPassword from "../assets/images/InputFieldIcons/viewPassword.png"

const ResetPassword = () => {
  const navigate = useNavigate();

  const [flagDisableBtn, setFlagDisableBtn] = useState(1);
  const [msg, setMsg] = useState("");

  const { updatepasswordcall, state } = useContext(AuthContext);

  const [userEmail_Password, setUserEmail_Password] = useState({
    email: "",
    password: "",
    repassword: "",
  });
  const [searchParams, setSearchParams] = useSearchParams();
  console.log(searchParams.get("email"));

  const matchPassword = () => {
    if(userEmail_Password.password != "" &&  userEmail_Password.repassword!= ""){
    if (userEmail_Password.password == userEmail_Password.repassword) {
      // console.log("if called------------------------------------------------");
      // console.log("userEmail_Password.password ", userEmail_Password.password);
      // console.log("token", setSearchParams("token"));
      updatepasswordcall({
        token: searchParams.get("token"),
        password: userEmail_Password.password,
      });
      navigate("/login");
    } else {
      setMsg("Passwords do not match.");
    }}
    else{
      setMsg("Please enter a password.");
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };


  // console.log("Error message from the Login page ", error_message)

  return (
    <Fragment>
      <CssBaseline />
      <LoginHeader/>
      <Box
        className="main_box"
        sx={{
          width: "100%",
          background: "rgba(0, 0, 0, 0.5);",
          backgroundImage: `url(${ImageBG})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundBlendMode: "multiply",
        }}
      >
        <Box
          className="main_container"
          sx={{
            height: "fit-content",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            className="resetpass_innerBox"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              className="resetpass_form_container"
              justifyContent={"center"}
              alignItems={"center"}
              width={"470px"}
              flexDirection={"column"}
            >
              <Grid item padding={"0px !important"} width={"100%"}>
                <ArrowBackIcon
                  sx={{ color: "white" }}
                  onClick={() => navigate("/login")}
                />
              </Grid>
              <Grid
                item
                padding={"0px !important"}
                width={"100%"}
                sx={{ marginBottom: "20px" }}
              >
                <Typography
                  sx={{
                    fontWeight: "500",
                    color: "white !important",
                    fontSize: "25px",
                    marginBottom: "20px",
                    
                  }}
                >
                  Reset Password
                </Typography>
              </Grid>
              {/* <span className='errMsg'>{error_message}</span><span></span> */}

              <Grid item marginBottom={"20px"} width={"100%"}>
                <TextField
                  type="email"
                  className="resetpass_inputField"
                  required
                  InputProps={{
                    startAdornment: (
                      <div style={{ padding: "10px" }}>
                        <img src={emailIcon} />
                      </div>
                    ),
                    disableUnderline: true,
                    style: { paddingLeft: 10 },
                  }}
                  
                  value={searchParams.get("email")}
                  disabled
                />
              </Grid>
              <Grid
                item
                className="password"
                padding={"0px !important"}
                width={"100%"}
                sx={{ marginBottom: "20px" }}
              >
                <TextField
                  type={showPassword ? "text" : "password"}
                  id="password"
                  className="resetpass_inputField"
                  placeholder="New Password"
                  required
                  InputProps={{
                    startAdornment: (
                      <div style={{ padding: "10px" }}>
                        <img src={LockRounded} />
                      </div>
                    ),
                    endAdornment: (
                      <div style={{ paddingLeft: "10px", cursor: "pointer" }}>
                        <img
                          src={showPassword ? hidePassword : viewPassword}
                          style={{width:"20px"}}
                          alt={showPassword ? "Hide Password" : "Show Password"}
                          onClick={togglePasswordVisibility}
                        />
                      </div>
                    ),
                    
                    disableUnderline: true,
                    style: { paddingLeft: 10 },
                  }}
                  value={userEmail_Password?.password}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setUserEmail_Password({
                      ...userEmail_Password,
                      password: e.target.value,
                    });
                  }}
                />
              </Grid>

              <Grid
                item
                className="repassword"
                padding={"0px !important"}
                width={"100%"}
                marginBottom={"50px"}
              >
                <TextField
                  type={showPassword2 ? "text" : "password"}
                  id="password"
                  required
                  className="resetpass_inputField"
                  placeholder="Confirm New Password"
                  InputProps={{
                    startAdornment: (
                      <div style={{ padding: "10px" }}>
                        <img src={LockRounded} />
                      </div>
                    ),
                    endAdornment: (
                      <div style={{ paddingLeft: "10px", cursor: "pointer" }}>
                        <img
                          src={showPassword2 ? hidePassword : viewPassword}
                          style={{width:"20px"}}
                          alt={showPassword2 ? "Hide Password" : "Show Password"}
                          onClick={togglePasswordVisibility2}
                        />
                      </div>
                    ),
                   
                    disableUnderline: true,
                    style: { paddingLeft: 10 },
                  }}
                  value={userEmail_Password?.repassword}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setUserEmail_Password({
                      ...userEmail_Password,
                      repassword: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item width={"100%"}>
                {msg == "" ?"" : <div className="alert alert-danger" role="alert" style={{background:"#ff8080",border:"#ff8080",fontSize:"15px",padding:"5px 5px 5px 15px",}}>{msg}</div>}
                <Button
                  className="resetpass_btn"
                  onClick={matchPassword}
                  disabled={flagDisableBtn === 0 ? true : false}
                  sx={{
                    textTransform: "none",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  <span style={{color:"black",fontSize:"20px"}}>

                  Reset Password
                  </span>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default ResetPassword;
