import { Box, Divider, Link } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import {
  Navigate,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import Header from "../components/header/header";
import LeftSection from "./dashboard/leftsection";
import RightSection from "./dashboard/rightsection";
import "../pages/verifyemail.css";
// import { verifyEmailAPI } from "../service/verifyemail.service";
import { Context as AuthContext } from "../context/auth_context";
import VerifyLink from "./verifylink";
import MyAdvisorCloudLogo from "../../src/assets/images/MyAdvisorCloudLogo.png";
import PremiumAllianceLogo from "../../src/assets/images/PremiumAllianceLogo.png";
import Loader from "./dashboard/loader";


const NotApprovedPage = () => {
  const { state, verifyemail, logoutCall,setflagloader } = useContext(AuthContext);
  const navigate = useNavigate();
  const { user_detail,flag_loader } = state;
  const location = useLocation();
  const queryParams = location;
  const [searchParams] = useSearchParams();
  console.log("Search Params ", searchParams);
  const token = searchParams?.get("token");
  console.log("Verify Email Address called", token);

  let emailAddress = user_detail.user_email;

  console.log("Location: ", location);

  if (emailAddress === "") emailAddress = location?.state?.email;

  console.log("Email address in Verify Email: ", emailAddress);
  const [flagSent, setFlagSent] = useState("");

  useEffect(() => {
    setflagloader(false)
  }, []);

  return (
    <>
       {flag_loader === true ? <Loader /> : null}
      <Box className="header">
        <div className="global_header">
          <div className="left_section">
            <span
              onClick={() => navigate("/home")}
              style={{ cursor: "pointer" }}
            >
              <img
                src={MyAdvisorCloudLogo}
                alt="my advisor cloud logo"
                className="first_image_logo"
              />
            </span>
            &nbsp;&nbsp;&nbsp;
            <a href="https://premiumalliance.com/">
              <img
                src={PremiumAllianceLogo}
                alt="premium alliance logo"
                className="second_image_logo"
              />
            </a>
          </div>
          <div className="right_section">
            {/* <span
              onClick={() => {
                navigate("/login");
              }}
              style={{ cursor: "pointer" }}
            >
              Login
            </span> */}
          </div>
        </div>
      </Box>
      { (
        <div className="email_box_main">
          <div className="email_box ">
            <div className="verify_mail">Under Review</div>
            <Divider />
            {/* {flagSent && (
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "green",
                  padding: "20px 0",
                }}
              >
                {flagSent}
              </span>
            )} */}

            <div className="content">
              Your email has been successfully verified. Your registration request is currently under review.<br/> You will receive an email once your registration request is approved.
            </div>
          
            <button onClick={logoutCall} className="verifyemail_logout_btn">
              Logout
            </button>
          </div>
        </div>
      )}
    </>
    // <>hello</>
  );
};

export default NotApprovedPage;
