import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const Loader = () => {

    const centerDivStyle = {
        position: "fixed",
        top: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        minHeight: "100vh",
        flexDirection: "column",
        zIndex: 1399,
        background: "rgba(0,0,0,0.1)"
    }

    const loaderStyle = {
        width: "auto",
        height: "auto",
        background: "transparent",
        color: "#fff"
    }

    return (

        <div className="center_div" style={centerDivStyle}>
            <CircularProgress id="loader" style={loaderStyle} />
        </div>

    )
}

export default Loader;