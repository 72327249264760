import React, { Fragment, useState ,useEffect} from 'react';
import './trainingpage.css';
import CorrectImage from '../../assets/images/correct.png';
import InformationIcon from '../../assets/images/information.png';
import trainingApp from "../../assets/images/trainig_app.png"
import samplePresentation from "../../assets/images/presentation.png"
import foreignNationals  from "../../assets/images/Foreign_Nationals.png"
import smallCostPlatform  from "../../assets/images/Small_Cost.png"
import uploadTool  from "../../assets/images/Upload_Tool.png"
import SendUsCase from "../../assets/images/Send us case.png"
import { Tooltip, Button, Divider } from '@mui/material';
import Header from '../../components/header/header';
import { Box } from '@mui/system';
import chat from "../../assets/images/chat_icon.svg"
import Eric from "../../assets/images/Eric-Palmer_450_Web.jpg"
import Cayse from "../../assets/images/Cayse-Mersch_450_Web.jpg"
import Kelley from "../../assets/images/Kelley_Sorensen_450x450.jpg"
import DialogBox from '../dialogbox';
import TrainingApp from './trainingpage';
import "../../pages/dashboard/uploadtool.css"
import LeftSection from './leftsection';
import RightSection from './rightsection';
import { Navigate ,useNavigate} from "react-router-dom"; 
import $ from "jquery"

const UploadTool = () => {

    const [showPopup,setShowPopup]=useState(false);
    const [videoPopup,setVideoPopup]=useState(false);
    useEffect(()=>{
        $(document).ready(function(){
            $(this).scrollTop(0);
        });
    },[]) 
    const navigate = useNavigate();
    
    return (
        <Fragment>
           
            <Header/>

            {(window.outerWidth <= 1200)
             ?
             <>
             <div className='dashboard_page dashboard_page_upload_tool' >
                <div className='middle_section middle'>
                        <Box className="middle_header">
                            <div className='upload_tool'>Upload Tool</div> 
                            {/* <a className='link' onClick={()=>{navigate('/home')}} style={{cursor:"pointer"}}  >  
                            <span className='dashboard_upload_tool'>Dashboard</span>   
                            </a> */}
                        </Box>
                        <Divider/>
                
                        <iframe  className='upload_tool_ifame' src="https://gfiledrop.appspot.com/fb?email=pilk91_02__09__03__11__31__10__01_l_06__02__09__43__10__09__02__06__03__05__06__01_ll_03__01__07__11__02__31__11__04__06_" width="100%" height="500" frameborder="0" scrolling="no">
                                </iframe>
                            
                </div>
                <RightSection/>
                <LeftSection/>
            </div>
            </> 
            :
            <>
            <div className='dashboard_page  dashboard_page_upload_tool ' >
                <LeftSection/>
                <div className='middle_section middle'>
                <Box className="middle_header">
                    <div className='upload_tool'>Upload Tool</div> 
                    {/* <a className='link' onClick={()=>{navigate('/home')}} style={{cursor:"pointer"}}  >  
                       <span className='dashboard_upload_tool'>Dashboard</span>   
                       </a> */}
                   </Box>
                   <Divider/>
          
                   <iframe  className='upload_tool_ifame' src="https://gfiledrop.appspot.com/fb?email=pilk91_02__09__03__11__31__10__01_l_06__02__09__43__10__09__02__06__03__05__06__01_ll_03__01__07__11__02__31__11__04__06_" width="100%" height="500" frameborder="0" scrolling="no">
                        </iframe>
                    
           </div>
               <RightSection/>
            </div>
             </>

            } 
        </Fragment>
    )
};

export default UploadTool;