import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Checkbox,
  CssBaseline,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  Menu,
  MenuItem,
  Select,
  Snackbar,
  styled,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./RejectionNotePopup.css";
import moment from "moment";
const RejectionNotePopup = (props) => {
  const rejectionNote =
    "this is long rejection note. entered by admin who rejected the user. this can be long";
  const rejectionNote1 = "this is. this can be long";

  
  return (
    <>
      return (
      <>
        <Dialog
          open={true}
          // onClose={() => { setFlagAgentDeleted(false) }}

          onClose={props.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            <Grid
              container
              flexWrap={"nowrap"}
              className="mainGridContainer"
              rowSpacing={1}
           
              direction={"column"}
              // justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid
                item
                width={"100%"}
                sx={{
                  paddingBottom: "10px",
                  borderBottom: "2px solid #c8bba1",
                  // marginLeft: "auto",
                  /*   transform: "translate(9px, -25px) !important",
                  "@media screen and (max-width:600px)": {
                    transform: "translate(9px, -15px) !important",
                  }, */
                  // fontSize:"25px !important"
                }}
                onClick={() => props.setShowRejectionNotePopup(false)}
              >
                <Grid container justifyContent={"space-between"}>
                  <Grid item width={"70%"}>
                    <Typography sx={{ fontWeight: "700", fontSize: "25px" }}>
                     Info
                    </Typography>
                  </Grid>
                  <Grid item>
                    {" "}
                    <CloseIcon sx={{ fontSize: "30px" }} />
                  </Grid>
                </Grid>
              </Grid>

              <hr style={{ color: "black", height: "5px" }}></hr>

              <Grid item width={"100%"}>
                {/*  <Grid
                  container
                  direction={"column"}
                  justifyContent={"center"}
                  rowSpacing={1}
                >
                  <Grid item width={"100%"}>
                    {" "}
                    <Grid
                      container
                      justifyContent={"left"}
                      alignItems={"center"}
                      sx={{
                        "@media screen and (max-width:600px)": {
                        alignItems: "top",
                        },

                      }}
                    >
                      <Grid
                        item
                        width={"35%"}
                        sx={{
                          "@media screen and (max-width:600px)": {
                            width: "25% !important",
                          },
                          "@media screen and (max-width:400px)": {
                            width: "30% !important",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "600",
                            fontSize: "15px",
                            width: "95%",
                            "@media screen and (max-width:400px)": {
                              fontSize: "14px !important"
                            },
                          }}
                        >
                          <span>Rejected By : </span>
                        </Typography>
                      </Grid>
                      <Grid item width={"60%"} sx={{
                        "@media screen and (max-width:600px)": {
                          width: "70% !important",
                        },
                          "@media screen and (max-width:400px)": {
                            width: "70% !important",
                            fontSize: "11px !important"
                          },
                        }}>
                        <Typography
                          sx={{
                            border: "1px solid #c8bba1",
                            padding: "2px 7px",
                            borderRadius: "8px",
                            marginBottom: "3px",
                            width: "95%",
                            "@media screen and (max-width:400px)": {
                              fontSize: "14px !important"
                            },
                          }}
                        >
                          divyang
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item width={"100%"}>
                    {" "}
                    <Grid
                      container
                      justifyContent={"left"}
                      alignItems={"center"}
                    >
                      <Grid
                        item
                        width={"35%"}
                        sx={{
                          "@media screen and (max-width:600px)": {
                            width: "25% !important",
                          },
                          "@media screen and (max-width:400px)": {
                            width: "30% !important",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "600",
                            fontSize: "15px",
                            width: "95%",
                            "@media screen and (max-width:400px)": {
                              fontSize: "14px !important"
                            },
                          }}
                        >
                          <span>Rejection Date : </span>
                        </Typography>
                      </Grid>
                      <Grid item width={"60%"} sx={{
                        "@media screen and (max-width:600px)": {
                          width: "70% !important",
                        },
                          "@media screen and (max-width:400px)": {
                            width: "70% !important",
                          },
                        }}>
                        <Typography
                          sx={{
                            border: "1px solid #c8bba1",
                            padding: "2px 7px",
                            borderRadius: "8px",
                            marginBottom: "3px",
                            width: "95%",
                            "@media screen and (max-width:400px)": {
                              fontSize: "14px !important"
                            },
                          }}
                        >
                          05/13/2023
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item width={"100%"}>
                    {" "}
                    <Grid container justifyContent={"left"} alignItems={"top"}>
                      <Grid
                        item
                        width={"35%"}
                        sx={{
                          "@media screen and (max-width:600px)": {
                            width: "25% !important",
                          },
                          "@media screen and (max-width:400px)": {
                            width: "30% !important",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "600",
                            fontSize: "15px",
                            width: "95%",
                            "@media screen and (max-width:400px)": {
                              fontSize: "14px !important"
                            },
                          }}
                        >
                          <span>Rejection Note : </span>
                        </Typography>
                      </Grid>
                      <Grid item width={"60%"} sx={{
                        "@media screen and (max-width:600px)": {
                          width: "70% !important",
                        },
                          "@media screen and (max-width:400px)": {
                            width: "70% !important",
                          },
                        }}>
                        <Typography
                          sx={{
                            border: "1px solid #c8bba1",
                            padding: "2px 7px",
                            borderRadius: "8px",
                            marginBottom: "3px",
                            width: "95%",
                            height: "75px",
                            "@media screen and (max-width:400px)": {
                              fontSize: "14px !important"
                            },
                          }}
                        >
                          {rejectionNote1}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid> */}

                <table style={{ width: "100%" }}>
                  <tr>
                    <td className="dataTitle">Rejected By </td>
                    <td>{props.rejectionNotePopupDetail.user_rejected_by_admin_name}</td>
                  </tr>
                  <tr>
                    <td className="dataTitle">Rejection Date</td>
                    <td>{ moment(props.rejectionNotePopupDetail.deleted_at).format("MM/DD/YYYY")}</td>
                  </tr>
                  <tr>
                    <td className="dataTitle">Rejection Note</td>
                    <td>{props.rejectionNotePopupDetail.user_rejection_note}</td>
                  </tr>
                </table>
              </Grid>

              <Grid item width={"100px"} marginTop={"30px"}>
                <Button
                  color="success"
                  variant="outlined"
                  sx={{
                    border: "1px solid #c8bba1",
                    color: "#000",
                    background: "#c8bba1",
                    textTransform: "none",
                    marginRight: "20px",
                    "&:hover": {
                      border: "1px solid #c8bba1",
                    },
                  }}
                  onClick={() => props.setShowRejectionNotePopup(false)}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </>
      );
    </>
  );
};

export default RejectionNotePopup;
