import react, { Fragment } from 'react';
import { Dialog ,Box, Typography,Input,Button} from '@mui/material';
import "././dashboard/dialogbox.css"
import { getThemeProps } from '@mui/system';
import pdf from "../assets/images/pdf.svg"
import close from "../assets/images/close.svg"
import { Navigate ,useNavigate} from "react-router-dom";

const DialogBox = (props) => {

    const navigate = useNavigate();
    return(
        <Fragment>
            <Dialog
                open={true}
                onClose={() => {
                   getThemeProps.close();
                }}
                disableEscapeKeyDown={false}
            >
            <div className='main_head'><h4>Download Sample Presentation</h4></div>
            <Button style={{position: "absolute", top: "10px", right: "10px", fontSize: "20px", color: "#1c2735"}} onClick={()=>{props.close()}}>
              <img src={close} />
            </Button>
                <Box className='dialog_content'  style={{margin: "20px 0 0 "}}>

                        {/* <Box className='first' >
                        <a 
                        // onClick={()=>{navigate("/initiate-a-premium-finance-case")}}
                        target="_blank" href='https://docs.google.com/forms/d/e/1FAIpQLSeY1NrjcrcuR_Saimo7bxgTtEqggfNIlBGzXJr11bao065QeQ/viewform?usp=sf_link'
                        style={{textDecoration:"none",color:"black",cursor:"pointer"}}>
                            <img src={pdf}/>
                            <span className='pdf1' >Initiate a Premium Finance Case!</span>
                            <div >Have a case you think may qualify as premium finance?  Submit the details to our team and we'll evaluate the information and work with you to find the best solution for the client. Please be sure you have the following information on hand: client DOB, annual income, net worth, and beneficiary information. A Brokers Alliance representative will reach out to you within 12-24 business hours after we receive your submission.</div>
                            </a>
                            </Box> */}

                        <Box className='second'>
                        <a onClick={()=>{navigate("/premium-finance-checklists")}}
                        style={{textDecoration:"none",color:"black",cursor:"pointer"}}>
                             <img src={pdf}/>
                            <span className='pdf2'>Premium Finance Checklist</span>
                            <div >This checklist cover the majority of items required by lenders and insurance companies for premium financed life insurance cases. There may be additional requirements by certain lenders and/or carriers that are not listed but this will help get you started.</div>
                            </a>
                        </Box>

                        <Box className='third'>
                        <a onClick={()=>{navigate("/Customizable-premium-finance-marketing")}}
                        style={{textDecoration:"none",color:"black",cursor:"pointer"}}>
                             <img src={pdf}/>
                            <span className='pdf3'>Customizable Premium Finance Marketing</span>
                            <div >Download this complimentary and completely customizable Premium Finance marketing flyer, "Alleviating Estate Taxes with Life Insurance." Premium Finance is an effective strategy for many reasons, one of them is Estate Planning. This pdf flyer will help engage and educate your High Net Worth clients.</div>
                            </a>
                            </Box>
{/* 
                        <Box className='fourth'>
                            <a onClick={()=>{navigate("/4-Most-Importatnt-Considerations-For-Premium-Finance")}} style={{textDecoration:"none",color:"black",cursor:"pointer"}}>
                            <img src={pdf}/>
                                <span className='pdf4'>4 Most Important Considerations For Premium Finance</span>
                                <div >What is Premium Finance and what are the most important considerations?  This overview answers all of those questions and more. Gain valuable insight and set yourself up for success.</div>
                            </a>
                        </Box> */}
                        
                        <Box className="fourth">
                        <a onClick={()=>{navigate("/key-Person-Product-Option")}} style={{textDecoration:"none",color:"black",cursor:"pointer"}}>
                            <img src={pdf}/>
                                <span className='pdf4'>Key Person Product Option</span>
                                <div>Term insurance, while guaranteed to be the lowest cost form of Key Person coverage, will have no residual benefit to the Company or the Insured once the term has expired. In these situations, we provide analyses to show alternatives to this coverage that will provide additional benefits to the Company  and/or the Insured once the Key Person coverage term is over.</div>
                            </a>
                        </Box>

                        <Box className="fourth">
                        <a onClick={()=>{navigate("/Premium-Alliance-Sample-Valuation")}} style={{textDecoration:"none",color:"black",cursor:"pointer"}}>
                            <img src={pdf}/>
                                <span className='pdf4'>Premium Alliance Sample Valuation</span>
                                <div>This dynamically driven and customized report was generated to provide the business owner, and entrepreneur or his or her advisor with general estimates of fair market value and liquidation value under relevant transaction conditions assumed for the profiled business at a fair price and in real-time.</div>
                            </a>
                        </Box>

                        <Box className="fourth">
                        <a onClick={()=>{navigate("/Premium-Alliance-Information-Bussiness-valuation-Request")}} style={{textDecoration:"none",color:"black",cursor:"pointer"}}>
                            <img src={pdf}/>
                                <span className='pdf4'>Premium Alliance Information Bussiness valuation Request</span>
                                <div>How does your business owner client plan for the future without knowing the value of their business?</div>
                            </a>
                        </Box>

                        <Box className="fourth">
                        <a onClick={()=>{navigate("/Forign-Nationals")}} style={{textDecoration:"none",color:"black",cursor:"pointer"}}>
                            <img src={pdf}/>
                                <span className='pdf4'>Premium Finance of Foreign Nationals</span>
                                <div>Premium Alliance is proud to offer premium finance services where there is a need, and that includes serving those outside the US.</div>
                            </a>
                        </Box> 
                        {/* <Box className="fourth">
                        <a onClick={()=>{navigate("/Premium-Alliance-Crypto-Valuation-Cheatsheet")}} style={{textDecoration:"none",color:"black",cursor:"pointer"}}>
                            <img src={pdf}/>
                                <span className='pdf4'>Premium Alliance Crypto Valuation Cheatsheet</span>
                                <div>Premium Alliance Crypto Valuation Guidelines</div>
                            </a>
                        </Box> */}
                </Box>
            </Dialog>
        </Fragment>
    )
}
export default DialogBox;