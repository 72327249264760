import { Box, Divider, Link } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import {
  Navigate,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import Header from "../components/header/header";
import LeftSection from "./dashboard/leftsection";
import RightSection from "./dashboard/rightsection";
import "../pages/verifyemail.css";
// import { verifyEmailAPI } from "../service/verifyemail.service";
import { Context as AuthContext } from "../context/auth_context";
import VerifyLink from "./verifylink";
import MyAdvisorCloudLogo from "../../src/assets/images/MyAdvisorCloudLogo.png";
import PremiumAllianceLogo from "../../src/assets/images/PremiumAllianceLogo.png";
import Loader from "./dashboard/loader";

const VerifyEmail = () => {
  const { state, verifyemail, logoutCall } = useContext(AuthContext);
  const navigate = useNavigate();
  const { user_detail } = state;
  const location = useLocation();
  const queryParams = location;
  const [searchParams] = useSearchParams();
  console.log("Search Params ", searchParams);
  const token = searchParams?.get("token");
  console.log("Verify Email Address called", token);

  let emailAddress = user_detail.user_email;
  const { flag_loader} = state;


  console.log("Location: ", location);

  if (emailAddress === "") emailAddress = location?.state?.email;

  console.log("Email address in Verify Email: ", emailAddress);
  const [flagSent, setFlagSent] = useState("");

  // useEffect(() => {
  //   if (!user_detail.user_email) {
  //     navigate("/login");
  //   }
  // }, []);

  return (
    <>
    {flag_loader === true ? <Loader /> : null}
      <Box className="header">
        <div className="global_header">
          <div className="left_section">
            <span
              onClick={() => navigate("/home")}
              style={{ cursor: "pointer" }}
            >
              <img
                src={MyAdvisorCloudLogo}
                alt="my advisor cloud logo"
                className="first_image_logo"
              />
            </span>
            &nbsp;&nbsp;&nbsp;
            <a href="https://premiumalliance.com/">
              <img
                src={PremiumAllianceLogo}
                alt="premium alliance logo"
                className="second_image_logo"
              />
            </a>
          </div>
          <div className="right_section">
            {/* <span
              onClick={() => {
                navigate("/login");
              }}
              style={{ cursor: "pointer" }}
            >
              Login
            </span> */}
          </div>
        </div>
      </Box>
      {!user_detail.flag_email_verified && (
        <div className="email_box_main">
          <div className="email_box ">
            <div className="verify_mail">Verify Email</div>
            <Divider />
            {flagSent && (
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "green",
                  padding: "20px 0",
                }}
              >
                {flagSent}
              </span>
            )}

            <div className="content">
              Thank you for signing up for our service!<br/> Before you can start
              using our platform, we need to verify your email address. This is
              an important step to ensure the security of your account.
              {/* <Link
              onClick={() => {
                setFlagSent("We have sent you the Mail");
                verifyemail({ emailAddress });
              }}
              style={{ cursor: "pointer" }}
            >
              {" "}
              click here
            </Link>{" "} */}
              {/* to request a new verification email. */}
            </div>
            <button
              className="verifyemail_resend-email-btn"
              onClick={() => {
                setFlagSent(
                  "A new verification email has been sent to your registered email address"
                );
                verifyemail({ emailAddress });
              }}
            >
              Resend Mail
            </button>
            <button onClick={logoutCall} className="verifyemail_logout_btn">
              Logout
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default VerifyEmail;
