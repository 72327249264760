import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  CssBaseline,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import Header from "../components/header/header";
import "../pages/confirmMail.css";
import { Context as AuthContext } from "../context/auth_context";
import ErrorIcon from '@mui/icons-material/Error';
import { useNavigate } from "react-router-dom";
import MyAdvisorCloudLogo from "../../src/assets/images/MyAdvisorCloudLogo.png";
import PremiumAllianceLogo from "../../src/assets/images/PremiumAllianceLogo.png";
import Loader from "./dashboard/loader";
import emailIcon from "../assets/images/InputFieldIcons/emailIcon.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import imageBG from "../assets/images/bg_img2.png";
import LoginHeader from "../components/header/LoginHeader/LoginHeader";

const ConfirmMail = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [showEmptyFieldError , setShowEmptyFieldError] = useState(false);
  // const [verify,setVerify] = useState(0);
  const { resetpasswordcall, state, SetErrorMessageToNull } = useContext(AuthContext);
  const { flag_loader, flag_email_sent, alertclassName } = state;

  // useEffect(() => {
  //   console.log(
  //     "flag_email_sent : --------",
  //     flag_email_sent,
  //     state.alertclassName
  //   );
  //   flag_email_sent == true ? navigate("/login") : state.alertclassName == "errMsg" ? navigate("/login")
  //     : console.log(
  //         "if flag email sent is false and aleert casname is empty string then this console will be printed."
  //       );
  // }, [flag_email_sent, state.alertclassName]);

  useEffect(() => {
    console.log("Printig object in useeffet");
  }, [email]);
  console.log("alertclassName",alertclassName);

  function ResetPassButtonClick(){
    if(email == "") {
      setShowEmptyFieldError(true);
      SetErrorMessageToNull();
    }
    else{
      setShowEmptyFieldError(false);

      resetpasswordcall({
        email: email,
      });
    }
   
  }
  return (
    <>
      <CssBaseline />
      {flag_loader === true ? <Loader /> : null}
      <LoginHeader/>
      <Box
        className="register_main  "
        sx={{
          width: "100%",
          background: "rgba(0, 0, 0, 0.5);",
          backgroundImage: `url(${imageBG})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundBlendMode: "multiply",
        }}
      >
        <Box
          className="register_form"
          style={{
            height: "fit-content",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            className="confirm_mail_innerBox"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid container className="confirm_main_form_container" flexDirection={"column"} >
              <Grid item padding={"0px !important"} width={"100%"}>
                <ArrowBackIcon
                  sx={{ color: "white" }}
                  onClick={() => navigate("/login")}
                />
              </Grid>
              <Grid
                item
                padding={"0px !important"}
                width={"100%"}
                sx={{ marginBottom: "20px" }}
              >
                <Typography
                  sx={{
                    fontWeight: "500",
                    color: "white !important",
                    fontSize: "25px",
                    marginBottom: "10px",
                    
                  }}
                >
                  Forgot Password
                </Typography>
              </Grid>
              <Grid item>
                {showEmptyFieldError && 
                  <>
                 <div
                  className="alert alert-danger"
                  role="alert"
                  style={{ padding: "0px 5px 2px 15px" }}
                >
                  <span style={{marginRight:"10px",}}>
                <ErrorIcon />
                  </span>
                  <span style={{fontSize:"15px"}}>
                    
                  Please enter all details
                  </span>
                </div>
                </>}
              {alertclassName == "errMsg"?  (
              <>
                <div
                  // className={alertclassName}
                  className="alert alert-danger" role="alert" style={{background:"#ff8080",border:"#ff8080",padding:"5px 5px 5px 15px",fontSize:"15px"}}
                >
                  
                  {state.message}
                </div>
              </>
            ):"" }
              </Grid>
              
              <Grid
                item
                width={"100%"}
                padding={"0px !important"}
                sx={{ marginBottom: "50px" }}
              >
                <TextField
                  type="email"
                  id="Email"
                  placeholder="Enter your Email"
                  pattern="((\w+\.)*\w+)@(\w+\.)+(com|kr|net|us|info|biz)"
                  required="required"
                  className="inputField1"
                  InputProps={{
                    startAdornment: (
                      <div style={{ padding: "10px" }}>
                        <img src={emailIcon} />
                      </div>
                    ),
                    disableUnderline: true,
                    style: { padding: 0 },
                  }}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    console.log("email", e.target.value);
                    // console.log("email",email)
                  }}
                  onBlur={(e) => {
                    console.log("email" + email);
                    // validateEmail(email);
                    // setVerify(1);
                  }}
                  autoComplete="off"
                />
              </Grid>

              <Grid
                item
                width={"100%"}
                padding={"0px !important"}
                sx={{ marginBottom: "20px" }}
              >
                {" "}
                <Button
                  className="reset_password_btn"
                  onClick={(res) => { ResetPassButtonClick()
                  }}
                  sx={{
                    textTransform: "none",
                    height: "40px",
                  }}
                  disableElevation
                  variant="contained"
                >
                  <span
                    style={{
                      fontWeight: "600",
                      color: "black",
                      fontSize: "20px",
                    }}
                  >
                    Reset Password
                  </span>
                </Button>
              </Grid>
            </Grid>
            {/* <Box
                className="first_item_confirmMail"
                style={{
                  display: "flex",
                  // flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box className="field_name_confirmMail">
                  <Typography component="div">
                    Email
                    <span className="required_field">*</span>
                  </Typography>
                </Box>

                <input
                  type="email"
                  id="Email"
                  className="input_tag_confirm_mail"
                  placeholder="Enter your Email"
                  pattern="((\w+\.)*\w+)@(\w+\.)+(com|kr|net|us|info|biz)"
                  required="required"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    console.log("email", e.target.value);
                    // console.log("email",email)
                  }}
                  onBlur={(e) => {
                    console.log("email" + email);
                    // validateEmail(email);
                    // setVerify(1);
                  }}
                  autoComplete="off"
                />
              </Box> */}

            {/* <Box className="create_account_button">
                <Button
                  onClick={(res) => {
                    resetpasswordcall({
                      email: email,
                    });
                  }}
                  disableElevation
                  variant="contained"
                >
                  Reset Password
                </Button>
              </Box> */}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ConfirmMail;
