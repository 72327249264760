import {
  Box,
  Divider,
  TextField,
  Button,
  InputLabel,
  Input,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../../components/header/header";
import LeftSection from "./leftsection";
import RightSection from "./rightsection";
import Inflation1 from "../../assets/images/inflation_app1.png";
import Inflation2 from "../../assets/images/inflation_app2.png";
import "../dashboard/inflationapp.css";
import Chart from "./chart";
import { Navigate, useNavigate } from "react-router-dom";
import $ from "jquery";

const Inflation = () => {
  const [principal, setPrincipal] = useState(null);
  const [rate, setRate] = useState(null);
  const [time, setTime] = useState(null);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();

  const calculate = () => {
    var finalvalue = (
      principal / Math.pow(1 + rate / 100, time).toFixed(2)
    ).toFixed(2);
    // var finalvalue = value.toFixed(2);
    console.log("finalvalue", finalvalue);

    let z = principal;
    for (var i = 1; i <= time; i++) {
      // z-=z*p

      // z+=z/(1+p)

      z = z / (1 + rate / 100);

      z = Math.round(z * 100) / 100;
    }

    console.log("z:", z);

    //var Interst = (principal * rate * time)/100.0;
    //var value =(principal-Interst)
    finalvalue !== NaN ? setTotal(z) : setTotal(0);
    // return value;
  };

  function thousands_separators(num) {
    var num_parts = num.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
  }
  useEffect(() => {
    $(document).ready(function () {
      $(this).scrollTop(0);
    });
  }, []);
  useEffect(() => {
    if (principal !== null && rate !== null && time !== null) calculate();
  }, [principal, rate, time]);

  //const [chartdata,setChartdata] = useState([{}])
  return (
    <>
      <Header />

      {window.outerWidth < 1200 ? (
        <>
          <div className="dashboard_page dashboard_page_inflation">
            <div className="middle_section middle_inflation">
              <Box className="middle_header">
                <div className="upload_tool">Inflation Calculator</div>
                {/* <a className='link_inflation link ' onClick={()=>{navigate('/home')}} style={{cursor:"pointer"}} >  
                        <span className='dashboard_inflation dashboard_upload_tool'>Dashboard</span>   
                        </a> */}
              </Box>
              <Divider />
              {/* <div className="img_div">
                <img className="img1_inflation" src={Inflation1}/>
                <div className="welcome_div">Welcome to the Inflation Rate Calculator </div>
                <div className="para1">How do we measure inflation? Typically we look at benchmarks like the<b> ​Consumer Price Index (CPI),</b> ​which is a measure of the average change over time in the prices paid by urban consumers for a market basket of consumer goods and services.</div>
                </div> */}
              <Box className="calculator">
                <div className="calc_div">
                  <div className="calc_header">
                    <b>What is my policy's buying power?</b>
                  </div>
                  <div className="calc_header1">
                    Calculates the equivalent purchasing power of an amount some
                    years ago based on a certain average inflation rate.
                  </div>
                  <div className="comp7">
                    {/* <TextField id="outlined-basic" className="component1" defaultValue="small" variant="outlined" /> */}

                    <Box className="component1_box">
                      <Box className="box_of_input">$</Box>
                      <Box className="box_of_textbox">
                        <Input
                          // className="component1"
                          variant="outlined"
                          type="number"
                          placeholder="Amount"
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            console.log(
                              "principal",
                              thousands_separators(e.target.value)
                            );
                            setPrincipal(e.target.value);
                          }}
                        />
                      </Box>
                    </Box>

                    <Box className="component2_box">
                      <div className="component2">With inflation Rate</div>
                    </Box>

                    <Box className="component3_box">
                      <Box className="box_of_input">%</Box>
                      <Box className="box_of_textbox">
                        <Input
                          //  className="component3"
                          variant="outlined"
                          placeholder="Rate"
                          style={{ width: "100%" }}
                          type="number"
                          onChange={(e) => {
                            console.log("rate", e.target.value);
                            setRate(e.target.value);
                          }}
                        />
                      </Box>
                    </Box>

                    <Box className="component4_box">
                      <div className="component4">after</div>
                    </Box>

                    <Box className="component5_box">
                      <Box className="box_of_textbox box_of_textbox_of_5">
                        <Input
                          //    className="component5"
                          variant="outlined"
                          placeholder="Years"
                          style={{ width: "100%" }}
                          type="number"
                          onChange={(e) => {
                            console.log("time", e.target.value);
                            setTime(e.target.value);
                          }}
                        />
                      </Box>
                    </Box>

                    <Box className="component6_box">
                      <div className="component6">
                        = ${thousands_separators(total)}{" "}
                      </div>
                    </Box>

                    {/* <Box className="component7_box">
                       <Button variant="contained"   className="component7" onClick={calculate}>Calculate</Button>
                    </Box> */}
                  </div>
                </div>
              </Box>
              <Box className="two_part">
                <Box className="part1">
                  <div className="para2_header">
                    <b>So what makes up the CPI “market basket”?</b>
                  </div>
                  <div className="para2_table">
                    CPI has 8 categories, listed below, ranked in “weight”
                    highest to lowest:
                  </div>
                  <div className="for_li">
                    <li>Housing</li>
                    <li>Transportation</li>
                    <li>Food and Beverages</li>
                    <li>Medical Care</li>
                    <li>Education and communication</li>
                    <li>Recreation</li>
                    <li>Apparel</li>
                    <li>Other goods and services</li>
                  </div>
                  <div className="imp">
                    <b>IMPORTANT​</b>: Inflation, although a blended average,
                    MUST be itemized for a consumer to truly understand the
                    impact of inflation on their own lives.
                  </div>
                </Box>
                <Box className="part2">
                  <div className="para2_header">
                    <b>So far in 2023:</b>
                  </div>
                  <div className="'para2_content">
                    <li>Electricity inflated by 10.2%</li>
                    <li>New vehicles up 6.1%</li>
                    <li>Dairy and related products up 10.7%</li>
                    <li>Cereals and bakery products up 13.6%</li>
                    <li>Apparel up 3.3%</li>
                    <li>Shelter costs are up 8.1%</li>
                    {/* <li>Fruits and vegetables up 7.7%</li>
                    <li>Apparel up 6.6%</li>
                    <li>Hospital and related services up 2.5%</li> */}
                    <li>
                      {" "}
                      <a
                        href="https://www.bls.gov/charts/consumer-price-index/consumer-price-index-by-category.htm#"
                        style={{
                          color: "blue",
                          fontSize: "10px",
                          textDecoration: "none",
                        }}
                      >
                        *12 mth percentage change, Consumer Price Index March
                        2023 not seasonally adjusted
                      </a>
                    </li>
                  </div>
                </Box>
              </Box>
              <Box className="para3">
                <div>
                  The all items index increased 5.0% for the 12 months ending
                  March 2023; this was the smallest 12-month increase since the
                  period ending May 2021. The all items less food and energy
                  index rose 5.6% over the last 12 months. The energy index
                  decreased 6.4% for the 12 months ending March 2023 and the
                  food index increased 8.5% over the last year.
                </div>
              </Box>
              <Box className="para3">
                <a
                  href="https://www.bls.gov/news.release/cpi.nr0.htm"
                  style={{
                    color: "blue",
                    fontSize: "10px",
                    textDecoration: "none",
                  }}
                >
                  *Consumer Price Index Summary 4/12/2023
                </a>
              </Box>

              <Box className="para4">
                <div>
                  <b>
                    So how does inflation, even a modest 2% per year target,
                    impact the buying power of your life insurance death benefit
                    for your beneficiary OR your retirement income
                    distributions?
                  </b>
                </div>
              </Box>

              <Box className="canvas_chart">
                <Chart />
              </Box>
              <Box>
                <div className="para5_header">
                  <b>Why does this matter?</b>
                </div>
                <div className="para5">
                  Inflation is the economic process of a general increase in the
                  prices of goods and services overtime. As prices rise, the
                  amount of goods and services that can be bought with a unit of
                  currency decreases, which may also be referred to as a
                  decrease in a currency's purchasing power. A common measure of
                  the rate of inflation is the rate of change in prices from one
                  period to the next and are usually measured both&nbsp;
                  <a
                    href="https://www.statista.com/statistics/273418/unadjusted-monthly-inflation-rate-in-the-us/"
                    rel="noopener"
                    target="_blank"
                    style={{textDecoration:"none"}}
                  >
                    
                    monthly
                  </a>
                  &nbsp;and&nbsp;
                  <a
                    href="https://www.statista.com/statistics/244983/projected-inflation-rate-in-the-united-states/"
                    rel="noopener"
                    target="_blank"
                    style={{textDecoration:"none"}}
                  >
                    annually
                  </a>
                  . Annual inflation rates are those more keenly observed by a
                  wider audience given that fluctuations in price can be
                  temporary. That said, monthly inflation rates are followed
                  closely by those hoping to assess the direction in which the
                  price level is headed.*
                  <br />
                  <br />
                  When creating a financial plan for your clients, inflation
                  should be part of the conversation. The buying power of a
                  policy sold today, could be deeply impacted by inflation rates
                  over the coming years.&nbsp;
                </div>
                <div style={{fontSize:"10px",marginTop:"10px"}}><a href="https://www.statista.com/topics/774/inflation/#topicOverview"style={{textDecoration:"none"}}>*Inflation in the U.S. - statistics &amp; facts</a></div>

                {/* <div className="para5_header"><b>Interesting Facts:</b></div>
                    <div className="para5">The primary job of the Federal Reserve is to control inflation while avoiding a recession. It does this with monetary policy. To control inflation, the Fed must use contractionary monetary policy to slow economic growth and demand, which puts downward pressure on prices.</div>
                    <div className="para6">Inflation and fed funds rate. Source: Federal Reserve Bank of St. Louis. In general, as interest rates are reduced, more people are able to borrow more money. The result is that consumers have more money to spend, causing the economy to grow and inflation to increase.</div>
                    <div className="para7">Fed expanded balance sheet by about $3 trillion so far this year, to a total of $7.2 trillion</div>
                    <div className="para8">Historically hyperinflation occurs when supply of money has no natural constraints.</div>
                    <div className="para9">Bitcoin prices have surged 36% as expectations for hedges against inflation are growing.</div>
                    <div className="para10">Short term - unemployment and corona virus have slowed wage growth and consumer demand, reducing upward pressure on prices for goods and services … BUT long term, inflation greater than 2% target seems inevitable.</div> */}
              </Box>
            </div>
            <RightSection />
            <LeftSection />
          </div>
        </>
      ) : (
        <>
          <div className="dashboard_page dashboard_page_inflation ">
            <LeftSection />
            <div className="middle_section middle_inflation">
              <Box className="middle_header">
                <div className="upload_tool">Inflation Calculator</div>
                {/* <a className='link_inflation link ' onClick={()=>{navigate('/home')}} style={{cursor:"pointer"}} >  
                        <span className='dashboard_inflation dashboard_upload_tool'>Dashboard</span>   
                        </a> */}
              </Box>
              <Divider />
              {/* <div className="img_div">
                <img className="img1_inflation" src={Inflation1}/>
                <div className="welcome_div">Welcome to the Inflation Rate Calculator </div>
                <div className="para1">How do we measure inflation? Typically we look at benchmarks like the<b> ​Consumer Price Index (CPI),</b> ​which is a measure of the average change over time in the prices paid by urban consumers for a market basket of consumer goods and services.</div>
                </div> */}
              <Box className="calculator">
                <div className="calc_div">
                  <div className="calc_header">
                    <b>What is my policy's buying power?</b>
                  </div>
                  <div className="calc_header1">
                    Calculates the equivalent purchasing power of an amount some
                    years ago based on a certain average inflation rate.
                  </div>
                  <div className="comp7">
                    {/* <TextField id="outlined-basic" className="component1" defaultValue="small" variant="outlined" /> */}

                    <Box className="component1_box">
                      <Box className="box_of_input">$</Box>
                      <Box className="box_of_textbox">
                        <Input
                          // className="component1"
                          variant="outlined"
                          type="number"
                          placeholder="Amount"
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            console.log(
                              "principal",
                              thousands_separators(e.target.value)
                            );
                            setPrincipal(e.target.value);
                          }}
                        />
                      </Box>
                    </Box>

                    <Box className="component2_box">
                      <div className="component2">With inflation Rate</div>
                    </Box>

                    <Box className="component3_box">
                      <Box className="box_of_input">%</Box>
                      <Box className="box_of_textbox">
                        <Input
                          //  className="component3"
                          variant="outlined"
                          placeholder="Rate"
                          style={{ width: "100%" }}
                          type="number"
                          onChange={(e) => {
                            console.log("rate", e.target.value);
                            setRate(e.target.value);
                          }}
                        />
                      </Box>
                    </Box>

                    <Box className="component4_box">
                      <div className="component4">after</div>
                    </Box>

                    <Box className="component5_box">
                      <Box className="box_of_textbox box_of_textbox_of_5">
                        <Input
                          //    className="component5"
                          variant="outlined"
                          placeholder="Years"
                          style={{ width: "100%" }}
                          type="number"
                          onChange={(e) => {
                            console.log("time", e.target.value);
                            setTime(e.target.value);
                          }}
                        />
                      </Box>
                    </Box>

                    <Box className="component6_box">
                      <div className="component6">
                        = ${thousands_separators(total)}{" "}
                      </div>
                    </Box>

                    {/* <Box className="component7_box">
                       <Button variant="contained"   className="component7" onClick={calculate}>Calculate</Button>
                    </Box> */}
                  </div>
                </div>
              </Box>
              <Box className="two_part">
                <Box className="part1">
                  <div className="para2_header">
                    <b>So what makes up the CPI “market basket”?</b>
                  </div>
                  <div className="para2_table">
                    CPI has 8 categories, listed below, ranked in “weight”
                    highest to lowest:
                  </div>
                  <div className="for_li">
                    <li>Housing</li>
                    <li>Transportation</li>
                    <li>Food and Beverages</li>
                    <li>Medical Care</li>
                    <li>Education and communication</li>
                    <li>Recreation</li>
                    <li>Apparel</li>
                    <li>Other goods and services</li>
                  </div>
                  <div className="imp">
                    <b>IMPORTANT​</b>: Inflation, although a blended average,
                    MUST be itemized for a consumer to truly understand the
                    impact of inflation on their own lives.
                  </div>
                </Box>
                <Box className="part2">
                  <div className="para2_header">
                    <b>So far in 2023:</b>
                  </div>
                  <div className="'para2_content">
                    <li>Electricity inflated by 10.2%</li>
                    <li>New vehicles up 6.1%</li>
                    <li>Dairy and related products up 10.7%</li>
                    <li>Cereals and bakery products up 13.6%</li>
                    <li>Apparel up 3.3%</li>
                    <li>Shelter costs are up 8.1%</li>
                    {/* <li>Fruits and vegetables up 7.7%</li>
                    <li>Apparel up 6.6%</li>
                    <li>Hospital and related services up 2.5%</li> */}
                    <li>
                      {" "}
                      <a
                        href="https://www.bls.gov/charts/consumer-price-index/consumer-price-index-by-category.htm#"
                        style={{
                          color: "blue",
                          fontSize: "10px",
                          textDecoration: "none",
                        }}
                      >
                        *12 mth percentage change, Consumer Price Index March
                        2023 not seasonally adjusted
                      </a>
                    </li>
                  </div>
                </Box>
              </Box>
              <Box className="para3">
                <div>
                  The all items index increased 5.0% for the 12 months ending
                  March 2023; this was the smallest 12-month increase since the
                  period ending May 2021. The all items less food and energy
                  index rose 5.6% over the last 12 months. The energy index
                  decreased 6.4% for the 12 months ending March 2023 and the
                  food index increased 8.5% over the last year.
                </div>
              </Box>
              <Box className="para3">
                <a
                  href="https://www.bls.gov/news.release/cpi.nr0.htm"
                  style={{
                    color: "blue",
                    fontSize: "10px",
                    textDecoration: "none",
                  }}
                >
                  *Consumer Price Index Summary 4/12/2023
                </a>
              </Box>

              <Box className="para4">
                <div>
                  <b>
                    So how does inflation, even a modest 2% per year target,
                    impact the buying power of your life insurance death benefit
                    for your beneficiary OR your retirement income
                    distributions?
                  </b>
                </div>
              </Box>

              <Box className="canvas_chart">
                <Chart />
              </Box>
              <Box>
                <div className="para5_header">
                  <b>Why does this matter?</b>
                </div>
                <div className="para5">
                  Inflation is the economic process of a general increase in the
                  prices of goods and services overtime. As prices rise, the
                  amount of goods and services that can be bought with a unit of
                  currency decreases, which may also be referred to as a
                  decrease in a currency's purchasing power. A common measure of
                  the rate of inflation is the rate of change in prices from one
                  period to the next and are usually measured both&nbsp;
                  <a
                    href="https://www.statista.com/statistics/273418/unadjusted-monthly-inflation-rate-in-the-us/"
                    rel="noopener"
                    target="_blank"
                    style={{textDecoration:"none"}}
                  >
                    monthly
                  </a>
                  &nbsp;and&nbsp;
                  <a
                    href="https://www.statista.com/statistics/244983/projected-inflation-rate-in-the-united-states/"
                    rel="noopener"
                    target="_blank"
                    style={{textDecoration:"none"}}
                  >
                    annually
                  </a>
                  . Annual inflation rates are those more keenly observed by a
                  wider audience given that fluctuations in price can be
                  temporary. That said, monthly inflation rates are followed
                  closely by those hoping to assess the direction in which the
                  price level is headed.*
                  <br />
                  <br />
                  When creating a financial plan for your clients, inflation
                  should be part of the conversation. The buying power of a
                  policy sold today, could be deeply impacted by inflation rates
                  over the coming years.&nbsp;
                </div>
                <div style ={{fontSize:"10px",marginTop:"20px"}}><a  href="https://www.statista.com/topics/774/inflation/#topicOverview"style={{textDecoration:"none"}}>*Inflation in the U.S. - statistics &amp; facts</a></div>
                {/* <div className="para5">Inflation is the economic process of a general increase in the prices of goods and services overtime. As prices rise, the amount of goods and services that can be bought with a unit of currency decreases, which may also be referred to as a decrease in a currency's purchasing power. A common measure of the rate of inflation is the rate of change in prices from one period to the next and are usually measured both monthly and annually. Annual inflation rates are those more keenly observed by a wider audience given that fluctuations in price can be temporary. That said, monthly inflation rates are followed closely by those hoping to assess the direction in which the price level is headed.*</div>
                    <div className="para6">When creating a financial plan for your clients, inflation should be part of the conversation. The buying power of a policy sold today, could be deeply impacted by inflation rates over the coming years. </div> */}
                {/* <div className="para7">Fed expanded balance sheet by about $3 trillion so far this year, to a total of $7.2 trillion</div>
                    <div className="para8">Historically hyperinflation occurs when supply of money has no natural constraints.</div>
                    <div className="para9">Bitcoin prices have surged 36% as expectations for hedges against inflation are growing.</div>
                    <div className="para10">Short term - unemployment and corona virus have slowed wage growth and consumer demand, reducing upward pressure on prices for goods and services … BUT long term, inflation greater than 2% target seems inevitable.</div> */}
              </Box>
            </div>
            <RightSection />
          </div>
        </>
      )}
    </>
  );
};
export default Inflation;
