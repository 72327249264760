import React, { Fragment, useEffect, useState } from 'react';
import { Navigate ,useNavigate} from "react-router-dom";
import LeftSection from './leftsection';
import RightSection from './rightsection';
import $ from "jquery"
import { Box,Divider} from "@mui/material";
import "../dashboard/SendUsCase.css";
import Header from '../../components/header/header';


const SendUsCase = () => {
       
    useEffect(()=>{
        $(document).ready(function(){
            $(this).scrollTop(0);
        });
      },[])

     
      const navigate = useNavigate();
    return (
        <Fragment>
            
        {(window.outerWidth < 1200) ?
            <>
            <Header/>
                <div className='dashboard_page' >
                <div className='middle_section '>
                        <Box className="middle_header">
                            <div className='upload_tool'>Send Us A Case</div> 
                            
                        </Box>
                        <Divider/>
                        {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSd07PNbWeLhs8bp8qGvsbm62zAbIWO2CdiPnpV0tB9sScJPJg/viewform" className='iframe_send_us_case' style={{minHeight:"100vh",width:"95%",margin:"2%"}}></iframe> */}

                        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSd07PNbWeLhs8bp8qGvsbm62zAbIWO2CdiPnpV0tB9sScJPJg/viewform?embedded=true" className='iframe_send_us_case' style={{minHeight:"100vh",width:"95%",margin:"2%"}} width="700" height="520" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

                    </div>
                    <RightSection/>
                    <LeftSection/>
                </div>
            </>  :
            <>
            <Header/>
                <div className='dashboard_page ' >
                    <LeftSection/>
                    <div className='middle_section '>
                        <Box className="middle_header">
                            <div className='upload_tool'>Send Us A Case</div> 
                            
                        </Box>
                        <Divider/>
                        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSd07PNbWeLhs8bp8qGvsbm62zAbIWO2CdiPnpV0tB9sScJPJg/viewform" className='iframe_send_us_case' style={{height:"95%",width:"100%"}}></iframe>
                    </div>
                    <RightSection/>
                </div>
                    
            </>
        }
        </Fragment>
    )
};

export default SendUsCase;