import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import "./userslists.css";
import Modal from "@mui/material/Modal";
import ErrorIcon from "@mui/icons-material/Error";
import ReportProblemIcon from "@mui/icons-material/WarningSharp";
import Header from "../../components/header/header";
// import IconButton from '@mui/material/IconButton';
import Menu from "@mui/material/Menu";
// import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from "axios";
import { Context as AuthContext } from "../../context/auth_context";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";

import {
  GetAllUsersAPI,
  UpdateUserStatusAPI,
} from "../../service/pendingusers.service";
import Home from "./dashboard";
import { Navigate, useNavigate } from "react-router-dom";
import { BackHand, Cookie } from "@mui/icons-material";

const columns = [
  {
    field: "id",
    headerName: "#",
    width: 80,
    headerAlign: "center",
    // flex: 1,
    renderCell: (params) => {
      return (
        <div
          style={{
            borderLeft:
              params.row.user_status_id === 1
                ? "3px solid #DBA800"
                : params.row.user_status_id === 2
                ? "3px solid green"
                : "3px solid red",
            textAlign: "center",
            width: "100%",
            height: "70%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span>{params.row.id}</span>
        </div>
      );
    },
  },
  {
    field: "user_full_name",
    headerName: "Name",
    width: 150,
    sortable: false,
    filterable: true,
    editable: false,
    // flex: 1,
    headerAlign: "left",
  },
  {
    field: "user_email",
    headerName: "Email",
    width: 250,
    sortable: false,
    filterable: true,
    editable: false,
    // headerAlign: "center",
    // flex: 1,
  },
  {
    field: "created_at",
    headerName: "Created At",
    width: 200,
    sortable: false,
    filterable: true,
    editable: false,
    // headerAlign: "center",
    // flex: 1,
    renderCell: (params) => {
      return (
        <p>{moment(params.row.created_at).subtract(1, "days").calendar()}</p>
      );
    },
  },
  {
    field: "is_verified",
    headerName: "Email Verification",
    width: 180,
    sortable: false,
    filterable: true,
    // headerAlign: "center",
    // flex: 1,
    renderCell: (params) => {
      return <EmailVerifiedComponent is_verified={params.row.is_verified} />;
    },
  },
  // (showApprovedReject.approve || showApprovedReject.reject) &&
  {
    field: "status",
    headerName: "Status",
    width: 150,
    sortable: false,
    filterable: true,

    // headerAlign: "center",
    // flex: 1,
    renderCell: (params) => {
      return (
        <div>
          <Typography
            sx={{
              color:
                params.row.user_status_id === 1
                  ? "#DBA800"
                  : params.row.user_status_id === 2
                  ? "green"
                  : "red",
            }}
          >
            {params.row.user_status_id === 1
              ? "Pending"
              : params.row.user_status_id === 2
              ? "Active"
              : "Inactive"}
          </Typography>
        </div>
      );
    },
  },
  // {
  //   !showApprovedReject.approve && !showApprovedReject.reject && (
  //     field: "status",
  //     headerName: "Status",
  //     width: 150,
  //     sortable: false,
  //     filterable: true,
  //     // headerAlign: "center",

  //     renderCell: (params) => {
  //       return <div>
  //         <Typography>{params.row.user_status_id === 1 ? "Pending" : (params.row.user_status_id === 2 ? "Approved": 'Rejected')}</Typography>
  //       </div>;
  //     },
  //   )
  // },

  // (
  //   showApprovedReject.approved || showApprovedReject.
  // )
  // !showApprovedReject.notVerified &&
  {
    field: "user_status_id",
    headerName: "Action",
    width: 180,
    sortable: false,
    filterable: true,
    // headerAlign: "center",
    // flex: 1,
    renderCell: (params) => {
      return (
        <StatusComponent
          user_id={params.row.user_id}
          status_id={params.row.user_status_id}
          name={params.row.user_full_name}
          actionName=""
          is_verified={params.row.is_verified}
          // status_name={params.row.user_status}
        />
      );
    },
  },
  // {
  //   field: "user_status_id",
  //   headerName: "Action",
  //   width: 250,
  //   sortable: false,
  //   filterable: true,
  //   // headerAlign: "center",
  //   // flex: 1,
  //   renderCell: (params) => {
  //     return (
  //       <StatusComponent
  //         user_id={params.row.user_id}
  //         status_id={params.row.user_status_id}
  //         name={params.row.user_full_name}
  //         // status_name={params.row.user_status}
  //       />
  //     );
  //   },
  // },
  // {
  //   field: "delete",
  //   headerName: "",
  //   width: 80,
  //   sortable: false,
  //   filterable: true,
  //   editable: false,
  //   // headerAlign: "center",
  //   // flex: 1,
  //   renderCell: (params) => {
  //     return (
  //       <StatusComponent
  //         user_id={params.row.user_id}
  //         status_id={params.row.user_status_id}
  //         name={params.row.user_full_name}
  //         actionName="delete"
  //         // status_name={params.row.user_status}
  //       />
  //     );
  //   },
  // },
  // {
  //   field: "status",
  //   headerName: "",
  //   width: 120,
  //   headerAlign: "center",
  //   editable: false,
  //   renderCell: (params) => (
  //     <StatusComponent
  //       name={params.row.firstname + " " + params.row.lastname}
  //       id={params.row._id}
  //       usersData={usersData}
  //       setUsersData={setUsersData}
  //     />
  //   ),
  // },
  // {
  //   field: "enrollment",
  //   headerName: "Enrollment",
  //   width: 180,
  //   type: "singleSelect",
  //   // valueOptions: ["Approve", "Reject"],

  //   // valueOptions: [
  //   //   { value: 99, label: "Select One" },
  //   //   { value: 1, label: "Enrollment" },
  //   //   { value: 0, label: "No Enrollment" }
  //   // ],
  //   // valueFormatter: ({ id: rowId, value, field, api }) => {
  //   //   const colDef = api.getColumn(field);
  //   //   const option = colDef.valueOptions.find(
  //   //     ({ value: optionValue }) => value === optionValue
  //   //   );

  //   //   return option.label;
  //   // },
  //   editable: true
  // }
];

const StatusComponent = ({
  user_id,
  status_id,
  name,
  actionName,
  is_verified,
}) => {
  debugger
  const { pendingUsersDataHandler: GetAllUserData, state } = useContext(AuthContext);

  const { pendingUsersData: allUserData, } = state;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    borderRadius: "1rem",
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
  };

  const [openModal, setOpenModal] = useState(false);
  const [currentAction, setCurrentAction] = useState("");
  const handleOpenModal = (action) => {
    setCurrentAction(action);
    setOpenModal(true);
  };
  const handleCloseModal = () => setOpenModal(false);

  const handleApproveReject = async () => {
    let obj = {
      user_id,
      status_id:
        currentAction === "Active" ? 2 : currentAction === "Inactive" ? 3 : 4,
      send_notification: true,
    };
    await UpdateUserStatusAPI(
      obj,
      (res) => {
        if (res.data.status) {
          GetAllUserData();
        } else {
          alert("Error");
        }
      },
      (err) => {
        console.log("error", err);
      }
    );
    setOpenModal(false);
  };

  const modal = (
    <div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {status_id === 1 ? (
            <>
              <Typography
                sx={{
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "600",
                  color: currentAction === "Active" ? "green" : "red",
                  margin: "1rem 0",
                }}
              >
                <span style={{ textTransform: "capitalize" }}>
                  {currentAction}
                </span>
              </Typography>
              <ReportProblemIcon
                sx={{
                  fontSize: "100px",
                  color: currentAction === "Active" ? "orange" : "red",
                }}
              />
              <Typography
                sx={{
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "600",
                  margin: "1rem 0",
                }}
              >
                Are you sure you want to {currentAction} {name}?
              </Typography>
              <Box
                sx={{
                  width: "60%",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    background: "#c8bba1",
                    color: "#000",
                  }}
                  onClick={() => handleApproveReject()}
                >
                  Yes
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    border: "1px solid #c8bba1",
                    color: "#000",
                  }}
                  onClick={() => setOpenModal(false)}
                >
                  No
                </Button>
              </Box>
            </>
          ) : (
            <>
              <>
                <Typography
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    fontWeight: "600",
                    color: currentAction === "Active" ? "green" : "red",
                    margin: "1rem 0",
                  }}
                >
                  <span style={{ textTransform: "capitalize" }}>
                    {currentAction}{" "}
                  </span>
                  {name}
                </Typography>
                <ErrorIcon sx={{ fontSize: "4rem", color: "red" }} />
                <Typography
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    fontWeight: "600",
                    margin: "1rem 0",
                  }}
                >
                  Are you sure you want to {currentAction} {name}
                </Typography>
                <Box
                  sx={{
                    width: "60%",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => handleApproveReject()}
                  >
                    Yes
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => setOpenModal(false)}
                  >
                    No
                  </Button>
                </Box>
              </>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );

  return (
    <>
      {modal}
      {actionName !== "delete" ? (
        <div style={{ display: "flex" }}>
          {status_id === 1 ? (
            is_verified === 1 && (
              <>
                <Typography
                  onClick={(e) =>
                    handleOpenModal(e.target.innerText.toLowerCase())
                  }
                  sx={{
                    textAlign: "center",
                    padding: "0.2rem 0.7rem",
                    fontSize: "0.9rem",
                    borderRadius: "1rem",
                    background: "#47921c",
                    color: "#fff",
                    // color: is_verified === 0 ? "red" : "green",
                    // border: is_verified === 0 ? "1px solid red" : "1px solid green",
                    width: "fit-content",
                    fontWeight: "500",
                    letterSpacing: "0.4px",
                    marginRight: "0.5rem",
                    cursor: "pointer",
                  }}
                >
                  {/* #47921c */}
                  {/* #df3131 */}
                  Active
                </Typography>

                <Typography
                  onClick={(e) =>
                    handleOpenModal(e.target.innerText.toLowerCase())
                  }
                  sx={{
                    textAlign: "center",
                    padding: "0.2rem 0.7rem",
                    fontSize: "0.9rem",
                    borderRadius: "1rem",
                    background: "#df3131",
                    color: "#fff",
                    // color: is_verified === 0 ? "red" : "green",
                    // border: is_verified === 0 ? "1px solid red" : "1px solid green",
                    width: "fit-content",
                    fontWeight: "400",
                    letterSpacing: "0.4px",
                    cursor: "pointer",
                  }}
                >
                  Inactive
                </Typography>
              </>
            )
          ) : (
            <>
              { <Button
                  sx={{
                    textTransform: "capitalize",
                    color: "#383535",
                    background: "#c8bba1",
                    "&:hover": {
                      background: "#e8dabd",
                    },
                  }}
                  size="small"
                  variant="contained"
                  onClick={(e) =>
                    handleOpenModal(status_id === 2 ? "Inactive" : "Active")
                  }
                >
                  Change Status
                </Button> }
            </>
          )}
        </div>
      ) : (
        <DeleteIcon onClick={(e) => handleOpenModal("delete")} />
      )}
    </>
  );
};

const EmailVerifiedComponent = ({ is_verified }) => {
  return (
    <Typography
      sx={{
        textAlign: "center",
        padding: "0.2rem 0.7rem",
        fontSize: "0.7rem",
        borderRadius: "1rem",
        color: is_verified === 0 ? "red" : "green",
        border: is_verified === 0 ? "1px solid red" : "1px solid green",
        width: "fit-content",
        fontWeight: "500",
        letterSpacing: "0.4px",
      }}
    >
      {is_verified === 0 ? "Not Verified" : "Verified"}
    </Typography>
  );
};

const DatagridComponent = () => {
  const navigate = useNavigate();
  const { pendingUsersDataHandler: GetAllUserData, state } = useContext(AuthContext);

  const { pendingUsersData: allUserData, user_detail } = state;

  const [showSelectedValue, setShowSelectedValue] = useState("Pending");

  const [visibleColumns, setVisibleColumns] = useState(columns);

  console.log(";;;", allUserData);
  console.log(user_detail);

  const [usersData, setUsersData] = useState(allUserData);
  console.log("usersData1234:", usersData);


  const [showApprovedReject, setShowApprovedReject] = useState({
    Active: false,
    Inactive: false,
    notVerified: false,
  });

  // useEffect(() => {
  //   let newUsersData = pendingUsersData.filter((rr, i) => {
  //     return rr.user_status === "Pending";
  //   });

  //   newUsersData = newUsersData.map((rr, i) => {
  //     return {
  //       ...rr,
  //       id: i + 1,
  //     };
  //   });
  //   setUsersData(newUsersData);
  // }, [pendingUsersData]);

  useEffect(() => {
    document.body.classList.add("hide-chat-button");
    return () => document.body.classList.remove("hide-chat-button");
  }, []);

  // useEffect(() => {
  //   if (showApprovedReject.approve && showApprovedReject.reject) {
  //     let newUsersData = pendingUsersData.filter((rr, i) => {
  //       return rr.user_status !== "Pending";
  //     });

  //     newUsersData = newUsersData.map((rr, i) => {
  //       return {
  //         ...rr,
  //         id: i + 1,
  //       };
  //     });
  //     setUsersData(newUsersData);
  //   } else if (showApprovedReject.approve) {
  //     let newUsersData = pendingUsersData.filter((rr, i) => {
  //       return rr.user_status === "Approved";
  //     });

  //     newUsersData = newUsersData.map((rr, i) => {
  //       return {
  //         ...rr,
  //         id: i + 1,
  //       };
  //     });
  //     setUsersData(newUsersData);
  //   } else if (showApprovedReject.reject) {
  //     let newUsersData = pendingUsersData.filter((rr, i) => {
  //       return rr.user_status === "Rejected";
  //     });

  //     newUsersData = newUsersData.map((rr, i) => {
  //       return {
  //         ...rr,
  //         id: i + 1,
  //       };
  //     });
  //     setUsersData(newUsersData);
  //   } else if (showApprovedReject.notVerified === true) {
  //     let newUsersData = pendingUsersData.filter((rr, i) => {
  //       return rr.is_verified !== 1;
  //     });

  //     newUsersData = newUsersData.map((rr, i) => {
  //       return {
  //         ...rr,
  //         id: i + 1,
  //       };
  //     });
  //     setUsersData(newUsersData);
  //   } else {
  //     let newUsersData = pendingUsersData.filter((rr, i) => {
  //       return rr.is_verified === 1 && rr.user_status === "Pending";
  //     });

  //     newUsersData = newUsersData.map((rr, i) => {
  //       return {
  //         ...rr,
  //         id: i + 1,
  //       };
  //     });
  //     setUsersData(newUsersData);
  //   }
  // }, [showApprovedReject, pendingUsersData, showSelectedValue]);

  const handleShowApproveReject = (action) => {
    setShowApprovedReject({
      ...showApprovedReject,
      [action]: !showApprovedReject.action,
    });
  };

  useEffect(() => {
    if (showSelectedValue === "Pending") {
      let newUsersData = allUserData.filter((rr, i) => {
        return rr.user_status === "Pending" && rr.is_verified === 1;
      });

      newUsersData = newUsersData.map((rr, i) => {
        return {
          ...rr,
          id: i + 1,
        };
      });
      setUsersData(newUsersData);
    } else {
      let newUsersData = allUserData.filter((rr, i) => {
        return rr;
      });

      newUsersData = newUsersData.map((rr, i) => {
        return {
          ...rr,
          id: i + 1,
        };
      });
      setUsersData(newUsersData);
    }
  }, [allUserData, showSelectedValue]);

  // const getAllUsersAPIHandler = () => {
  //   GetAllUsersAPI(
  //     (res) => {
  //       console.log(res.data.data);
  //       if (res.data.status) {
  //         // const newsdf = res.data.data.map((rr, i) => {
  //         //   if (rr.user_status === "Pending") {
  //         //     return { ...rr, id: i + 1 };
  //         //   }
  //         // });
  //         res.data.data = res.data.data.filter((rr, i) => {
  //           return rr.user_status === "Pending";
  //         });
  //         res.data.data = res.data.data.map((rr, i) => {
  //           return {
  //             ...rr,
  //             id: i + 1,
  //           };
  //         });
  //         setUsersData(res.data.data);
  //         // console.log(";;;;;", newsdf);
  //       } else {
  //         alert("Error");
  //       }
  //     },
  //     (err) => {
  //       console.log("error", err);
  //     }
  //   );
  // };

  useEffect(() => {
    GetAllUserData();
  }, []);

  const paymentOptions = [
    { value: "paid", label: "Paid" },
    { value: "unpaid", label: "Un Paid" },
  ];

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });

  useEffect(() => {
    console.log(usersData);
  }, [usersData]);

  useEffect(() => {
    console.log(showSelectedValue);
  }, [showSelectedValue]);

  const handleChange = (event) => {
    setShowSelectedValue(event.target.value);
  };
  // return state?.role === "Admin" ?
  return (
    <div className="pendingusers_main">
      <Box
        sx={{
          height: 600,
          width: "fit-content",
          margin: "0 auto",
        }}
      >
        <Header />

        <Typography
          variant="h5"
          component="h5"
          sx={{ textAlign: "center", mt: 12, mb: 3 }}
        >
          Users List
        </Typography>
        <Box sx={{ display: "flex", gap: 2 }}>
          <FormControl sx={{ flexDirection: "row !important" }}>
            {/* <FormLabel id="demo-controlled-radio-buttons-group">
              Gender
            </FormLabel> */}
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={showSelectedValue}
              onChange={handleChange}
            >
              <FormControlLabel
                value="Pending"
                control={<Radio />}
                label="Pending"
              />
              <FormControlLabel
                value="All"
                control={<Radio />}
                label="Show all"
              />
            </RadioGroup>
          </FormControl>
          {/* <Radio
            checked={showSelectedValue === "Pending"}
            // onChange={(e) => setShowSelectedValue("Pending")}
            onChange={handleChange}
            // value="Pending"
            value={showSelectedValue}
            name="radio-buttons"
            slotProps={{ input: { "aria-label": "A" } }}
          />
          <Radio
            checked={showSelectedValue === "All"}
            // onChange={(e) => setShowSelectedValue("All")}
            onChange={handleChange}
            // value="All"
            value={showSelectedValue}
            name="radio-buttons"
            slotProps={{ input: { "aria-label": "B" } }}
          /> */}
        </Box>
        {/* <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "fit-content",
              marginRight: "1rem",
            }}
          >
            <Typography>Approved</Typography>
            <Checkbox
              checked={showApprovedReject.approve}
              onClick={() =>
                setShowApprovedReject({
                  ...showApprovedReject,
                  approve: !showApprovedReject.approve,
                })
              }
              inputProps={{ "aria-label": "controlled" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "fit-content",
              marginRight: "1rem",
            }}
          >
            <Typography>Rejected</Typography>
            <Checkbox
              checked={showApprovedReject.reject}
              onClick={() =>
                setShowApprovedReject({
                  ...showApprovedReject,
                  reject: !showApprovedReject.reject,
                })
              }
              inputProps={{ "aria-label": "controlled" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "fit-content",
              marginRight: "1rem",
            }}
          >
            <Typography>Not Verified</Typography>
            <Checkbox
              checked={showApprovedReject.notVerified}
              onClick={() =>
                setShowApprovedReject({
                  ...showApprovedReject,
                  notVerified: !showApprovedReject.notVerified,
                })
              }
              inputProps={{ "aria-label": "controlled" }}
            />
          </Box>
        </Box> */}

        <DataGrid
          // autoHeight
          columns={visibleColumns}
          rows={usersData}
          getRowId={(row) => row.user_id}
          // pageSizeOptions={[5, 10, 20]}
          // // pageSize={pageSize}
          // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          // getRowSpacing={(params) => ({
          //   top: params.isFirstVisible ? 0 : 5,
          //   bottom: params.isLastVisible ? 0 : 5,
          // })}
          // sx={{
          //     [`& .${gridClasses.row}`]: {
          //         bgcolor: (theme) =>
          //             theme.palette.mode === 'light' ? grey[200] : grey[900],
          //     },
          // }}
          // onCellEditCommit={(params) => setRowId(params.id)}
        />
      </Box>
    </div>
  );
  //  :
  //   navigate("/home")
};

export default DatagridComponent;

// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Button,
//   FormControl,
//   IconButton,
//   InputLabel,
//   MenuItem,
//   Select,
//   TextField,
//   Typography,
// } from "@mui/material";
// import { DataGrid } from "@mui/x-data-grid";
// import "./pendingusers.css";
// import Modal from "@mui/material/Modal";
// import ErrorIcon from "@mui/icons-material/Error";
// import Header from "../../components/header/header";
// // import IconButton from '@mui/material/IconButton';
// import Menu from "@mui/material/Menu";
// // import MenuItem from '@mui/material/MenuItem';
// import MoreVertIcon from "@mui/icons-material/MoreVert";
// import axios from "axios";
// import GetAllUsersAPI from "../../service/pendingusers.service";

// const StatusComponent = ({ name, id, usersData, setUsersData }) => {
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const open = Boolean(anchorEl);
//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//   const handleClose = () => {
//     // console.log(e.target.value)
//     // setAnchorEl(e.target.value);
//     setAnchorEl(null);
//     // setCurrentStatus(option);
//     // setOpenModal(true);
//   };

//   const options = ["Pending", "Approve", "Reject", "Delete"];

//   const ITEM_HEIGHT = 48;

//   const style = {
//     position: "absolute",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     width: 400,
//     bgcolor: "background.paper",
//     // border: "2px solid #000",
//     boxShadow: 24,
//     p: 4,
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     justifyContent: "center",
//     gap: "1rem",
//   };

//   const [status, setStatus] = useState("pending");
//   const [openModal, setOpenModal] = useState(false);
//   const [currentStatus, setCurrentStatus] = useState("");
//   const allStatus = ["Pending", "Approve", "Reject"];
//   // const handleOpenModal = (e) => {
//   //   console.log("enteredddddd");
//   //   setCurrentStatus(e.target.value);
//   //   setOpenModal(true);
//   // };
//   const handleCloseModal = () => setOpenModal(false);

//   const setStatusHandler = () => {
//     setStatus(currentStatus);
//     setUsersData(
//       usersData.map((data, i) => {
//         if (data._id === id) {
//           return {
//             ...data,
//             emailVerificationStatus: currentStatus,
//           };
//         } else {
//           return data;
//         }
//       })
//     );
//     handleCloseModal();
//   };

//   const modal = (
//     <div>
//       <Modal
//         open={openModal}
//         onClose={handleCloseModal}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box sx={style}>
//           <Typography
//             sx={{
//               width: "100%",
//               textAlign: "center",
//               fontWeight: "600",
//               color: currentStatus === "approve" ? "green" : "red",
//             }}
//           >
//             <span style={{ textTransform: "capitalize" }}>
//               {currentStatus}{" "}
//             </span>
//             {name}
//           </Typography>
//           <ErrorIcon sx={{ fontSize: "3rem", color: "red" }} />
//           <Typography
//             sx={{ width: "100%", textAlign: "center", fontWeight: "600" }}
//           >
//             Are you sure you want to {currentStatus} {name}
//           </Typography>
//           <Box
//             sx={{
//               width: "60%",
//               display: "flex",
//               justifyContent: "space-around",
//             }}
//           >
//             <Button variant="contained" onClick={() => setStatusHandler()}>
//               Yes
//             </Button>
//             <Button variant="outlined" onClick={() => handleCloseModal()}>
//               No
//             </Button>
//           </Box>
//         </Box>
//       </Modal>
//     </div>
//   );

//   return (
//     <>
//       {modal}
//       <div>
//         <IconButton
//           aria-label="more"
//           id="long-button"
//           aria-controls={open ? "long-menu" : undefined}
//           aria-expanded={open ? "true" : undefined}
//           aria-haspopup="true"
//           onClick={handleClick}
//         >
//           <MoreVertIcon />
//         </IconButton>
//         <Menu
//           id="long-menu"
//           MenuListProps={{
//             "aria-labelledby": "long-button",
//           }}
//           anchorEl={anchorEl}
//           open={open}
//           // onClose={handleClose}
//           PaperProps={{
//             style: {
//               maxHeight: ITEM_HEIGHT * 4.5,
//               width: "20ch",
//             },
//           }}
//         >
//           {options.map((option) => (
//             <MenuItem
//               key={option}
//               onClick={() => {
//                 setAnchorEl(null);
//                 setCurrentStatus(option);
//                 setOpenModal(true);
//               }}
//             >
//               {option}
//             </MenuItem>
//           ))}
//         </Menu>
//       </div>
//       {/* <FormControl className="pendingusers_select" fullWidth>
//         <Select
//           labelId="demo-simple-select-label"
//           id="demo-simple-select"
//           name="status"
//           value={status}
//           label="Age"
//           onChange={(e) => {
//             handleOpenModal(e);
//           }}
//         >
//           <MenuItem value={"pending"}>Pending</MenuItem>
//           <MenuItem value={"approve"}>Approve</MenuItem>
//           <MenuItem value={"reject"}>Reject</MenuItem>
//         </Select>
//       </FormControl> */}
//       {/* <TextField
//         id="gender-select"
//         select
//         placeholder="!" // <<<<< See here
//         sx = {{width: "100%"}}
//         // value={status}
//         onChange={(e) => {
//           handleOpenModal(e);sdfdsf
//         }}zxc
//         margin="normal"
//         variant="outlined"
//       >
//         {allStatus.map((option) => (sdf
//           <MenuItem key={option} value={option}>
//             {option}dsf
//           </MenuItem>
//         ))}
//       </TextField> */}
//     </>
//   );
// };

// const EmailStatusComponent = ({ status, status_name }) => {
//   return (
//     <Typography
//       sx={{
//         textAlign: "center",
//         padding: "0.3rem 0.4rem",
//         fontSize: "0.7rem",
//         borderRadius: "0.2rem",
//         background: status === 1 ? "yellow" : status === 2 ? "green" : "red",
//         width: "fit-content",
//         fontWeight: "bold",
//       }}
//     >
//       {status_name}
//     </Typography>
//   );
// };

// const DatagridComponent = () => {
//   const rows = [
//     {
//       _id: 1,
//       firstname: "John",
//       lastname: "Doe",
//       email: "random@gmail.com",
//       emailVerificationStatus: "pending",
//       status: "pending",
//     },
//     {
//       _id: 2,
//       firstname: "John",
//       lastname: "Doe",
//       email: "random@gmail.com",
//       emailVerificationStatus: "pending",
//       status: "pending",
//     },
//     {
//       _id: 3,
//       firstname: "John",
//       lastname: "Doe",
//       email: "random@gmail.com",
//       emailVerificationStatus: "pending",
//       status: "pending",
//     },
//     {
//       _id: 4,
//       firstname: "John",
//       lastname: "Doe",
//       email: "random@gmail.com",
//       emailVerificationStatus: "pending",
//       status: "pending",
//     },
//     {
//       _id: 5,
//       firstname: "John",
//       lastname: "Doe",
//       email: "random@gmail.com",
//       emailVerificationStatus: "pending",
//       status: "pending",
//     },
//     {
//       _id: 6,
//       firstname: "John",
//       lastname: "Doe",
//       email: "random@gmail.com",
//       emailVerificationStatus: "pending",
//       status: "pending",
//     },
//     {
//       _id: 7,
//       firstname: "John",
//       lastname: "Doe",
//       email: "random@gmail.com",
//       emailVerificationStatus: "pending",
//       status: "pending",
//     },
//     {
//       _id: 8,
//       firstname: "John",
//       lastname: "Doe",
//       email: "random@gmail.com",
//       emailVerificationStatus: "pending",
//       status: "pending",
//     },
//     {
//       _id: 9,
//       firstname: "John",
//       lastname: "Doe",
//       email: "random@gmail.com",
//       emailVerificationStatus: "pending",
//       status: "pending",
//     },
//     {
//       _id: 10,
//       firstname: "John",
//       lastname: "Doe",
//       email: "random@gmail.com",
//       emailVerificationStatus: "pending",
//       status: "pending",
//     },
//     {
//       _id: 11,
//       firstname: "John",
//       lastname: "Doe",
//       email: "random@gmail.com",
//       emailVerificationStatus: "pending",
//       status: "pending",
//     },
//     {
//       _id: 12,
//       firstname: "John",
//       lastname: "Doe",
//       email: "random@gmail.com",
//       emailVerificationStatus: "pending",
//       status: "pending",
//     },
//     {
//       _id: 13,
//       firstname: "John",
//       lastname: "Doe",
//       email: "random@gmail.com",
//       emailVerificationStatus: "pending",
//       status: "pending",
//     },
//     {
//       _id: 14,
//       firstname: "John",
//       lastname: "Doe",
//       email: "random@gmail.com",
//       emailVerificationStatus: "pending",
//       status: "pending",
//     },
//     {
//       _id: 15,
//       firstname: "John",
//       lastname: "Doe",
//       email: "random@gmail.com",
//       emailVerificationStatus: "pending",
//       status: "pending",
//     },
//     {
//       _id: 16,
//       firstname: "John",
//       lastname: "Doe",
//       email: "random@gmail.com",
//       emailVerificationStatus: "pending",
//       status: "pending",
//     },
//     {
//       _id: 17,
//       firstname: "John",
//       lastname: "Doe",
//       email: "random@gmail.com",
//       emailVerificationStatus: "pending",
//       status: "pending",
//     },
//     {
//       _id: 18,
//       firstname: "John",
//       lastname: "Doe",
//       email: "random@gmail.com",
//       emailVerificationStatus: "pending",
//       status: "pending",
//     },
//     {
//       _id: 19,
//       firstname: "John",
//       lastname: "Doe",
//       email: "random@gmail.com",
//       emailVerificationStatus: "pending",
//       status: "pending",
//     },
//     {
//       _id: 20,
//       firstname: "John",
//       lastname: "Doe",
//       email: "random@gmail.com",
//       emailVerificationStatus: "pending",
//       status: "pending",
//     },
//     {
//       _id: 21,
//       firstname: "John",
//       lastname: "Doe",
//       email: "random@gmail.com",
//       emailVerificationStatus: "pending",
//       status: "pending",
//     },
//     {
//       _id: 22,
//       firstname: "John",
//       lastname: "Doe",
//       email: "random@gmail.com",
//       emailVerificationStatus: "pending",
//       status: "pending",
//     },
//   ];
//   const [usersData, setUsersData] = useState([]);

//   useEffect(() => {
//     GetAllUsersAPI(
//       (res) => {
//         if (res.data.status) {
//           res.data.data = res.data.data.map((rr, i) => {
//             return { ...rr, id: i + 1 };
//           });
//           setUsersData(res.data.data);
//         } else {
//           alert("Error");
//         }
//       },
//       (err) => {
//         console.log("error", err);
//       }
//     );
//   }, []);
//   const paymentOptions = [
//     { value: "paid", label: "Paid" },
//     { value: "unpaid", label: "Un Paid" },
//   ];

//   const [paginationModel, setPaginationModel] = React.useState({
//     pageSize: 10,
//     page: 0,
//   });

//   useEffect(() => {
//     console.log(usersData);
//   }, [usersData]);

//   const columns = [
//     { field: "id", headerName: "#", width: 100, headerAlign: "center" },
//     {
//       field: "user_full_name",
//       headerName: "Name",
//       width: 200,
//       sortable: false,
//       filterable: true,
//       editable: false,
//       headerAlign: "center",
//     },
//     {
//       field: "user_email",
//       headerName: "Email",
//       width: 200,
//       sortable: false,
//       filterable: true,
//       editable: false,
//       headerAlign: "center",
//     },
//     {
//       field: "is_verified",
//       headerName: "Email Verification",
//       width: 250,
//       sortable: false,
//       filterable: true,
//       headerAlign: "center",
//       renderCell: (params) => {
//         if (params.row["is_verified"] == 0) {
//           return (
//             <EmailStatusComponent
//               status={"Not Verified"}
//               status_name={"Not Verified"}
//             />
//           );
//         } else {
//           return "";
//         }
//       },
//     },
//     {
//       field: "user_status_id",
//       headerName: "Status",
//       width: 250,
//       sortable: false,
//       filterable: true,
//       headerAlign: "center",
//       renderCell: (params) => {
//         return (
//           <EmailStatusComponent
//             status={params.row.user_status_id}
//             status_name={params.row.user_status}
//           />
//         );
//       },
//     },
//     {
//       field: "status",
//       headerName: "",
//       width: 120,
//       headerAlign: "center",
//       editable: false,
//       renderCell: (params) => (
//         <StatusComponent
//           name={params.row.firstname + " " + params.row.lastname}
//           id={params.row._id}
//           usersData={usersData}
//           setUsersData={setUsersData}
//         />
//       ),
//     },
//     // {
//     //   field: "enrollment",
//     //   headerName: "Enrollment",
//     //   width: 180,
//     //   type: "singleSelect",
//     //   // valueOptions: ["Approve", "Reject"],

//     //   // valueOptions: [
//     //   //   { value: 99, label: "Select One" },
//     //   //   { value: 1, label: "Enrollment" },
//     //   //   { value: 0, label: "No Enrollment" }
//     //   // ],
//     //   // valueFormatter: ({ id: rowId, value, field, api }) => {
//     //   //   const colDef = api.getColumn(field);
//     //   //   const option = colDef.valueOptions.find(
//     //   //     ({ value: optionValue }) => value === optionValue
//     //   //   );

//     //   //   return option.label;
//     //   // },
//     //   editable: true
//     // }
//   ];
//   return (
//     <div className="pendingusers_main">
//       <Box
//         sx={{
//           height: 600,
//           width: "75%",
//           margin: "0 auto",
//         }}
//       >
//         <Header />

//         <Typography
//           variant="h3"
//           component="h3"
//           sx={{ textAlign: "center", mt: 8, mb: 3 }}
//         >
//           Pending Users
//         </Typography>
//         <DataGrid
//           // autoHeight
//           columns={columns}
//           rows={usersData}
//           getRowId={(row) => row.user_id}
//           // pageSizeOptions={[5, 10, 20]}
//           // // pageSize={pageSize}
//           // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
//           paginationModel={paginationModel}
//           onPaginationModelChange={setPaginationModel}
//           // getRowSpacing={(params) => ({
//           //   top: params.isFirstVisible ? 0 : 5,
//           //   bottom: params.isLastVisible ? 0 : 5,
//           // })}
//           // sx={{
//           //     [`& .${gridClasses.row}`]: {
//           //         bgcolor: (theme) =>
//           //             theme.palette.mode === 'light' ? grey[200] : grey[900],
//           //     },
//           // }}
//           // onCellEditCommit={(params) => setRowId(params.id)}
//         />
//       </Box>
//     </div>
//   );
// };

// export default DatagridComponent;
