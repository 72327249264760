import React, { Fragment, useContext, useState, useEffect } from "react";
import "./header.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import MyAdvisorCloudLogo from "../../assets/images/MyAdvisorCloudLogo.png";
import PremiumAllianceLogo from "../../assets/images/PremiumAllianceLogo.png";
import UserLogo from "../../assets/images/UserLogo.png";
import {
  MenuItem,
  IconButton,
  Avatar,
  Menu,
  Divider,
  ListItemIcon,
  MenuList,
} from "@mui/material";
import { Context as AuthContext } from "../../context/auth_context";
import Loader from "../../pages/dashboard/loader";
import HomeIcon from "@mui/icons-material/Home";

const Header = () => {
  const navigate = useNavigate();
  const { logoutCall, logincall, state } = useContext(AuthContext);
  const { user_detail, flag_loader } = state;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Fragment>
      {flag_loader === true ? <Loader /> : null}
      <div className="global_header">
        <div className="left_section">
          <span id="currnetLogin" style={{ display: "none" }}>
            {user_detail.user_email}
          </span>
          <span
            onClick={() => navigate("/home")}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "baseline",
            }}
          >
            <img
              src={MyAdvisorCloudLogo}
              alt="my advisor cloud logo"
              className="first_image_logo"
            />
          </span>
          &nbsp;&nbsp;&nbsp;
          <a
            href="https://premiumalliance.com/"
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "baseline",
            }}
          >
            <img
              src={PremiumAllianceLogo}
              alt="premium alliance logo"
              className="second_image_logo"
            />
          </a>
        </div>
        <div className="right_section">
          <a
            className=" link_header "
            style={{ cursor: "pointer", textDecoration: "underline" }}
          >
            <span
              className="dashboard"
              onClick={() => {
                navigate("/home");
              }}
            >
              <HomeIcon
                className="1111"
                sx={{ fontSize: "35px !important", marginBottom: "5px" }}
              />
            </span>
          </a>
          <IconButton
            onClick={handleClick}
            sx={{ width: "35px !important", height: "35px important" }}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar
              sx={{ background: "black", fontSize: "3px !important" }}
            ></Avatar>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            className="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {/* {showLogin==1 ? <h4>Login</h4>:( */}
            <MenuList>
              <MenuItem onClick={logoutCall}>Logout</MenuItem>
            </MenuList>
          </Menu>
          {/* <MenuItem >
                <img src={UserLogo} alt="user" className='user_logo' /> 
                </MenuItem> */}
        </div>
      </div>
    </Fragment>
  );
};

export default Header;