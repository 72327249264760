import React, { Fragment, useEffect, useState } from 'react';
import './trainingpage.css';
import { Document, Page } from "react-pdf";
import Header from '../../components/header/header';
import { Box } from '@mui/system';
import pdfNew from "../../assets/images/pdf_new.svg"
import DialogBox from '../dialogbox';
import TrainingApp from './trainingpage';
import LeftSection from './leftsection';
import RightSection from './rightsection';
import { Link, Navigate ,useNavigate} from "react-router-dom";
import $ from "jquery"
import DownloadIcon from '@mui/icons-material/Download';

const TrainingPage = () => {

    const [showPopup,setShowPopup]=useState(false);
    const [videoPopup,setVideoPopup]=useState(false);
       
    useEffect(()=>{
        $(document).ready(function(){
            $(this).scrollTop(0);
        });
      },[])
        const navigate = useNavigate();
        const [numPages, setNumPages] = useState(null);
        const [pageNumber, setPageNumber] = useState(1);

        function onDocumentLoadSuccess({ numPages }) {
            setNumPages(numPages);
        }
        



    return (
        <Fragment>
        {showPopup === true && <DialogBox close={()=>{
            setShowPopup(false);
        }} />}
        {videoPopup === true && <TrainingApp close={()=>{
            setShowPopup(false);
        }} />}
        <Header/>


        {(window.outerWidth < 1200) ?
            <>
                <div className='dashboard_page dashboard_page_training' >
                <div className='main_header' >
                        <span className='videos'>Training Resources
                        </span> 
                        <Box className='video_content'  >
                            <Box className="tittle">
                            <h3>Premium Finance: An Introduction</h3>
                            </Box>
                            <Box className='video_div1' >
                                <a target="_blank" >
                                    <iframe  className='video1' src="https://player.vimeo.com/video/686457990?h=ddf66dfaa6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0"  allowfullscreen=""  allow="autoplay; fullscreen; picture-in-picture"  title="WFG PremiumFinance Intro"></iframe>
                                </a>
                                    <p>What is Premium Financed Life Insurance? Who is the ideal client? This brief video will introduce you to the concept of Premium Finance, what qualifications clients need to meet to be considered for premium financed life insurance, and how the policies are structured. Critical information as you begin exploring this lucrative concept. (5 mins)</p>
                                

                            </Box>
                            <Box className="tittle">
                            <h3>Premium Finance Top 4 Considerations</h3>
                            </Box>
                            
                            <Box className='video_div2' >
                                <a target="_blank" >
                                    <iframe className='video2' src="https://player.vimeo.com/video/686467074?h=94777e0710&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0"  allowfullscreen="" allow="autoplay; fullscreen; picture-in-picture"   title="taj-anow-uqy (2022-03-09 13_26 GMT-8)"></iframe>
                                </a>
                                    <p> Before you add Premium Financed Life Insurance to your product portfolio, it's important to understand what factors will help make each transaction successful. This video will review collateral, interest, exit strategies, and ownership structures and how they each play a critical role in the Premium Finance transaction. (6 mins)</p>
                                
                            </Box>
                        </Box>
                        <Box className="Link_pdf1">
                        <h5 style={{marginTop: "200px !important"}} className={"oneText"} >4 Most Important Considerations For Premium Finance</h5>           

                            <a
                            target="_blank" href="https://storage.googleapis.com/farmers-life-ins-public-storage/Premium%20Alliance%20Marketing%20Material/Premium_Finance_-_Brokers_Alliance_v2_1.pdf">
                                <Box className="three">
                                    <Box className="asset_elevation_download_icon">Download File<DownloadIcon /></Box>
                                </Box>
                            </a>
                                             
                            <h5 style={{marginTop: "200px !important"}} className={"oneText"} >Asset Elevation</h5>           
                            <a
                             target="_blank" href="https://storage.googleapis.com/farmers-life-ins-public-storage/Premium%20Alliance%20Marketing%20Material/Asset%20Elevation%20-%20Premium%20Alliance.pdf">
                                <Box className="one">
                                    <Box className="asset_elevation_download_icon">Download File<DownloadIcon /></Box>
                                </Box>
                            </a>
                            
                            <h5 style={{marginTop: "200px !important"}} className='oneText' >Buy Sell Agreements</h5>
                             <a href="https://storage.googleapis.com/farmers-life-ins-public-storage/Premium%20Alliance%20Marketing%20Material/Buy-Sell%20Agreements%20-%20Premium%20Alliance.pdf" target="_blank">
                                <Box className="two">
                                    <Box className="buy_sell_agreement_download_icon">Download File<DownloadIcon /></Box>
                                </Box>
                            </a>
                        </Box>
                    </div>
                    <RightSection/>
                    <LeftSection/>
                </div>
            </>  :
            <>
                <div className='dashboard_page  dashboard_page_training ' >
                    <LeftSection/>
                    <div className='main_header' >
                        <span className='videos'>Training Resources
                        </span> 
                        <Box className='video_content'  >
                            <Box className="tittle">
                            <h3>Premium Finance: An Introduction</h3>
                            </Box>
                            <Box className='video_div1' >
                                <a target="_blank" >
                                    <iframe  className='video1' src="https://player.vimeo.com/video/686457990?h=ddf66dfaa6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0"  allowfullscreen=""  allow="autoplay; fullscreen; picture-in-picture"  title="WFG PremiumFinance Intro"></iframe>
                                </a>
                                    <p>What is Premium Financed Life Insurance? Who is the ideal client? This brief video will introduce you to the concept of Premium Finance, what qualifications clients need to meet to be considered for premium financed life insurance, and how the policies are structured. Critical information as you begin exploring this lucrative concept. (5 mins)</p>
                                

                            </Box>
                            <Box className="tittle">
                            <h3>Premium Finance Top 4 Considerations</h3>
                            </Box>
                            
                            <Box className='video_div2' >
                                <a target="_blank" >
                                    <iframe className='video2' src="https://player.vimeo.com/video/686467074?h=94777e0710&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0"  allowfullscreen="" allow="autoplay; fullscreen; picture-in-picture"   title="taj-anow-uqy (2022-03-09 13_26 GMT-8)"></iframe>
                                </a>
                                    <p> Before you add Premium Financed Life Insurance to your product portfolio, it's important to understand what factors will help make each transaction successful. This video will review collateral, interest, exit strategies, and ownership structures and how they each play a critical role in the Premium Finance transaction. (6 mins)</p>
                                
                            </Box>
                        </Box>
                        <Box className="Link_pdf1">
                                             
                            <h5 style={{marginTop: "200px !important"}} className={"oneText"} >4 Most Important Considerations For Premium Finance</h5>           

                            <a
                             target="_blank" href="https://storage.googleapis.com/farmers-life-ins-public-storage/Premium%20Alliance%20Marketing%20Material/Premium_Finance_-_Brokers_Alliance_v2_1.pdf">
                                <Box className="three">
                                    <Box className="asset_elevation_download_icon">Download File<DownloadIcon /></Box>
                                </Box>
                            </a>
                            {/* <p>Brokers Alliance has many years of experience when it comes to Premium Financing a life insurance policy. In virtually every scenario, there are 4 things that must be considered and addressed before presenting a financed solution to the client.</p> */}


                            <h5 style={{marginTop: "200px !important"}} className={"oneText"} >Asset Elevation</h5>           
                            <a
                             target="_blank" href="https://storage.googleapis.com/farmers-life-ins-public-storage/Premium%20Alliance%20Marketing%20Material/Asset%20Elevation%20-%20Premium%20Alliance.pdf">
                                <Box className="one">
                                    <Box className="asset_elevation_download_icon">Download File<DownloadIcon /></Box>
                                </Box>
                            </a>
                            
                            <h5 style={{marginTop: "200px !important"}} className='oneText' >Buy Sell Agreements</h5>
                             <a href="https://storage.googleapis.com/farmers-life-ins-public-storage/Premium%20Alliance%20Marketing%20Material/Buy-Sell%20Agreements%20-%20Premium%20Alliance.pdf" target="_blank">
                                <Box className="two">
                                    <Box className="buy_sell_agreement_download_icon">Download File<DownloadIcon /></Box>
                                </Box>
                            </a>
                        </Box>
                    </div>
                    <RightSection/>
                </div>
            </>
        }
        </Fragment>
    )
};

export default TrainingPage;