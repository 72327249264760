import React, { Fragment, useEffect, useState, useContext } from "react";
import "./dashboard.css";
import CorrectImage from "../../assets/images/correct.png";
import InformationIcon from "../../assets/images/information.png";
import trainingApp from "../../assets/images/trainig_app.png";
import samplePresentation from "../../assets/images/presentation.png";
import foreignNationals from "../../assets/images/Foreign_Nationals.png";
import smallCostPlatform from "../../assets/images/Small_Cost.png";
import uploadTool from "../../assets/images/Upload_Tool.png";
import SendUsCase from "../../assets/images/Send us case.png";
import AppStore from "../../assets/images/App_Store.png";
import { Tooltip, Button, Divider } from "@mui/material";
import inflationApp from "../../assets/images/Inflation_App.png";
import usersList from "../../assets/images/usersList.png";
import Header from "../../components/header/header";
import { Box } from "@mui/system";
import chat from "../../assets/images/chat_icon.svg";
import { useNavigate } from "react-router-dom";
import DialogBox from "../dialogbox";
import TrainingPage from "../dashboard/trainingpage";
import "../../assets/images/bg_img.jpg";
//import { Context as AuthContext } from '../../context/auth_context';
import LeftSection from "./leftsection";
import RightSection from "./rightsection";
import { Context as AuthContext } from "../../context/auth_context";

const Home = () => {
  // const { logoutCall, state} = useContext(AuthContext);
  // const { flag_email_verified } = state
  // console.log("user detail on Dashboard page", user_detail)

  const [outerWidth, setOuterwidth] = useState();

  const navigate = useNavigate();

  const [showPopup, setShowPopup] = useState(false);
  const [videoPopup, setVideoPopup] = useState(false);
  const { loginCall, state } = useContext(AuthContext);

  const openpopup = () => {
    setShowPopup(true);
    if (showPopup == true) {
      <DialogBox />;
    }
  };

  useEffect(() => {
    setOuterwidth(window.outerWidth);
    console.log("window.outerWidth", window.outerWidth);
  }, [window.outerWidth]);

  return (
    <Fragment>
      {showPopup === true && (
        <DialogBox
          close={() => {
            setShowPopup(false);
          }}
        />
      )}

      {<Header />}

      {/* {(window.outerWidth <= 420 ) ? alert(true) : alert(false)} */}

      {outerWidth < 1200 ? (
        <>
          <div className="dashboard_page ">
            <div className="middle_section">
              <div className="middle_section_header">
                <h4>My Apps</h4>
              </div>
              <div className="container">
                <div className="cards">
            
                  <a
                    onClick={() => {
                      navigate("/send-us-case");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="card_app">
                      <div className="card_app_header">
                        <h4> Send Us A Case </h4>
                        <Tooltip
                          title={`Have a case you think may qualify as premium finance?  Submit the details to our team and we'll evaluate the information and work with you to 
                                    find the best solution for the client.`}
                          arrow={true}
                        >
                          <img src={InformationIcon} />
                        </Tooltip>
                      </div>
                      <div className="card_app_description">
                        <img
                          src={SendUsCase}
                          style={{ width: "100px", height: "100px" }}
                        />
                      </div>
                    </div>
                  </a>

                  <a
                    onClick={() => {
                      navigate("/training-app");
                    }}
                  >
                    <div className="card_app" style={{ cursor: "pointer" }}>
                      <div className="card_app_header">
                        <h4>Training Resources</h4>

                        <Tooltip
                          title={`What is Premium Finance and what are the most important considerations?  This overview answers all of those questions and more. Gain valuable insight and set yourself up for success. `}
                          arrow={true}
                        >
                          <img src={InformationIcon} />
                        </Tooltip>
                      </div>

                      <div className="card_app_description">
                        <img
                          src={trainingApp}
                          style={{ width: "100px", height: "100px" }}
                        />
                      </div>
                    </div>
                  </a>

                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/Premium-Finance-Checklist");
                    }}
                  >
                    <div className="card_app">
                      <div className="card_app_header">
                        <h4> Premium Finance Checklist </h4>
                        <Tooltip
                          title={`This checklist cover the majority of items required by lenders and insurance companies for premium financed life insurance cases. There may be additional requirements by certain lenders and/or carriers that are not listed but this will help get you started.`}
                          arrow={true}
                        >
                          <img src={InformationIcon} />
                        </Tooltip>
                      </div>
                      {/*  */}
                      <div className="card_app_description">
                        <img
                          src={CorrectImage}
                          style={{ width: "100px", height: "100px" }}
                        />
                      </div>
                    </div>
                  </a>

                  <a onClick={openpopup} style={{ cursor: "pointer" }}>
                    <div className="card_app">
                      <div className="card_app_header">
                        <h4>Advisor Resources</h4>
                        <Tooltip
                          title={`Download Premium Finance Checklist, Customizable Premium Finance Marketing, Foreign Nationals For Premium Finance Pdfs. `}
                          arrow={true}
                        >
                          <img src={InformationIcon} />
                        </Tooltip>
                      </div>
                      <div className="card_app_description">
                        <img
                          src={samplePresentation}
                          style={{ width: "100px", height: "100px" }}
                        />
                      </div>
                    </div>
                  </a>

                  {/* <a onClick={()=>{navigate("/Forign-Nationals")}} style={{cursor:"pointer"}}
                            ><div className='card_app'>
                                <div className='card_app_header'>
                                    <h4>Foreign Nationals </h4>
                                    <Tooltip title={`Foreign Nationals and those serving outside the U.S. have unique and specific requirements when considering financing of life coverage. This document outlines many of those requirements and will help ensure a smoother transaction.`} arrow={true}>
                                        <img src={InformationIcon} />
                                    </Tooltip>
                                </div>
                                <div className="card_app_description">
                                    <img src={foreignNationals} style={{ width: "100px", height: "100px" }} />
                                </div>
                            </div></a> */}

                  <a
                    onClick={() => {
                      navigate("/upload-tool");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="card_app">
                      <div className="card_app_header">
                        <h4>Upload Tool</h4>
                        <Tooltip title={`Upload the documents`} arrow={true}>
                          <img src={InformationIcon} />
                        </Tooltip>
                      </div>
                      <div className="card_app_description">
                        <img
                          src={uploadTool}
                          style={{ width: "100px", height: "100px" }}
                        />
                      </div>
                    </div>
                  </a>

                  <a
                    onClick={() => {
                      navigate("/Inflation-app");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="card_app">
                      <div className="card_app_header">
                        <h4>Inflation Calculator</h4>
                        {/* <Tooltip title={`What is Premium Finance and what are the most important considerations?  This overview answers all of those questions and more. Gain valuable insight and set yourself up for success.`} arrow={true}>
                                        <img src={InformationIcon} />
                                    </Tooltip> */}
                      </div>
                      <div className="card_app_description">
                        <img
                          src={inflationApp}
                          style={{ width: "100px", height: "100px" }}
                        />
                      </div>
                    </div>
                    {/* </a> */}
                  </a>
                  {state?.user_detail?.role === "Admin" && (
                    <a
                      onClick={() => {
                        navigate("/agentList");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="card_app">
                        <div className="card_app_header">
                          <h4>Agents</h4>
                        </div>
                        <div className="card_app_description">
                          <img
                            src={usersList}
                            style={{ width: "100px", height: "100px" }}
                          />
                        </div>
                      </div>
                      {/* </a> */}
                    </a>
                  )}
                </div>
              </div>
            </div>
            <RightSection />

            <LeftSection />
          </div>
        </>
      ) : (
        <>
          <div className="dashboard_page ">
            <LeftSection />

            <div className="middle_section">
              <div className="middle_section_header">
                <h4>My Apps</h4>
              </div>
              <div className="container">
                <div className="cards">
               

                  <a
                    onClick={() => {
                      navigate("/send-us-case");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="card_app">
                      <div className="card_app_header">
                        <h4> Send Us A Case </h4>
                        <Tooltip
                          title={`Have a case you think may qualify as premium finance?  Submit the details to our team and we'll evaluate the information and work with you to 
                                    find the best solution for the client.`}
                          arrow={true}
                        >
                          <img src={InformationIcon} />
                        </Tooltip>
                      </div>
                      <div className="card_app_description">
                        <img
                          src={SendUsCase}
                          style={{ width: "100px", height: "100px" }}
                        />
                      </div>
                    </div>
                  </a>

                  <a
                    onClick={() => {
                      navigate("/training-app");
                    }}
                  >
                    <div className="card_app" style={{ cursor: "pointer" }}>
                      <div className="card_app_header">
                        <h4>Training Resources</h4>

                        <Tooltip
                          title={`What is Premium Finance and what are the most important considerations?  This overview answers all of those questions and more. Gain valuable insight and set yourself up for success. `}
                          arrow={true}
                        >
                          <img src={InformationIcon} />
                        </Tooltip>
                      </div>

                      <div className="card_app_description">
                        <img
                          src={trainingApp}
                          style={{ width: "100px", height: "100px" }}
                        />
                      </div>
                    </div>
                  </a>

                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/Premium-Finance-Checklist");
                    }}
                  >
                    <div className="card_app">
                      <div className="card_app_header">
                        <h4> Premium Finance Checklist </h4>
                        <Tooltip
                          title={`This checklist cover the majority of items required by lenders and insurance companies for premium financed life insurance cases. There may be additional requirements by certain lenders and/or carriers that are not listed but this will help get you started.`}
                          arrow={true}
                        >
                          <img src={InformationIcon} />
                        </Tooltip>
                      </div>
                      {/*  */}
                      <div className="card_app_description">
                        <img
                          src={CorrectImage}
                          style={{ width: "100px", height: "100px" }}
                        />
                      </div>
                    </div>
                  </a>

                  <a onClick={openpopup} style={{ cursor: "pointer" }}>
                    <div className="card_app">
                      <div className="card_app_header">
                        <h4>Advisor Resources</h4>
                        <Tooltip
                          title={`Download Premium Finance Checklist, Customizable Premium Finance Marketing, Foreign Nationals For Premium Finance Pdfs. `}
                          arrow={true}
                        >
                          <img src={InformationIcon} />
                        </Tooltip>
                      </div>
                      <div className="card_app_description">
                        <img
                          src={samplePresentation}
                          style={{ width: "100px", height: "100px" }}
                        />
                      </div>
                    </div>
                  </a>

                  {/* <a onClick={()=>{navigate("/Forign-Nationals")}} style={{cursor:"pointer"}}
                            ><div className='card_app'>
                                <div className='card_app_header'>
                                    <h4>Foreign Nationals </h4>
                                    <Tooltip title={`Foreign Nationals and those serving outside the U.S. have unique and specific requirements when considering financing of life coverage. This document outlines many of those requirements and will help ensure a smoother transaction.`} arrow={true}>
                                        <img src={InformationIcon} />
                                    </Tooltip>
                                </div>
                                <div className="card_app_description">
                                    <img src={foreignNationals} style={{ width: "100px", height: "100px" }} />
                                </div>
                            </div></a> */}

                  <a
                    onClick={() => {
                      navigate("/upload-tool");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="card_app">
                      <div className="card_app_header">
                        <h4>Upload Tool</h4>
                        <Tooltip title={`Upload the documents`} arrow={true}>
                          <img src={InformationIcon} />
                        </Tooltip>
                      </div>
                      <div className="card_app_description">
                        <img
                          src={uploadTool}
                          style={{ width: "100px", height: "100px" }}
                        />
                      </div>
                    </div>
                  </a>

                  <a
                    onClick={() => {
                      navigate("/Inflation-app");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="card_app">
                      <div className="card_app_header">
                        <h4>Inflation Calculator</h4>
                        {/* <Tooltip title={`What is Premium Finance and what are the most important considerations?  This overview answers all of those questions and more. Gain valuable insight and set yourself up for success.`} arrow={true}>
                                        <img src={InformationIcon} />
                                    </Tooltip> */}
                      </div>
                      <div className="card_app_description">
                        <img
                          src={inflationApp}
                          style={{ width: "100px", height: "100px" }}
                        />
                      </div>
                    </div>
                    {/* </a> */}
                  </a>

                  {state?.user_detail?.role === "Admin" && (
                    <a
                      onClick={() => {
                        navigate("/agentList");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="card_app">
                        <div className="card_app_header">
                          <h4>Agents</h4>
                        </div>
                        <div className="card_app_description">
                          <img
                            src={usersList}
                            style={{ width: "100px", height: "100px" }}
                          />
                        </div>
                      </div>
                      {/* </a> */}
                    </a>
                  )}
                </div>
              </div>
            </div>

            <RightSection />
          </div>
        </>
      )}
    </Fragment>
  );
};

export default Home;
